import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReactProps } from "ag-grid-react";
import { Box, Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { ArrowDropDownRounded } from "@mui/icons-material";
import qs from "query-string";

import BaseDataTable from "./Base";
import { get } from "api";
import { generateQuery, getId, isValidTimestamp, removePrefix } from "logic/utils";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import MoreButton from "./MoreButton";
import { getTableColumnsFromServer, setTableColumnsOnServer } from "../../api/table";
import ServerComboFilter from "./ServerComboFilter";
import { ICellEditorParams } from "ag-grid-community";
import useSWR from "swr";
import AsyncCombo from "common/AsyncCombo";
import { DatePickerEditor } from "./DatePickerEditor";


const ThreeStateCheckboxFilter = ({ column, model, onModelChange }: any) => {
  // 0 = intermediate (no filter)
  // 1 = checked (true)
  // 2 = unchecked (false)
  const [filterState, setFilterState] = useState(0);

  const handleChange = () => {
    const nextState = filterState === 0 ? 1 : filterState === 1 ? 2 : 0;
    setFilterState(nextState);

    switch (nextState) {
      case 0:
        onModelChange(null);
        break;
      case 1:
        onModelChange({
          type: "equals",
          filter: "true",
        });
        break;
      case 2:
        onModelChange({
          type: "equals",
          filter: "false",
        });
        break;
    }
  };

  const isChecked = filterState === 1;
  const isIndeterminate = filterState === 0;

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Checkbox
        size="small"
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={handleChange}
        indeterminateIcon={<IndeterminateCheckBoxIcon />}
        sx={{ mr: "1rem" }}
      />
    </Box>
  );
};

export function ComboFilter({ options, model, onModelChange }: any) {
  const value = (model && model.filter) || "";

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    onModelChange(
      newValue === ""
        ? null
        : {
            type: "equals",
            filter: newValue,
          }
    );
  };

  return (
    <Select value={value} onChange={(e: any) => handleChange(e)} displayEmpty style={{ width: "100%" }}>
      <MenuItem value="">All</MenuItem>
      {options.map((option: string) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export function ComboFilterExtended({ options, model, onModelChange }: any) {
  const value = (model && model.filter) || "";

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    onModelChange(
      newValue === ""
        ? null
        : {
            type: "equals",
            filter: newValue,
          }
    );
  };

  return (
    <Select value={value} onChange={(e: any) => handleChange(e)} displayEmpty style={{ width: "100%" }}>
      <MenuItem value="">All</MenuItem>
      {options.map((option: { id: number; value: string; label: string }) => (
        <MenuItem key={option.id} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export const AsyncEditor = forwardRef((props: any, ref: any) => {
  return (
    <AsyncCombo
      style={{ width: "100%" }}
      url={props.colDef?.optionsUrl}
      filterBy={props.colDef?.optionsFilterBy}
      getOptionLabel={props.colDef?.getOptionLabel}
      onChange={(e, v) => props.onValueChange(v)}
    />
  );
});

function SelectCellEditor(props: ICellEditorParams) {
  const [value, setValue] = useState(props.value);
  const options = props.colDef?.cellEditorParams?.options || [];
  console.log(props, options);

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (props.stopEditing) {
      props.stopEditing();
    }
  };

  const getValue = () => {
    return value;
  };

  const preventPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  return (
    <div onClick={preventPropagation}>
      <Select value={value} onChange={handleChange}>
        {options.map((option: string) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export function getOperator(type: string) {
  switch (type) {
    case "contains":
      return "contain";
    case "equals":
      return "";
    case "startsWith":
      return "startsWith";
    case "greaterThan":
      return "min";
    case "lessThan":
      return "max";
    default:
      return "";
  }
}

function currencyFormatter(params: any) {
  const value = Math.floor(params.value);
  if (isNaN(value)) {
    return "";
  }
  return "$" + value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function dateFormatter(params: any) {
  const value = params.value;
  if (!value) {
    return "";
  }
  // return format(new Date(value), "MM/dd/yyyy HH:mm");
  return format(new Date(value), "yyyy-dd-MM");
}

function ViewDetailsButton(props: any) {
  const history = useHistory();

  return (
    <button onClick={() => history.push((props.column?.userProvidedColDef?.context || "") + getId(props.data))}>
      <ArrowDropDownRounded sx={{ width: 12, height: "auto" }} />
    </button>
  );
}

function DataTable({
  id,
  url,
  params,
  height,
  columns,
  rowHeight,
  rowSelection,
  singleClickEdit,
  noPagination,
  getContextMenuItems,
  onRowSelected,
  onSelectionChanged,
  onGridReady,
  getRowClass,
  onCellValueChanged,
  initialSort,
  option,
  setTotal,
  overrideDataSource,
  overrideFilterParams,
  onFilterMade,
  onFetchedData,
  headerBackgroundColor,
  allowContextMenuWithControlKey,
  cellSelection,
}: {
  id?: string;
  url: string;
  params?: any;
  height?: number | string;
  rowHeight?: number;
  singleClickEdit?: boolean;
  columns: AgGridReactProps["columnDefs"];
  rowSelection?: AgGridReactProps["rowSelection"];
  noPagination?: boolean;
  onRowSelected?: AgGridReactProps["onRowSelected"];
  onSelectionChanged?: AgGridReactProps["onSelectionChanged"];
  getRowClass?: AgGridReactProps["getRowClass"];
  onGridReady?: AgGridReactProps["onGridReady"];
  onCellValueChanged?: AgGridReactProps["onCellValueChanged"];
  initialSort?: { sort: string; order: "ASC" | "DESC" };
  option?: string[];
  setTotal?: (p?: any) => void;
  overrideDataSource?: (rows: any[] | null, total: number) => { result: any[] | null; total: number };
  overrideFilterParams?: (filters: any) => any;
  onFetchedData?: (response: any) => void;
  onFilterMade?: (filters?: any) => void;
  headerBackgroundColor?: string;
  allowContextMenuWithControlKey?: any;
  getContextMenuItems?: any;
  cellSelection?: boolean;
}) {
  const history = useHistory();
  const { data: tableorder } = useSWR(id ? `/tableorder?name=${id}` : null);
  const columnsOrder = tableorder?.result?.[0]?.order;

  const [colDefs, setColDefs] = useState<AgGridReactProps["columnDefs"]>(columns);
  const [toShowColDefs, setToShowColDefs] = useState<AgGridReactProps["columnDefs"]>(columns);

  const defaultColDef = useMemo(() => {
    return {
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      filter: true,
      floatingFilter: true,
      filterParams: {
        filterOptions: ["startsWith", "contains", "equals"],
        debounceMs: 500,
        maxNumConditions: 1,
      },
    };
  }, []);

  const columnTypes = useMemo<{
    [key: string]: any;
  }>(() => {
    return {
      view: {
        initialWidth: 60,
        width: 60,
        minWidth: 60,
        headerName: "",
        filter: null,
        floatingFilter: false,
        cellRenderer: ViewDetailsButton,
      },
      editableSelect: {
        editable: true,
        cellEditor: SelectCellEditor,
        // cellRenderer: SelectCellEditor,

        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ["equals"],
        },
        floatingFilterComponent: ({ column, model, onModelChange }: any) => {
          const options = column.getColDef().cellEditorParams?.options || [];
          return <ComboFilter options={options} model={model} onModelChange={onModelChange} />;
        },
      },
      boolean: {
        initialWidth: 100,
        width: 100,
        minWidth: 100,
        headerName: "",
        filter: true,
        cellEditor: "agCheckboxCellEditor",
        filterParams: {
          filterOptions: ["equals"],
        },
        floatingFilter: true,
        floatingFilterComponent: ThreeStateCheckboxFilter,
        cellRenderer: (p: any) => {
          return (
            <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ mt: -0.5 }}>
              <Checkbox checked={Boolean(p.value)} size="small" />
            </Box>
          );
        },
      },
      action: {
        initialWidth: 100,
        minWidth: 60,
        headerName: "",
        filter: null,
        floatingFilter: false,
      },
      number: {
        minWidth: 100,
        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ["greaterThan", "equals", "lessThan"],
          debounceMs: 500,
          maxNumConditions: 1,
        },
      },
      currency: {
        minWidth: 100,
        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ["greaterThan", "equals", "lessThan"],
          debounceMs: 500,
          maxNumConditions: 1,
        },
        valueFormatter: currencyFormatter,
      },
      date: {
        minWidth: 170,
        filter: "agDateColumnFilter",
        floatingFilter: true,
        filterParams: {
          filterOptions: ["greaterThan", "equals", "lessThan", "inRange"],
          debounceMs: 500,
          maxNumConditions: 1,
        },
        valueFormatter: dateFormatter,
        cellEditor: DatePickerEditor,
        cellEditorPopup: true,
        editable: (params: any) => {
          return params.colDef.editable === true;
        },
      },
      combo: {
        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ["equals"],
        },
        floatingFilterComponent: ({ column, model, onModelChange }: any) => {
          const options = column.getColDef().filterParams?.options || [];
          return <ComboFilter options={options} model={model} onModelChange={onModelChange} />;
        },
      },
      customCombo: {
        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ["equals"],
        },
        floatingFilterComponent: ({ column, model, onModelChange }: any) => {
          const options = column.getColDef().filterParams?.options || [];
          return <ComboFilterExtended options={options} model={model} onModelChange={onModelChange} />;
        },
      },
      serverCombo: {
        filter: true,
        floatingFilter: true,
        // filterParams: {
        //   filterOptions: ["equals"],
        // },
        floatingFilterComponent: ({ column, model, onModelChange }: any) => {
          const optionsUrl = column.getColDef().optionsUrl;
          return (
            <ServerComboFilter optionsUrl={optionsUrl} model={model} onModelChange={onModelChange} column={column} />
          );
        },
      },
      checkboxCombo: {
        editable: true,
        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ["equals"],
        },
        floatingFilterComponent: ({ column, model, onModelChange, ...other }: any) => {
          const options =
            column.getColDef().cellEditorParams?.options || column.getColDef().filterParams?.options || [];

          const filterValues = model?.filter?.split(",") || [];

          const handleChange = (event: any) => {
            const prev = model?.filter?.split(",") || ([] as string[]);
            const value = event.target.value;

            if (prev.indexOf(value) > -1) {
              const filterValue = prev.filter((i: string) => i !== value).join(",");
              onModelChange({
                type: "equals",
                filter: filterValue,
              });
            } else {
              const filterValue = [...prev, value].join(",");
              onModelChange({
                type: "equals",
                filter: filterValue,
              });
            }
          };

          return (
            <Select
              value={filterValues}
              onChange={handleChange}
              renderValue={(selected) => (selected as string[]).join(",")}
              style={{ width: "100%" }}
            >
              {options.map((option: string) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={filterValues.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      checkboxFilter: {
        editable: false,
        // cellEditor: SelectCellEditor,
        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ["equals"],
        },
        floatingFilterComponent: ({ column, model, onModelChange, ...other }: any) => {
          const options =
            column.getColDef().cellEditorParams?.options || column.getColDef().filterParams?.options || [];
          console.log({
            column,
            model,
          });
          const filterValues = model?.filter?.split(",") || [];

          const handleChange = (event: any) => {
            const prev = model?.filter?.split(",") || ([] as string[]);
            const value = event.target.value;

            if (prev.indexOf(value) > -1) {
              const filterValue = prev.filter((i: string) => i !== value).join(", ");
              onModelChange({
                type: "equals",
                filter: filterValue,
              });
            } else {
              const filterValue = [...prev, value].join(",");
              onModelChange({
                type: "equals",
                filter: filterValue,
              });
            }
          };

          return (
            <Select
              value={filterValues}
              onChange={handleChange}
              renderValue={(selected) => (selected as string[]).join(", ")}
              style={{ width: "100%" }}
            >
              {options.map((option: string) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={filterValues.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
    };
  }, []);

  const handleColumnChange = useCallback(
    async (columns?: string[]) => {
      if (!columns || columns?.length === 0 || !id) {
        return;
      }

      await setTableColumnsOnServer({ name: id, order: columns });
    },
    [id]
  );

  useEffect(() => {
    if (!id) {
      return;
    }
    getTableColumnsFromServer({ name: id }).then((data) => {
      const cols = data?.result?.[0]?.order;
      if (cols?.length) {
        setToShowColDefs((p) => {
          let temp: any = [];

          for (const column of cols) {
            const found = p?.find((i: any) => i.field === column);
            if (found) {
              temp.push(found);
            }
          }

          return temp;
        });
      } else {
        if (toShowColDefs?.length) {
          handleColumnChange(toShowColDefs?.map((i: any) => i.field));
        }
      }
    });
  }, [handleColumnChange, id, toShowColDefs?.length]);

  const getRowId = useCallback((o) => {
    return getId(o.data) || "1";
  }, []);

  const onColumnMoved = useCallback((p) => {
    handleColumnChange(
      p.api
        ?.getColumnDefs()
        ?.filter((i: any) => i.hide !== true)
        ?.map((i: any) => i.field)
    );
  }, []);

  const datasource = useMemo(
    () => ({
      getRows: ({ startRow, endRow, filterModel, sortModel, successCallback }: any) => {
        const pageSize = Math.abs(endRow - startRow) || 50;
        const page = (Math.round(startRow / pageSize) || 0) + 1;
        const filters: any = {};
        for (const key in filterModel || {}) {
          if (filterModel[key]?.dateFrom && filterModel[key].type === "inRange") {
            filters["min" + key] = Number(new Date(filterModel[key].dateFrom));
          }
          if (filterModel[key]?.dateFrom && filterModel[key].type === "equals") {
            filters[key] = Number(new Date(filterModel[key].dateFrom));
          }
          if (filterModel[key]?.dateFrom && filterModel[key].type === "greaterThan") {
            filters["min" + key] = Number(new Date(filterModel[key].dateFrom));
          }
          if (filterModel[key]?.dateFrom && filterModel[key].type === "lessThan") {
            filters["max" + key] = Number(new Date(filterModel[key].dateFrom));
          }
          if (filterModel[key]?.dateTo && filterModel[key].type === "inRange") {
            filters["max" + key] = Number(new Date(filterModel[key].dateTo));
          }
          if (filterModel[key]?.filter && filterModel[key]?.type !== "inRange") {
            filters[getOperator(filterModel[key]?.type) + key] = filterModel[key]?.filter;
          }
        }

        const sorts = sortModel?.[0]?.colId
          ? { sort: sortModel?.[0]?.colId, order: sortModel?.[0]?.sort.toUpperCase() }
          : { sort: initialSort?.sort || "date", order: initialSort?.order || "DESC" };

        get(url, {
          params: overrideFilterParams
            ? overrideFilterParams({ ...params, ...filters, ...sorts, pageSize, page })
            : { ...params, ...filters, ...sorts, pageSize, page },
        })
          .then((d) => {
            onFetchedData?.(d);
            if (overrideDataSource) {
              const { result, total } = overrideDataSource(d?.result || d || null, d?.total || d?.length || 0);

              successCallback(result, total);
              setTotal && setTotal(total);
            } else {
              successCallback(d?.result || d || null, d?.total || d?.length || 0);
              setTotal && setTotal(d.total);
            }
          })
          .catch((e) => {
            console.log(e);
            successCallback([], 0);
          });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialSort?.order, initialSort?.sort, JSON.stringify(params || {}), setTotal, url]
  );

  const onFilterChanged = useCallback(
    (p) => {
      try {
        const prev = qs.parse(window.location.search);
        const filterModel = p.api.getFilterModel();
        let filters: any = {};

        for (const key in filterModel || {}) {
          if (filterModel[key]?.dateFrom) {
            filters["min" + key] = Number(new Date(filterModel[key].dateFrom));
          }
          if (filterModel[key]?.dateTo) {
            filters["max" + key] = Number(new Date(filterModel[key].dateTo));
          }
          if (
            filterModel[key]?.filter !== "" &&
            filterModel[key]?.filter !== undefined &&
            filterModel[key]?.filter !== null &&
            filterModel[key]?.type !== "inRange"
          ) {
            filters[getOperator(filterModel[key]?.type) + key] = filterModel[key]?.filter;
          }
        }

        filters = overrideFilterParams ? overrideFilterParams(filters) : filters;
        onFilterMade?.(filters);
        const query = generateQuery({ ...prev, ...filters });
        history.push(history.location.pathname + "?" + query);
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, onFilterMade]
  );

  console.count("DataTable re-rendered");

  return (
    <div style={{ position: "relative", overflow: "auto" }}>
      <MoreButton
        columns={
          colDefs?.map((c: any) => ({
            header: c.headerName || "",
            key: (c as any).field || "",
            hide:
              (c as any).hide !== undefined
                ? (c as any).hide
                : !Boolean(columnsOrder?.some((i: any) => i === (c as any).field)),
          })) || []
        }
        onChange={(nv) => {
          setToShowColDefs(() => {
            const temp = colDefs?.concat().map((i, idx) => ({
              ...i,
              ...(nv?.find((j) => j.header === i.headerName || j.key === (i as any).field) || {}),
            }));

            handleColumnChange(temp?.filter((i) => i.hide)?.map((i: any) => i.field));
            return temp;
          });
          setColDefs((p) => {
            const temp = p?.concat().map((i, idx) => ({
              ...i,
              ...(nv?.find((j) => j.header === i.headerName || j.key === (i as any).field) || {}),
            }));

            handleColumnChange(temp?.filter((i) => !i.hide)?.map((i: any) => i.field));
            return temp;
          });
        }}
      />
      <BaseDataTable
        height={height}
        rowHeight={rowHeight || 25}
        onGridReady={onGridReady}
        // onGridReady={(e) => {
        //   const filterModel = {};
        //   const parsed = qs.parse(window.location.search);

        //   for (const key in parsed) {
        //     if (Object.prototype.hasOwnProperty.call(parsed, key)) {
        //       let filterValue: any = parsed[key];
        //       if (!filterValue || Array.isArray(filterValue)) {
        //         continue;
        //       }

        //       let field = key;
        //       let type = "equals";
        //       let filterType = "text";

        //       if (key.includes("min")) {
        //         field = removePrefix(field, "min");
        //         type = "greaterThan";
        //       } else if (key.includes("max")) {
        //         field = removePrefix(field, "max");
        //         type = "lessThan";
        //       } else if (key.includes("startsWith")) {
        //         field = removePrefix(field, "startsWith");
        //         type = "startsWith";
        //       } else if (key.includes("contain")) {
        //         field = removePrefix(field, "contain");
        //         type = "contains";
        //       }

        //       const column = (toShowColDefs as any)?.find((c: any) => c.field === field || c.key === field);

        //       if (column) {
        //         filterType = column.type;
        //       }

        //       if (filterType === "date" && isValidTimestamp(filterValue)) {
        //         filterValue = format(new Date(Number(filterValue)), "yyyy-MM-dd hh:mm:ss");
        //       }

        //       Object.assign(filterModel, {
        //         [field]: {
        //           filterType,
        //           type,
        //           filter: filterValue,
        //         },
        //       });
        //     }
        //   }

        //   console.log({ newFilterModel: filterModel });

        //   e.api.setFilterModel(filterModel);

        //   onGridReady?.(e);
        // }}
        columnDefs={toShowColDefs}
        defaultColDef={defaultColDef}
        rowModelType="infinite"
        columnTypes={columnTypes}
        rowSelection={rowSelection}
        onRowSelected={onRowSelected}
        onSelectionChanged={onSelectionChanged}
        getRowClass={getRowClass}
        singleClickEdit
        onCellValueChanged={onCellValueChanged}
        getRowId={getRowId}
        cacheOverflowSize={0}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={0}
        maxBlocksInCache={0}
        rowBuffer={0}
        pagination={!noPagination}
        onColumnMoved={onColumnMoved}
        //
        onFilterChanged={onFilterChanged}
        onSortChanged={(p) => {
          const prev = qs.parse(window.location.search);

          if (!p?.columns?.[0]?.getSort()) {
            const newQueries = new URLSearchParams(window.location.search);

            newQueries.delete("sort");
            newQueries.delete("order");

            history.push(history.location.pathname + "?" + newQueries.toString());
            return;
          }
          const query = generateQuery({
            ...prev,
            sort: p.columns[0].getColId(),
            order: p.columns[0].getSort()?.toUpperCase(),
          });
          history.push(history.location.pathname + "?" + query);
        }}
        datasource={datasource}
        //
        allowContextMenuWithControlKey={allowContextMenuWithControlKey}
        cellSelection={cellSelection}
        headerBackgroundColor={headerBackgroundColor || "#202831"}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  );
}

export default DataTable;
