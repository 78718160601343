// import HeaderPic from '../../../assets/svg/1.svg?react'
// import { ReactComponent as HeaderPic } from '../../../../assets/svg/1.svg'
import quoteHeader from "assets/pdf/quote_header.jpg";

export default function QuoteHeaderPage1() {
  return (
    <div className="w-full">
      {/* <HeaderPic className="w-full h-auto" />  */}
      <img src={quoteHeader} className="w-full h-auto" alt="" />
    </div>
  );
}
