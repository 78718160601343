import React, { useMemo, useState } from "react";
import { Box, Button, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import { get } from "api";
import { IUnit, checkVariants } from "api/units";
import { instructionType, saveProcedureStep, updateStep } from "api/instruction";
import ConfirmModal from "../../Modals/Confirm";
import Checked from "modules/Engineering/Instruction/Checked";
import ResultDialog from "modules/Production/Steps/ResultDialog";
import StartupInstructionPreviewModal from "modules/Engineering/Process/PreviewModal";
// import { groupBy } from "logic/utils";
import { useLock } from "common/Lock";
import ConfirmSwal from "common/Confirm";
import DocumentTab from "common/Document/Tab";
import { getId } from "logic/utils";
import useSWR from "swr";
import { CloseRounded } from "@mui/icons-material";

export const startedProcedureQuestion = "Do you want to start a new procedure or want to work on last procedure?";

export default function ProductionDataGrid({
  variant,
  serial,
  unitId,
  check,
  checked,
  checkVariant,
  unit,
  photoVariant,
  processType,
}: {
  variant: string;
  serial: string;
  unitId: string;
  check: boolean;
  checked: boolean;
  checkVariant: checkVariants;
  unit: IUnit;
  photoVariant: string;
  processType: "Internal Startup" | "Manufacturing" | "Quality Control" | "QualityControl" | "Test" | "Startup";
}) {
  const { lock } = useLock();
  const [refresh, setRefresh] = useState(0);
  const [previewModal, setPreviewModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<instructionType>();
  const [step, setStep] = useState(1);
  const [confirm, setConfirm] = useState(false);

  const [resultModal, setResultModal] = useState(false);
  const [resultStatus, setResultStatus] = useState<"success" | "failed">("success");
  const [resultSteps, setResultSteps] = useState<any[]>([]);
  const [workOnPrev, setWorkOnPrev] = useState(false);
  const [noStepModal, setNoStepModal] = useState(false);

  const [steps, setSteps] = useState<instructionType[]>([]);
  const instructionsSorted = useMemo(() => steps.sort((a, b) => Number(a.step) - Number(b.step)) || [], [steps]);
  // const instructionsGrouped = groupBy(steps || [], (i) => i.title);
  // const sections = Array.from(new Set(instructionsSorted?.map((i) => i.title)));

  const { data: doc } = useSWR<{ result: any }>(`/document/unit-${variant}/${unitId}`);
  console.log({ doc });

  const handleStart = async (workOnPrev = false) => {
    if (workOnPrev) setWorkOnPrev(true);
    try {
      setConfirm(false);
      const resp = await get(`/step`, {
        params: {
          start: workOnPrev ? undefined : true,
          UnitId: unitId,
          context: processType,
        },
      });
      if (resp?.result && resp?.result?.length > 0) {
        setSteps(resp.result as any);
        setSelectedItem(workOnPrev ? resp?.result?.find((r: any) => r.done === false) : (resp.result[0] as any));
        setPreviewModal(true);
        if (workOnPrev) {
          const temp = resp?.result?.findIndex((r: any) => r.done === false);
          setStep(temp + 1);
        }
      } else if (resp?.result && resp?.result?.length === 0) {
        setNoStepModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async () => {
    try {
      setConfirm(false);
      const resp = await get(`/step`, {
        params: {
          start: "stop",
          UnitId: unitId,
          context: processType,
        },
      });
      if (resp?.result && resp?.result?.length > 0) {
        setSteps(resp.result as any);
        setSelectedItem(resp.result[0] as any);
        setPreviewModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStepChange = async (p: number, { args, checkboxes, photo, step, id, instruction, currentStep }: any) => {
    try {
      setResultSteps((p) => [
        ...p,
        {
          ...instruction,
          UnitId: id,
          step,
          // TestingInstructionId: id,
          ...(Object.keys(args).length > 0 && { args: Object.keys(args).map((k) => ({ key: k, value: args[k] })) }),
          ...(Object.keys(checkboxes).length > 0 && {
            checkBoxes: Object.keys(checkboxes).map((k) => ({ title: k, selected: checkboxes[k] })),
          }),
          ...(photo && { photo }),
        },
      ]);
      if (p - 1 === instructionsSorted.length) {
        setStep(1);
        setResultModal(true);
        setResultStatus("success");
        setPreviewModal(false);
        setRefresh((p) => p + 1);
        return;
      }
      if (p > currentStep) {
        await saveProcedureStep(processType, {
          UnitId: unitId,
          step,
          stepId: getId(selectedItem),
          ...(Object.keys(args).length > 0 && {
            args: Object.keys(args).map((k) => ({ key: k.trim(), value: args[k] })),
          }),
          ...(Object.keys(checkboxes).length > 0 && {
            checkBoxes: Object.keys(checkboxes).map((k) => ({ title: k, selected: checkboxes[k] })),
          }),
          ...(photo && { photo }),
        });
      }
      setStep(p);
      if (instructionsSorted) {
        setSelectedItem(instructionsSorted[p - 1]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFailedProcedure = () => {
    ConfirmSwal({
      confirmText: "Terminate",
      onConfirm() {
        setSteps(steps?.slice(0, step));
        setStep(1);
        setPreviewModal(false);
        updateStep(selectedItem?.id as string, { cancel: true });
        setResultModal(true);
        setResultStatus("failed");
        setRefresh((p) => p + 1);
      },
    });
  };

  return (
    <>
      {noStepModal && (
        <Dialog open={noStepModal} onClose={() => setNoStepModal(false)}>
          <Box sx={{ p: "1rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" px={1}>
              <IconButton
                title="Close"
                sx={{
                  background: "#EEEFF2",
                  borderRadius: 2,
                  "& .MuiButtonBase-root ": {
                    padding: "4px",
                  },
                }}
                onClick={() => setNoStepModal(false)}
              >
                <CloseRounded sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Box>
            <Typography sx={{ fontSize: "15px", mt: "1rem", px: "0.5rem" }}>
              No steps has been defined for this procedure.
            </Typography>
          </Box>
        </Dialog>
      )}
      {resultModal && (
        <ResultDialog
          unitId={unitId}
          open={resultModal}
          status={resultStatus}
          onClose={() => {
            setRefresh((p) => p + 1);
            setResultSteps([]);
            setResultModal(false);
          }}
          steps={resultSteps}
          variant={variant}
          serial={serial}
          model={unit?.unitModel}
          description={unit?.description}
          name={unit?.name}
        />
      )}
      <ConfirmModal
        title="Start Procedure"
        confirmButtonTitle="Yes"
        cancelTitle="No"
        open={confirm}
        onClose={handleClose}
        notShowText
        onConfirm={handleStart}
        text={doc?.result?.length === 0 ? `Do you want to start the process?` : startedProcedureQuestion}
      />
      {selectedItem && (
        <StartupInstructionPreviewModal
          open={previewModal}
          onClose={handleFailedProcedure}
          step={step}
          totalSteps={steps.length || 0}
          onStepChange={handleStepChange}
          stepId={getId(selectedItem)}
          workOnPrev={workOnPrev}
        />
      )}
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Button variant="outlined" sx={{ my: 1 }} disabled={lock} onClick={() => setConfirm(true)}>
          Start Procedure
        </Button>
        {check && <Checked itemId={unitId} model="unit" variant={checkVariant} sx={{ mx: 2 }} />}
      </Box>
      <DocumentTab
        showAdd={false}
        itemId={unitId}
        model={`unit-${variant}`}
        downloadAs="document.pdf"
        controlledRefresh={refresh}
      />
    </>
  );
}
