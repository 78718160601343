import React, { forwardRef } from "react";
import styled from "@emotion/styled";

import dspmLogo from "assets/pdf/dspmlogo.png";

const Wrapper = styled.div`
  width: 21cm;
  height: 29.7cm;
  padding: 1em;
  display: flex;
  flex-direction: column;

  .logo {
    text-align: center;
    margin-bottom: 30px;
  }
  .logo img {
    max-width: 200px;
  }
  .instructions {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
  }
  .instructions p {
    margin-bottom: 15px;
  }
  .document-number {
    margin-top: auto;
    text-align: right;
    font-size: 0.8em;
    color: #666;
  }
`;

function CoverPage(props: any, ref: any) {
  return (
    <Wrapper ref={ref}>
      <div className="logo">
        <img src={dspmLogo} alt="DSPM Inc. Logo" />
      </div>
      <div className="instructions">
        <p>
          All attached service forms are to be filled out completely, and returned via fax or email within 24 hours of
          the completion of the service call.
        </p>
        <p>
          Vendor invoices will not be processed until the receipt of the service forms completely filled out with
          customer signature and the return of all parts unused and used.
        </p>
        <p>Vendors wishing to use there own forms may do so as an addition to the forms attached.</p>
      </div>
      <div className="document-number">
        Document No.: 074-0006-00D
        <br />
        Page 1
      </div>
    </Wrapper>
  );
}

export default forwardRef(CoverPage);
