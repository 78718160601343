import styled from "@emotion/styled";
import React, { forwardRef } from "react";

const Wrapper = styled.div`
  width: 21cm;
  height: 29.7cm;

  input {
    border: none;
    outline: none;
  }

  .form-container {
    max-width: 800px;
    margin: 0 auto;
    border: 2px solid #000;
  }
  .header {
    background-color: #f0f0f0;
    padding: 4px;
    border-bottom: 2px solid #000;
  }
  .logo {
    font-weight: bold;
    font-size: 24px;
  }
  .title {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
  }
  .contact-info {
    font-size: 11px;
    text-align: center;
  }
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px;
  }
  .full-width {
    grid-column: 1 / -1;
  }
  .form-field {
    display: flex;
    flex-direction: column;
  }
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  input,
  textarea {
    padding: 5px;
    border: 1px solid #ccc;
  }
  .time-table {
    width: 100%;
    border-collapse: collapse;
  }
  .time-table th,
  .time-table td {
    border: 1px solid #000;
    padding: 5px;
  }
  .parts-table {
    width: 100%;
    border-collapse: collapse;
  }
  .parts-table th,
  .parts-table td {
    border: 1px solid #000;
    padding: 5px;
  }
  .signature-section {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .signature-field {
    width: 45%;
  }
`;

function CustomerServiceForm(props: any, ref: any) {
  return (
    <Wrapper ref={ref}>
      <div className="form-container">
        <div className="header">
          <div className="title">CUSTOMER SERVICE REPORT</div>
          <div className="contact-info">
            Digital Signal Power Manufacturer, Inc.- PowerClone
            <br />
            1921 So. Quaker Ridge Place
            <br />
            Ontario, CA 91761
            <br />
            Voice: (714) 970-2304 Fax: (909) 930-3335 Email: customersupport@dspmanufacturing.com
          </div>
        </div>
        <div className="form-grid">
          <div className="form-field full-width">
            <label>Bill To:</label>
            <input type="text" value="DSPM INC" />
          </div>
          <div className="form-field full-width">
            <input type="text" value="1921 S. QUAKER RIDGE PLACE" />
          </div>
          <div className="form-field">
            <label>City/State:</label>
            <input type="text" value="ONTARIO, CA." />
          </div>
          <div className="form-field">
            <label>Zip Code:</label>
            <input type="text" value="91761" />
          </div>
          <div className="form-field">
            <label>Billing Contact:</label>
            <input type="text" value="CAREY NEILL" />
          </div>
          <div className="form-field">
            <label>Service Contact:</label>
            <input type="text" value="BRYANA DANA" />
          </div>
          <div className="form-field">
            <label>Billing Contact Title:</label>
            <input type="text" value="CONTROLLER" />
          </div>
          <div className="form-field">
            <label>Service Phone:</label>
            <input type="text" value="714-970-2304" />
          </div>
          <div className="form-field">
            <label>Billing Phone:</label>
            <input type="text" value="714-970-2304" />
          </div>
          <div className="form-field">
            <label>Product Type (i.e. UPS Regulator):</label>
            <input type="text" />
          </div>
          <div className="form-field full-width">
            <label>Customer Description of Problem/Request:</label>
            <textarea></textarea>
          </div>
          <div className="form-field full-width">
            <label>Description of Work Performed:</label>
            <textarea></textarea>
          </div>
          <div className="full-width">
            <table className="time-table">
              <tr>
                <th colSpan={3}>Travel Time</th>
                <th colSpan={3}>Service Time</th>
              </tr>
              <tr>
                <td>To Site 1</td>
                <td>From Site 1</td>
                <td>=</td>
                <td>Start 1</td>
                <td>Stop 1</td>
                <td>=</td>
              </tr>
              <tr>
                <td>To Site 2</td>
                <td>From Site 2</td>
                <td>=</td>
                <td>Start 2</td>
                <td>Stop 2</td>
                <td>=</td>
              </tr>
              <tr>
                <td>To Site 3</td>
                <td>From Site 3</td>
                <td>=</td>
                <td>Start 3</td>
                <td>Stop 3</td>
                <td>=</td>
              </tr>
              <tr>
                <td colSpan={3}>Total Travel Time:</td>
                <td colSpan={3}>Total Service Time:</td>
              </tr>
            </table>
          </div>
          <div className="full-width">
            <table className="parts-table">
              <tr>
                <th>Qty</th>
                <th>Part Number or Desc.</th>
                <th>Price ea.</th>
                <th>Ext Price</th>
              </tr>
              <tr>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
              </tr>
            </table>
          </div>
          <div className="form-field">
            <label>Service Time @</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Travel Time @</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Parts</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Tax</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Per Diem/Expenses</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Airfare</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Car Rental</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Miles</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Other</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Estimated Total Charges</label>
            <input type="text" />
          </div>
          <div className="signature-section full-width">
            <div className="signature-field">
              <label>Service Person's Signature:</label>
              <label>Date:</label>
            </div>
            <div className="signature-field">
              <label>Customer's Signature:</label>
              <label>Date:</label>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default forwardRef(CustomerServiceForm);
