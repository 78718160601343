import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import SectionHeader from "./SectionHeader";
import { formatTimestampToDate } from "logic/date";
import ItemTable from "./ItemTable";
import { formatCurrency } from "logic/utils";
export default function QuotePage2({ data, items, pageNumber, QrCode }) {
  return (
    <div className="relative flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} items={items} QrCode={QrCode} />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content({ data, items, QrCode }) {
  const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
    const words = text.split(" "); // Split the text into words
    const elements = []; // Array to hold React elements

    words.forEach((word, index) => {
      elements.push(<span key={index}>{word} </span>); // Push each word as a span

      // After every 'wordsPerBreak', add a line break
      if ((index + 1) % wordsPerBreak === 0) {
        elements.push(<br key={`break-${index}`} />); // Push a line break
      }
    });

    return elements; // Return the array of React elements
  };

  var ServiceProgramTable = null;
  var ServiceTable = null;
  var FreightTable = null;

  items.forEach((item) => {
    // Check if ItemId is null or undefined
    if (!item.ItemId) {
      if (item.description && item.description.includes("Service Program Options")) {
        ServiceProgramTable = item;
      } else if (item.description && item.description.includes("Freight")) {
        FreightTable = item;
      } else {
        ServiceTable = item;
      }
    }
  });
  // console.log('====================================');
  // console.log(ServiceProgramTable);
  // console.log(ServiceTable);
  // console.log(FreightTable);

  // console.log('====================================');

  let dataTable1 = {};
  let dataTable2 = {};
  let dataTable3 = {};
  function ServiceTableSectionNumber() {
    if (items[0].ItemId && ServiceProgramTable != null) return 3;
    else if (!items[0].ItemId && ServiceProgramTable != null) return 2;
    else return 1;
  }

  function FreightTableSectionNumber() {
    if (items[0].ItemId && ServiceProgramTable != null && ServiceTable != null) return 4;
    else if (!items[0].ItemId && ServiceProgramTable != null && ServiceTable != null) return 3;
    else if (!items[0].ItemId && (ServiceProgramTable == null || ServiceTable == null)) return 2;
    else return 1;
  }

  const extractHeader = (text) => {
    // Split the text by new lines and trim whitespace
    const lines = text
      .split("⚭")
      .map((line) => line.trim())
      .filter((line) => line);
    // The first line is considered the header
    const header = lines[0];
    return header; // Return the header
  };

  if (ServiceProgramTable !== null) {
    // ServiceProgramSectionTitle = extractHeader(ServiceProgramTable.description);
    // ServiceProgramSectionTitle = 'Service Program Options for ' + ServiceProgramTable.itemNo
    dataTable1 = {
      groupLine: "",
      description: ServiceProgramTable.description,
      qty: ServiceProgramTable.qty,
      unitPrice: formatCurrency(ServiceProgramTable.price),
      totalPrice: formatCurrency(ServiceProgramTable.total),
    };
    if (ServiceProgramTable.sort && ServiceProgramTable.group)
      dataTable1.groupLine = `[${ServiceProgramTable.sort}]-${ServiceProgramTable.group}`;
  }

  if (ServiceTable !== null) {
    dataTable2 = {
      groupLine: "",
      description: ServiceTable.description,
      qty: ServiceTable.qty,
      unitPrice: formatCurrency(ServiceTable.price),
      totalPrice: formatCurrency(ServiceTable.total),
    };
    if (ServiceTable.sort && ServiceTable.group) dataTable2.groupLine = `[${ServiceTable.sort}]-${ServiceTable.group}`;
  }

  if (FreightTable !== null) {
    dataTable3 = {
      groupLine: "",
      description: FreightTable.description,
      qty: FreightTable.qty,
      unitPrice: formatCurrency(FreightTable.price),
      totalPrice: formatCurrency(FreightTable.total),
    };
    if (FreightTable.sort && FreightTable.group) dataTable3.groupLine = `[${ServiceTable.sort}]-${ServiceTable.group}`;
  }
  const splitEmails = (emails) => {
    if (!emails) return []; // Return an empty array if the input is empty or null
    return emails
      .split(";")
      .map((email) => email.trim())
      .filter((email) => email); // Trim and filter empty entries
  };

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        {!items[0].ItemId ? (
          <>
            <div className="mt-[2%]">
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-col justify-center">{QrCode}</div>
                <div className="flex flex-col bg-[#ececec] p-3 w-[41%]">
                  <div>
                    <span className="text-[14px]">Agency:</span>
                  </div>
                  <div>
                    <span className="text-[14px] font-light">{data.rep ? data.rep : "-"}</span>
                  </div>
                </div>
                <div className="flex flex-col bg-[#ececec] p-3 w-[41%]">
                  <div>
                    <span className="text-[14px]">Requested:</span>
                  </div>
                  <div>
                    <span className="text-[14px] font-light">{data.requestedByName ? data.requestedByName : "-"}</span>
                  </div>
                  <div className="mt-[4%]">
                    <span className="text-[14px]">By:</span>
                  </div>
                  <div>
                    {data.requestedByEmail ? (
                      splitEmails(data.requestedByEmail).length === 0 ? ( // Check if there are no emails
                        <div>
                          <span className="text-[14px] font-light">-</span>
                        </div>
                      ) : (
                        splitEmails(data.requestedByEmail).map((email, index) => (
                          <div key={index}>
                            <span className="text-[14px] font-light">{email}</span>
                          </div>
                        ))
                      )
                    ) : (
                      "-"
                    )}
                  </div>
                  <div>
                    <span className="text-[14px] font-light">{data.requestedByPhone ? data.requestedByPhone : ""}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[2%] bg-[#6a6a6a]">
              <div className="flex flex-row p-3">
                <div className="flex flex-col flex-1">
                  <div className="flex flex-row h-full">
                    <div className="flex flex-col justify-around">
                      <div>
                        <span className="text-white text-[12px] font-light">Quote # :</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-light">Prepared On:</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-light">Expires:</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-light">Lead Time:</span>
                      </div>
                    </div>
                    <div className="flex flex-col ml-[4%] justify-around">
                      <div>
                        <span className="text-white text-[12px]">{data.number ? data.number : "-"}</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px]">
                          {data.entryDate ? formatTimestampToDate(data.entryDate) : "-"}
                        </span>
                      </div>
                      <div>
                        <span className="text-white text-[12px]">
                          {data.expireDate ? formatTimestampToDate(data.expireDate) : "-"}
                        </span>
                      </div>
                      <div>
                        <span className="text-white text-[12px]">{data.leadTime ? data.leadTime : "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <div className="flex flex-row">
                    <div className="flex flex-col">
                      <div>
                        <span className="text-white text-[12px] font-light">Ship Via:</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-light">Freight Terms:</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-light">Payment Terms:</span>
                      </div>
                    </div>
                    <div className="flex flex-col ml-[4%] ">
                      <div>
                        <span className="text-white text-[12px]">-</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px]">-</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-bold">
                          {data.paymentTerms ? insertLineBreaksAfterWords(data.paymentTerms, 6) : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {ServiceProgramTable !== null ? (
          <>
            <div className="mt-[4%]">
              <SectionHeader
                // title={`Service Program Options for ${items[0].ItemId.no}`}
                title={"Service Program Options"}
                number={items[0].ItemId ? 2 : 1}
              />
            </div>
            <div className="mt-[2%]">
              <ItemTable
                groupLine={dataTable1.groupLine}
                description={dataTable1.description}
                qty={dataTable1.qty}
                unitPrice={dataTable1.unitPrice}
                totalPrice={dataTable1.totalPrice}
                descriptionElement={null}
              />
            </div>
          </>
        ) : null}
        {ServiceTable != null ? (
          <>
            <div className="mt-[4%]">
              <SectionHeader title={ServiceTable.itemNo} number={ServiceTableSectionNumber()} />
            </div>
            <div className="mt-[2%]">
              <ItemTable
                groupLine={dataTable2.groupLine}
                description={dataTable2.description}
                qty={dataTable2.qty}
                unitPrice={dataTable2.unitPrice}
                totalPrice={dataTable2.totalPrice}
                descriptionElement={<DescriptionTable2 description={dataTable2.description} />}
              />
            </div>
          </>
        ) : null}
        {FreightTable != null ? (
          <>
            <div className="mt-[4%]">
              <SectionHeader title={"FREIGHT"} number={FreightTableSectionNumber()} />
            </div>
            <div className="mt-[2%]">
              <ItemTable
                groupLine={dataTable3.groupLine}
                description={""}
                qty={dataTable3.qty}
                unitPrice={dataTable3.unitPrice}
                totalPrice={dataTable3.totalPrice}
                descriptionElement={<DescriptionTable3 description={dataTable3.description} />}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
function DescriptionTable2({ description }) {
  return (
    <div>
      <span className="text-[11px] font-light">{description}</span>
    </div>
  );
}
function DescriptionTable3({ description }) {
  // Remove the specific sentence from the description if it exists
  const sanitizedDescription = description.replace(/Freight is PREPAID AND ADD unless other wise noted\./i, "").trim(); // Trim to remove any leading or trailing whitespace

  return (
    <div>
      <span className="text-[11px] font-light text-primaryOrange">
        Freight is PREPAID AND ADD unless otherwise noted.
      </span>
      <br />
      <span className="text-[11px] font-light">
        {sanitizedDescription} {/* Render the sanitized description */}
      </span>
    </div>
  );
}
