import React from "react";
import Footer from "../Quote/footer/Footer";
import styled from "@emotion/styled";
import Header from "../PO/header/Header";
import SectionHeader2 from "../Quote/SectionHeader2";

const Wrapper = styled("div")`
  padding-top: 16px;

  p,
  h3 {
    padding: 0 16px;
    text-align: justify;
  }
  p {
    font-size: 12px;
  }
`;

export default function Terms({ number, pageNumber, title }: { number: string; pageNumber: string; title: string }) {
  return (
    <div className="mt-8 mx-auto h-[28cm] relative">
      <div className="flex w-full">
        <Header />
      </div>
      <Wrapper>
        <div className="my-8">
          <SectionHeader2 title={"TERMS AND CONDITIONS:"} />
        </div>
        <p className="font-bold mt-8">Acceptance</p>
        <p>
          The terms of sale contained herein apply to all sales transactions including Quotations and Purchase Orders
          entered into by Seller directly with the Buyer for standard product. Custom product sales may be subject to
          additional terms and conditions. This acceptance is conditional upon Buyer's assent to the terms and
          conditions set out herein in lieu of those in Buyer's purchase orders. Modifications or additions to these
          terms of sale will be recognized only if accepted in writing by an officer of the Seller. Seller's failure to
          object to provisions contained in any communication from Buyer shall not be deemed a waiver of the provisions
          contained herein.
        </p>

        <p className="font-bold mt-8">Prices</p>
        <p>
          Quotations automatically expire 90 days from the date issued and are subject to withdrawal or extension by
          notice within that period. Seller's published prices are subject to change without notice. New pricing will
          not be applied to existing orders accepted by Seller. Prices quoted are for products only and do not include
          any other charges unless specifically covered in writing by Seller. Any discrepancies relating to price must
          be called to Seller's attention for resolution within 30 days of the billing date.
        </p>

        <p className="font-bold mt-8">Taxes</p>
        <p>
          Unless otherwise agreed upon in writing or required by law, all prices will be quoted and billed exclusive of
          federal, state and local excise sales and similar taxes. Such taxes, when applicable shall appear as
          additional terms on the invoice. If exemption from such taxes is claimed, buyer must provide, or have on file
          with Seller, a certificate of exemption at the time the Purchase Order is submitted to Seller.
        </p>

        <p className="font-bold mt-8">Orders Acceptance</p>
        <p>
          Buyer's Purchase Orders must be in writing and approved by an authorized representative of the Buyer. Seller
          shall determine order acceptance at its discretion, and may impose restrictions for quantity requirements in
          regard to total order value and product packaging minimums. Seller can also restrict time frames for scheduled
          deliveries and change order activity. Terms of acceptance will be mutually understood by both parties, and
          authorized in writing by Seller at the Buyer's request.
        </p>

        <p className="font-bold mt-8">Terms of Payment</p>
        <p>
          The Seller reserves the right to require payment in advance or C.O.D. and otherwise modify credit terms.
          Unless otherwise agreed, partial shipments can be made and payment shall become due in accordance with the
          designated terms upon submission of invoices. Each shipment shall be invoiced separately and Buyer shall pay
          for each shipment as invoiced. If shipments are delayed by Buyer without Seller's prior written consent,
          payments shall become due on the date when Seller is prepared to make shipment. Products held for the Buyer by
          the Seller shall be at the risk and expense of the Buyer. Seller reserves the right to add a monthly service
          charge as allowed by law.
        </p>

        <p className="font-bold mt-8">Title and Delivery</p>
        <p>
          Unless otherwise instructed in writing, Seller shall ship by the methods it deems most advantageous.
          Transportation shall be collect, or if prepaid, will be subsequently billed to the Buyer. Title shall pass to
          the Buyer, and Seller's liability as to delivery shall cease on delivery of products to carrier at Seller's
          facility, regardless of any provisions for payment of freight or insurance or the form of shipping documents.
          Risk of loss or damage in transit shall rest with the Buyer. All claims for loss or damage will be filed with
          the carrier. Claims against Seller for shortages occurring before delivery to carrier shall be waived unless
          made within thirty (30) days after delivery of shipment to the Buyer.
        </p>

        <p className="font-bold mt-8">Contingencies</p>
        <p>
          All shipping dates and quantities are approximate. Seller shall use reasonable efforts to fill all orders
          according to the agreed upon schedule and quantity. Seller shall not be responsible for any failure to perform
          resulting from unforeseen circumstances or causes beyond Seller's reasonable control. Such causes include, but
          are not limited to; strikes, floods, fires, labor disputes, accidents, inability to maintain materials or
          supplies, excessive demand for products over the available supply, custom duties or surcharges, and
          interruption for any reason in the manufacture of Seller's products by Seller's suppliers, any act of God, or
          the action of any government. In the event of any delay caused by such a contingency, the date of delivery
          shall, at the request of the Seller be deferred for period equal to the period of delay.
        </p>
      </Wrapper>
      <div style={{ position: "absolute", top: "27cm" }}>
        <Footer DocNumber={number} pageNumber={pageNumber} title={title} email="sales-dspm@dspmanufacturing.com" />
      </div>
    </div>
  );
}
