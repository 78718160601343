import styled from "@emotion/styled";
import React, { forwardRef } from "react";

const Wrapper = styled.div`
  width: 21cm;
  height: 29.7cm;

  input {
    border: none;
    outline: none;
  }

  .form-container {
    max-width: 800px;
    margin: 0 auto;
    border: 2px solid #000;
  }
  .header {
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom: 2px solid #000;
  }
  .logo {
    font-weight: bold;
    font-size: 24px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  .contact-info {
    font-size: 14px;
    text-align: center;
  }
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px;
  }
  .full-width {
    grid-column: 1 / -1;
  }
  .form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  label {
    font-weight: bold;
  }
  input {
    width: 50%;
    padding: 5px;
    border: 1px solid #ccc;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  th,
  td {
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
  }
  .signature-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .signature-field {
    width: 45%;
  }
`;

function PreventiveTable(props: any, ref: any) {
  return (
    <Wrapper ref={ref}>
      <div className="form-container">
        <div className="header">
          <div className="title">PREVENTIVE MAINTENANCE/STARTUP REPORT</div>
          <div className="contact-info">
            Digital Signal Power Manufacturer, Inc.- PowerClone
            <br />
            1921 So. Quaker Ridge Place
            <br />
            Ontario, CA 91761
            <br />
            Voice: (714) 970-2304 Fax: (909) 930-3335 Email: customersupport@dspmanufacturing.com
          </div>
        </div>
        <div className="form-grid">
          <div className="form-field">
            <label>Model No.:</label>
            <input type="text" value="" />
          </div>
          <div className="form-field">
            <label>Serial No.:</label>
            <input type="text" value="" />
          </div>
        </div>
        <table>
          <tr>
            <th>Battery No.</th>
            <th>No Load</th>
            <th>Load</th>
            <th>Battery No.</th>
            <th>No Load</th>
            <th>Load</th>
          </tr>
          {Array(24)
            .fill(0)
            .map((_, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>{i + 35}</td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
              </tr>
            ))}
        </table>
        <div className="signature-section">
          <div className="signature-field">
            <label>Service Person's Signature:</label>
            <input type="text" />
            <label>Date:</label>
          </div>
          <div className="signature-field">
            <label>Customer's Signature:</label>
            <input type="text" />
            <label>Date:</label>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default forwardRef(PreventiveTable);
