import { get } from ".";

export type employeeNotificationType = {
  id: string;
  title: string;
  body: string;
  type: string;
  recipients: {
    id: string;
    seen: boolean;
  }[];
  data: {
    number: string;
    ItemId?: string;
  };
  seen?: boolean;
  done: boolean;
  seenBy: string[];
  deleted: boolean;
  createdAt: number;
  updatedAt: number;
  __v: number;
};

export interface notificationType {
  id: string;
  createDoc: boolean;
  data?: any;
  body: string;
  title: string;
  type: string;
  createdAt: number;
  updatedAt: number;
  __v: number;
}

export const sendNotificationTest = () => {
  return get("/firebasetest");
};

export const getNotifications = () => {
  return get("/notification");
};

export const toggleSeenNotification = (id: string) => {
  return get(`/notification/${id}`);
};
