import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import SectionHeader2 from "./SectionHeader2";

export default function QuotePage13({ data, pageNumber }) {
  return (
    <div className="relative flex flex-col items-center h-full w-[98%] mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div style={{ position: "absolute", left: 0, top: "25cm", width: "20.5cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    Equipment: "TOS - Equipment Turn On Service WITHOUT On Site Warranty",
    Equipment1:
      "Equipment Turn on Service is for customer that would like to have start-up performed without adding additional coverage to the equipment warranty.",
    Equipment2: "This coverage can be performed as an On-Site Startup Service for any DSPM product.",
    Equipment3:
      "All parts and labor to repair if NOT covered under a current Warranty or Service Plan and will be billed at DSPM current rates unless covered by a current warranty or service plan.",
    Certification: "CI - Certification Inspection Visit",
    Certification1:
      "The Certification/Inspection is a service provided to certify equipment capable is placed under a warranty/service plan.",
    Certification2: "The Certification Inspection is complete when all repairs are completed.",
    Certification3:
      "The Certification Inspection is billed at DSPM current hourly rates based on time of day and day of week service is performed.",
    Certification4: "All parts needed to complete the repairs are billed at DSPM current list prices.",
    Extended: "SW58 - Extended On-Site Warranty 8-5 Monday-Friday",
    Extended1:
      "This Extended Warranty Plan is to be purchased while the equipment is still under current warranty coverage, or after approval by DSPM and a Certification Inspection by DSPM.",
    Extended2:
      "This plan covers all replacement pans and labor including travel time and expenses for all emergency calls to service the unit. All service calls will be made between 8:00 a.m. and 5:00 p.m. and will be limited to Monday through Friday with the exception of the DSPM's designated holidays.",
    Extended3:
      "If the Customer requests remedial maintenance outside of the contracted coverage or preventive maintenance, DSPM will provide this service. This service will be charged to the Customer at DSPM's standard hourly rates in effect at the time of the service, and will be subject to an available field engineer.",
    Extended4:
      "DSPM will provide all replacement parts for parts that are found defective during emergency service calls. If replaced parts are used from any Customer- spare parts kit, DSPM will replace them to the Customer-owned spare parts kit with no charges to the Customer.",
  };
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div>
          <SectionHeader2 title={"Service Program Descriptions"} />
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">{texts.Equipment}</span>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Equipment1}</span>
            </div>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Equipment2}</span>
            </div>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Equipment3}</span>
            </div>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">{texts.Certification}</span>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Certification1}</span>
            </div>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Certification2}</span>
            </div>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Certification3}</span>
            </div>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Certification4}</span>
            </div>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">{texts.Extended}</span>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Extended1}</span>
            </div>
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Extended2}</span>
            </div>{" "}
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Extended3}</span>
            </div>{" "}
            <div className="flex flex-row">
              <span className="text-[16px] font-light">•</span>
              <span className="text-[16px] font-light ml-1 text-justify">{texts.Extended4}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
