import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSWRImmutable from "swr/immutable";
import jsPDF from "jspdf";
import { toCanvas } from "html-to-image";

import MyDialog from "app/Dialog";
import { IQuote } from "api/quote";
import { getId } from "logic/utils";
import { ILineItem } from "api/lineItem";
import { Button, LinearProgress } from "@mui/material";

import { createAModelDocument } from "api/document";
import QRCode from "app/QRCode";
import { QR } from "logic/QR";
import Swal from "sweetalert2";
import ItemTable from "PDFTemplates/New/Quote/ItemTable";
// import PdfPages from "pages/PdfPages";
import QuotePage0 from "PDFTemplates/New/Quote/QuotePage0";
// import QuotePage1 from "PDFTemplates/New/Quote/QuotePage1";
// import QuotePage2 from "PDFTemplates/New/Quote/QuotePage2";
import QuotePage3 from "PDFTemplates/New/Quote/QuotePage3";
import QuotePage4 from "PDFTemplates/New/Quote/QuotePage4";
import QuotePage5 from "PDFTemplates/New/Quote/QuotePage5";
import QuotePage6 from "PDFTemplates/New/Quote/QuotePage6";
import QuotePage7 from "PDFTemplates/New/Quote/QuotePage7";
import QuotePage8 from "PDFTemplates/New/Quote/QuotePage8";
import QuotePage9 from "PDFTemplates/New/Quote/QuotePage9";
import QuotePage10 from "PDFTemplates/New/Quote/QuotePage10";
import QuotePage11 from "PDFTemplates/New/Quote/QuotePage11";
import QuotePage12 from "PDFTemplates/New/Quote/QuotePage12";
import QuotePage13 from "PDFTemplates/New/Quote/QuotePage13";
import QuotePage14 from "PDFTemplates/New/Quote/QuotePage14";
import QuotePage15 from "PDFTemplates/New/Quote/QuotePage15";
import QuotePage16 from "PDFTemplates/New/Quote/QuotePage16";
import QuotePage17 from "PDFTemplates/New/Quote/QuotePage17";
import QuotePage18 from "PDFTemplates/New/Quote/QuotePage18";
import QuotePage19 from "PDFTemplates/New/Quote/QuotePage19";
import QuotePage20 from "PDFTemplates/New/Quote/QuotePage20";
import QuotePageX from "PDFTemplates/New/Quote/QuotePageX";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

const MAX_CONTENT_HEIGHT = 850; // Adjust this based on the A4 page content height

export default function QuotePdfModal({
  quoteId,
  open,
  onClose,
  onDone,
}: {
  quoteId: string;
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [heights, setHeights] = useState<number[]>([]);
  const [uploaded, setUploaded] = useState(false);
  const { data: quote, isLoading } = useSWRImmutable<IQuote>(`/quote/${quoteId}`);
  const { data: lineItems, isLoading: isLinesValidating } = useSWRImmutable<{ result: ILineItem[]; total: number }>(
    `/lineitem?QuoteId=${getId(quote)}`
  );

  const freight =
    lineItems?.result
      ?.filter((i: any) => i?.freight || i.itemNo?.includes("FREIGHT"))
      ?.reduce((p: any, c: any) => p + (c?.total || c?.price || 0), 0) || 0;

  const [totalPagesContent, setTotalPagesContent] = useState<ILineItem[][]>([]);
  const [sectionNumber, setSectionNumber] = useState<number[]>([]);
  const [pageCalculationFinished, setPageCalculationFinished] = useState(false);

  const [repQuote, setRepQuote] = useState(false);

  useEffect(() => {
    if (lineItems?.result) {
      CalcPages(MAX_CONTENT_HEIGHT, lineItems.result);
    }
  }, [heights]);

  useEffect(() => {
    const fetchDataAndCalculate = async () => {
      if (lineItems?.result) {
        // Run your async function here
        var heights = [];
        for (const item of lineItems.result) {
          const size = await measureDiv(item);
          heights.push(size.height);
        }
        setHeights(heights);
      }
    };
    fetchDataAndCalculate(); // Call the async function
  }, [lineItems]);

  const QuotePagesRef = useRef<HTMLDivElement | null>(null);

  const getQrUrl = useCallback(() => {
    const qr = new QR("quote");
    return qr.generateQuoteUrl({ quoteId: quoteId });
  }, [quoteId]);

  const QuoteComponents = useMemo(
    () => [
      { component: <QuotePage0 title={repQuote ? "Representative Quote" : "Quote"} />, key: "CoverPage" },
      ...Array.from({ length: totalPagesContent.length }, (_, index) => ({
        component: (
          <QuotePageX
            data={quote}
            page={index}
            pageNumber={index + 3} // Start from page 3
            items={totalPagesContent[index]}
            QrCode={<QRCode width={69} height={69} value={getQrUrl()} />}
            sectionNumbers={sectionNumber}
            footerTitle=""
          />
        ),
        key: `pagex${index + 3}`, // Adjust key accordingly
      })),
      {
        component: <QuotePage3 data={quote} pageNumber={3} rep={repQuote} totalFreight={freight} footerTitle="" />,
        key: "page3",
      },
      { component: <QuotePage4 data={quote} pageNumber={4} />, key: "page4" },
      { component: <QuotePage5 data={quote} pageNumber={5} />, key: "page5" },
      { component: <QuotePage6 data={quote} pageNumber={6} />, key: "page6" },
      { component: <QuotePage7 data={quote} pageNumber={7} />, key: "page7" },
      { component: <QuotePage8 data={quote} pageNumber={8} />, key: "page8" },
      { component: <QuotePage9 data={quote} pageNumber={9} />, key: "page9" },
      { component: <QuotePage10 data={quote} pageNumber={10} />, key: "page10" },
      { component: <QuotePage11 data={quote} pageNumber={11} />, key: "page11" },
      { component: <QuotePage12 data={quote} pageNumber={12} />, key: "page12" },
      { component: <QuotePage13 data={quote} pageNumber={13} />, key: "page13" },
      { component: <QuotePage14 data={quote} pageNumber={14} />, key: "page14" },
      { component: <QuotePage15 data={quote} pageNumber={15} />, key: "page15" },
      { component: <QuotePage16 data={quote} pageNumber={16} />, key: "page16" },
      { component: <QuotePage17 data={quote} pageNumber={17} />, key: "page17" },
      { component: <QuotePage18 data={quote} pageNumber={18} />, key: "page18" },
      { component: <QuotePage19 data={quote} pageNumber={19} />, key: "page19" },
      { component: <QuotePage20 data={quote} pageNumber={20} />, key: "page20" },
    ],
    [freight, getQrUrl, quote, repQuote, sectionNumber, totalPagesContent]
  );

  const handleDownload = useCallback(
    async ({ download, rep }: { download: boolean; rep: boolean }) => {
      setLoading(true);
      try {
        if (!quote || !quoteId || !QuotePagesRef.current) {
          return;
        }
        const pdf = new jsPDF("p", "pt", "a4");
        const pages = QuotePagesRef.current.children;

        for (let i = 0; i < pages.length; i++) {
          const page = pages[i] as HTMLElement;

          if (QuoteComponents[i]?.key?.includes("x")) {
            let start = performance.now();
            await pdf.html(page, {
              x: 20,
              y: i * 830 + 20,
              width: 560,
              html2canvas: {
                scale: 0.72,
                height: 830,
                windowHeight: 830,
              },
            });
            let end = performance.now();
            console.log("jsPDF html took:", end - start);
          } else {
            let start = performance.now();
            // const canvas = await html2canvas(page, { scale: 1.5, }); // Higher scale for better quality
            const canvas = await toCanvas(page, {
              cacheBust: true,
              backgroundColor: "white",
              canvasWidth: 560,
              canvasHeight: 800,
              pixelRatio: 3,
              style: {
                padding: "0",
                margin: "0",
              },
            });
            let end = performance.now();
            console.log("html to image took:", end - start);

            const imgData = canvas.toDataURL("image/jpeg", 0.6); // Using JPEG format with quality set to 0.8

            const imgWidth = 595.28; // A4 width in points
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (i > 0) pdf.addPage();
            pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
          }
        }

        const filename = rep ? `Quote_Representative_${quote.number}.pdf` : `Quote_${quote.number}.pdf`;
        if (download) {
          pdf.save(filename);
        }

        const generatedPdf = pdf.output("blob");
        createAModelDocument({
          model: "quote",
          id: getId(quote),
          file: generatedPdf,
          description: `${new Date().toJSON().slice(0, 19)} - ${quote.number}`,
          name: filename,
          fileName: filename,
        });
        setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [QuoteComponents, quote, quoteId]
  );

  useEffect(() => {
    let t = setTimeout(() => {
      if (!uploaded && quote && pageCalculationFinished) {
        console.log("pdf generation started.");

        handleDownload({ download: true, rep: false }).then(() => {
          setUploaded(true);

          setRepQuote(true);
          setTimeout(() => {
            handleDownload({ download: true, rep: true }).then(() => {
              onDone();
              onClose();
            });
          }, 500);
        });
      }
    }, 3000);

    return () => clearTimeout(t);
  }, [handleDownload, onClose, onDone, pageCalculationFinished, quote, uploaded]);

  if (isLoading || isLinesValidating) {
    return (
      <MyDialog title="Quote PDF" open={open} onClose={onClose}>
        <div style={{ width: "21cm", height: "29cm", margin: "auto" }}>
          <LinearProgress />
        </div>
      </MyDialog>
    );
  }
  const transformList = (inputList: number[]): number[] => {
    return inputList.reduce((accumulator: number[], current: number, index: number) => {
      // If it's the first element, just add it as is
      if (index === 0) {
        accumulator.push(current);
      } else {
        // Calculate the cumulative sum
        const newValue = accumulator[index - 1] + current;
        accumulator.push(newValue);
      }
      return accumulator;
    }, []);
  };
  function CalcPages(maxHeight: number, totalItems: ILineItem[]) {
    const pages: ILineItem[][] = [];
    let currentPage: ILineItem[] = [];
    let cumulativeHeight = 0;

    // Define the height for the first page
    const firstPageMaxHeight = maxHeight * (60 / 100);
    let isFirstPage = true; // Flag to check if it's the first page
    var count = 0;
    for (const item of totalItems) {
      // const itemHeight = ((item.description ? countTrimmedLines(item.description) : 1) * 16) + 40;
      const itemHeight = heights[count] + 40 + 16;
      count += 1;
      // Check if the cumulative height exceeds the current page's max height
      if (
        (isFirstPage && cumulativeHeight + itemHeight > firstPageMaxHeight) ||
        (!isFirstPage && cumulativeHeight + itemHeight > maxHeight)
      ) {
        // Push the current page to pages
        pages.push(currentPage);
        currentPage = []; // Reset current page
        cumulativeHeight = 0; // Reset cumulative height

        // Set the flag to false after the first page
        if (isFirstPage) {
          isFirstPage = false; // Switch to normal behavior for subsequent pages
        }
      }

      currentPage.push(item); // Add the item to the current page
      cumulativeHeight += itemHeight; // Update the cumulative height
    }

    // If there are items in currentPage, push them to pages
    if (currentPage.length > 0) {
      pages.push(currentPage);
    }
    // console.log('==============pages==============');
    // console.log(pages);
    // console.log('====================================');
    const lengths: number[] = transformList(pages.map((innerList) => innerList.length));

    setSectionNumber(lengths);
    // Update the state with the calculated pages
    setTotalPagesContent(pages);
    setPageCalculationFinished(true);
  }

  const Quote = QuoteComponents.map((item, index) => {
    const pageNumber = index;

    return {
      component: React.cloneElement(item.component, { pageNumber }), // Pass pageNumber as a prop
      key: item.key, // Keep the original key for identification
    };
  });

  function measureDiv(item: ILineItem): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      // Create a temporary container
      const tempDiv = document.createElement("div");

      // Apply styles for offscreen positioning and sizing
      tempDiv.style.position = "absolute";
      tempDiv.style.top = "-9999px";
      tempDiv.style.left = "-9999px";
      tempDiv.style.width = "672.938px";
      tempDiv.style.height = "auto";

      // Append the tempDiv to the document body
      document.body.appendChild(tempDiv);

      // Render the ItemTable component into tempDiv
      ReactDOM.render(
        <div className="w-full h-auto">
          <ItemTable
            no={item.itemNo || item.ItemId?.no || item.ItemId?.name || ""}
            groupLine={item.group && item.sort ? `[${item.group}]-${item.sort}` : ""}
            description={item.description}
            qty={item.qty}
            unitPrice={item.price}
            totalPrice={item.total}
          />
        </div>,
        tempDiv
      );

      // Use requestAnimationFrame to measure after rendering is complete
      requestAnimationFrame(() => {
        const dimensions = {
          width: tempDiv.offsetWidth,
          height: tempDiv.offsetHeight,
        };

        // Clean up
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);

        // Resolve with the measured dimensions
        resolve(dimensions);
      });
    });
  }

  return (
    <MyDialog
      title="Quote PDF"
      open={open}
      onClose={() => {
        if (loading) {
          Swal.fire({
            title: "Generating PDFs",
            text: "Please wait while we generate the PDFs for you, If you are sure you want to quit you can generate PDFs from document tab.",
            icon: "info",
            showCancelButton: true,
          }).then((r) => {
            if (r.isConfirmed) {
              onClose();
            }
          });
        } else {
          onClose();
        }
      }}
      maxWidth="lg"
      fullWidth
    >
      <Button variant="contained" disabled={loading} onClick={() => handleDownload({ download: true, rep: false })}>
        Download
      </Button>
      {loading ? <LinearProgress /> : <></>}
      <div className="flex-1 overflow-y-auto bg-gray-100" ref={QuotePagesRef}>
        {Quote.map(({ component, key }) => (
          <div className="w-[20.7cm] min-h-[29cm] mx-auto my-4 bg-white" key={key}>
            {component}
          </div>
        ))}
      </div>
    </MyDialog>
  );
}
