import React, { useEffect, useState } from "react";
import { Avatar, Box, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import ProfileMenu from "./Menu";
import { useSelector } from "react-redux";
import { selectSession } from "features/Session/sessionsSlice";
import profile from "assets/icons/header/profile.svg";
import useSWR from "swr";
import { IEmployee } from "api/employee";
import { photoType } from "api/photo";
import { getId } from "logic/utils";
import { host } from "host";

export default function NotificationButton() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const session = useSelector(selectSession);
  const history = useHistory();
  const { data: me } = useSWR<IEmployee>("/employee/me");
  const { data: photo, mutate } = useSWR<photoType[]>(`/photo/employee/${getId(me)}`);
  useEffect(() => {
    mutate();
  });
  return (
    <Box display={"flex"} alignItems="center" mr={3} style={{ cursor: "pointer" }}>
      <IconButton
        size="small"
        title="User"
        color="inherit"
        onClick={() => history.push("/employee/profileNotification")}
      >
        {photo?.[0]?.path ? (
          <Avatar src={host + photo?.[0]?.path} />
        ) : (
          <img alt="user" src={profile} style={{ width: 26, height: 26 }} />
        )}
      </IconButton>
      <span style={{ fontSize: 12 }} onClick={() => history.push("/employee/profileNotification")}>
        {session?.session?.username}
      </span>
      <ProfileMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </Box>
  );
}
