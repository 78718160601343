import React, { useMemo, useState } from "react";
import { Box, Button, ListItem, useMediaQuery } from "@mui/material";
import { BasePaper } from "app/Paper";
import DataGridAction from "common/DataGridAction";
import DataTable from "common/DataTable";
import AddTopicModal from "./AddTopicModal";

const Topic = () => {
  const phone = useMediaQuery("(max-width:600px)");
  const [addTopicModal, setAddTopicModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState<any>();

  const topicColumns = useMemo(() => {
    let res: any[] = [
      {
        field: "action",
        type: "action",
        initialWidth: 30,
        cellRenderer({ data }: any) {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <DataGridAction
                icon="edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAddTopicModal(true);
                  setSelectedTopic(data);
                }}
              />
            </Box>
          );
        },
      },
      { field: "createdAt", headerName: "Date", defaultOperator: "startsWith", type: "date" },
      { field: "name", headerName: "Topic Name" },
      {
        field: "clusterId",
        headerName: "Cluster Id",
        flex: 1,
        minWidth: 300,
        cellRenderer({ data }: any) {
          return (
            <Box display="flex" gap={1} alignItems="center">
              {data?.ClusterId?.map((c:{clusterValue:string}) => c.clusterValue).join(" , ")}
            </Box>
          );
        },
      },
    ];
    return res;
  }, []);

  return (
    <>
      {addTopicModal && (
        <AddTopicModal
          initialTopic={selectedTopic}
          setRefresh={setRefresh}
          open={addTopicModal}
          onClose={() => setAddTopicModal(false)}
        />
      )}
      <BasePaper style={{ height: "100%" }} sx={{ width: "100%" }}>
        <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={() => setAddTopicModal(true)}>
            Add Topic
          </Button>
        </ListItem>

        <Box sx={{ width: "100%" }}>
          <DataTable
            height={"calc(100vh - 220px)"}
            rowHeight={28}
            key={refresh}
            columns={topicColumns}
            url={`/topic`}
          />
        </Box>
      </BasePaper>
    </>
  );
};

export default Topic;
