import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import SectionHeader2 from "./SectionHeader2";
export default function QuotePage18({ data, pageNumber }) {
  return (
    <div className="relative flex flex-col items-center h-full w-[98%] mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div style={{ position: "absolute", left: 0, top: "25cm", width: "20.5cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    text1:
      "This Full Service Plan is to be purchased while the equipment is still under current warranty coverage, or after approval by DSPM and a Certification Inspection by DSPM .",

    text2:
      "This Full Service Plan covers all emergency calls to service the unit and one (1) Preventive Maintenance call per year. Additional Preventive Maintenance calls may be purchased and added to the plan.",

    text3:
      "This plan covers all replacement parts and labor including travel time and expenses for all emergency calls and one (1) Preventive Maintenance call to service the unit. All service calls will be made seven (7) days a week excluding DSPM's designated holidays.",

    text4:
      "If the Customer requests remedial maintenance or preventive maintenance outside of the contracted coverage, DSPM will provide this service. This service will be charged to the Customer at DSPM's standard hourly rates in effect at the time of the service, and will be subject to an available field engineer.",
    text5:
      "DSPM will provide all replacement parts for pans that are found defective during emergency service calls. If replaced parts are used from any Customer-owned spare parts kit, DSPM will replace them to the Customer-owned spare parts kit with no charges to the Customer.",

    text6:
      "DSPM will include installation of any changes for safety reasons and at DSPM option, install any factory enhancements and upgrades, and reliability changes or improvements during the emergency service call. Uninterruptible Power Systems (UPS) and Emergency Lighting Inverter batteries are NOT warranted under this plan unless specified. DSPM will provide the maintenance and testing for the batteries at DSPM 's the hourly rates in effect for DSPM. DSPM, unless specified otherwise in contract, will NOT provide Battery replacements but will assist the customer in the replacement of the batteries through the battery manufacturer's warranty.",
  };
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <SectionHeader2 title={"SF365-1 - Full Service Contract 24 hrs/day 7 days/week Including Holidays"} />
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-row">
            <span className="text-[16px] font-light">•</span>
            <span className="text-[16px] font-light ml-1 text-justify">{texts.text1}</span>
          </div>
          <div className="flex flex-row">
            <span className="text-[16px] font-light">•</span>
            <span className="text-[16px] font-light ml-1 text-justify">{texts.text2}</span>
          </div>
          <div className="flex flex-row">
            <span className="text-[16px] font-light">•</span>
            <span className="text-[16px] font-light ml-1 text-justify">{texts.text3}</span>
          </div>
          <div className="flex flex-row">
            <span className="text-[16px] font-light">•</span>
            <span className="text-[16px] font-light ml-1 text-justify">{texts.text4}</span>
          </div>
          <div className="flex flex-row">
            <span className="text-[16px] font-light">•</span>
            <span className="text-[16px] font-light ml-1 text-justify">{texts.text5}</span>
          </div>
          <div className="flex flex-row">
            <span className="text-[16px] font-light">•</span>
            <span className="text-[16px] font-light ml-1 text-justify">{texts.text6}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
