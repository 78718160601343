import { delete_, patch, post } from "api";

export type photoType = {
  id: string;
  path: string;
  createdAt: number;
  updatedAt: number;
  __v: number;
  name?: string;
  description?: string;
};

export const addPhoto = ({
  description,
  name,
  model,
  id,
  photo,
  engineering,
  url,
}: {
  name?: string;
  description?: string;
  model: string;
  id: string;
  photo?: File | File[] | null;
  engineering?: boolean;
  url?: string;
}) => {
  if (!photo) {
    return;
  }
  if (Array.isArray(photo)) {
    return Promise.all(
      photo.map((f) => {
        const formData = new FormData();
        formData.append("photo", f);

        url && formData.append("url", url);
        name && formData.append("name", name);
        description && formData.append("description", description);

        return post<photoType[]>(`/photo/${model}/${id}`, formData);
      })
    );
  } else {
    const formData = new FormData();
    formData.append("photo", photo);
    url && formData.append("url", url);
    name && formData.append("name", name);
    description && formData.append("description", description);

    return post<photoType[]>(`/photo/${model}/${id}`, formData);
  }
};
export const updatePhoto = ({
  description,
  name,
  url,
  id,
  photo,
}: {
  name?: string;
  description?: string;
  url?: string;
  id: string;
  photo?: File | File[] | null;
}) => {
  if (Array.isArray(photo)) {
    return Promise.all(
      photo.map((f) => {
        const formData = new FormData();
        formData.append("photo", f);
        url && formData.append("url", url);
        name && formData.append("name", name);
        description && formData.append("description", description);

        return patch(`/photo/${id}`, formData);
      })
    );
  } else {
    const formData = new FormData();
    photo && formData.append("photo", photo);
    url && formData.append("url", url);
    name && formData.append("name", name);
    description && formData.append("description", description);

    return patch(`/photo/${id}`, formData);
  }
};

export const deletePhoto = (id: string) => {
  return delete_(`/photo/${id}`);
};

export const getPhotos = (
  data: {
    id: string;
    type: string;
  }[]
) => {
  return post<photoType[]>(`/photo/models`, data);
};
