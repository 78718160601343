export const departments = ["Production", "Engineering", "Field Service"];

export const panels: { name: string; path: string }[] = [
  {
    name: "home",
    path: "/panel/home",
  },
  {
    name: "sales, dashboard",
    path: "/panel/sales/dashboard",
  },
  {
    name: "sales, quotes",
    path: "/panel/sales/quote/quotes",
  },
  {
    name: "sales, sales orders, so",
    path: "/panel/sales/salesOrders",
  },
  {
    name: "sales, clients",
    path: "/panel/sales/client/clients",
  },
  {
    name: "sales, reps",
    path: "/panel/sales/reps",
  },
  {
    name: "sales, devices, units",
    path: "/panel/sales/device/devices",
  },
  {
    name: "sales, options, units",
    path: "/panel/sales/option/options",
  },
  {
    name: "sales, tickets",
    path: "/panel/sales/salesTickets",
  },

  {
    name: "purchasing, dashboard, required po, fulfillment",
    path: "/panel/purchase/dashboard",
  },
  {
    name: "purchasing, purchase orders, po",
    path: "/panel/purchase/purchaseOrder",
  },
  {
    name: "purchasing, vendors",
    path: "/panel/purchase/vendor",
  },
  {
    name: "purchasing, tickets",
    path: "/panel/purchase/purchaseTicket",
  },

  {
    name: "inventory, dashboard",
    path: "/panel/inventory/dashboard",
  },
  {
    name: "inventory, items",
    path: "/panel/inventory/items",
  },
  {
    name: "inventory, receiving",
    path: "/panel/inventory/receiving",
  },
  {
    name: "inventory, partflow, issues",
    path: "/panel/inventory/PartFlow",
  },
  {
    name: "inventory, settings, UOMs, UOM groups",
    path: "/panel/inventory/setting",
  },

  {
    name: "engineering, dashboard, approvals,",
    path: "/panel/engineering/dashboard",
  },
  {
    name: "engineering, devices, units",
    path: "/panel/engineering/device/devices",
  },
  {
    name: "engineering, options",
    path: "/panel/engineering/option/options",
  },
  {
    name: "engineering, frus",
    path: "/panel/engineering/fru/frus",
  },
  {
    name: "engineering, product management, BOM, clusters, levels",
    path: "/panel/engineering/product-management",
  },
  {
    name: "engineering, product management, BOM, clusters, levels, matrix",
    path: "/panel/engineering/product-management",
  },
  {
    name: "engineering, qc tickets",
    path: "/panel/engineering/tickets/list",
  },
  {
    name: "engineering, projects",
    path: "/panel/engineering/projects",
  },
  // {
  //   name: "engineering, settings, process, process clusters",
  //   path: "/panel/engineering/settings",
  // },

  {
    name: "production, dashboard, units",
    path: "/panel/production/dashboard/units",
  },
  {
    name: "production, tasks list",
    path: "/panel/production/tasks/task",
  },
  {
    name: "production, staff",
    path: "/panel/production/staff",
  },

  {
    name: "shipping, dashboard",
    path: "/panel/shipping/dashboard",
  },
  {
    name: "shipping, units",
    path: "/panel/shipping/units",
  },

  {
    name: "accounting, dashboard, commissions, gross margin",
    path: "/panel/accounting/dashboard/dashboards",
  },
  {
    name: "accounting, invoices list",
    path: "/panel/accounting/invoice",
  },
  {
    name: "accounting, sales orders, so",
    path: "/panel/accounting/salesOrders",
  },
  {
    name: "accounting, tickets",
    path: "/panel/accounting/tickets/list",
  },

  { name: "field service, fs, dashboard", path: "/panel/fieldservice/dashboard" },
  { name: "field service, fs, quote", path: "/panel/fieldservice/quote/quotes" },
  { name: "field service, fs, salesOrders", path: "/panel/fieldservice/salesOrders" },
  { name: "field service, fs, tickets", path: "/panel/fieldservice/tickets" },
  { name: "field service, fs, fru", path: "/panel/fieldservice/fru/frus" },
  { name: "field service, fs, services", path: "/panel/fieldservice/services" },
  { name: "field service, fs, units", path: "/panel/fieldservice/units" },
  { name: "field service, fs, vendor techs", path: "/panel/fieldservice/vendor" },
  { name: "field service, fs, client", path: "/panel/fieldservice/client/clients" },
  { name: "field service, fs, reps", path: "/panel/fieldservice/reps" },
  { name: "field service, fs, rma", path: "/panel/fieldservice/rma" },
  { name: "field service, fs, tasks, calendar", path: "/panel/fieldservice/tasks" },
  { name: "field service, fs, up", path: "/panel/fieldservice/up" },
  { name: "field service, fs, purchase po", path: "/panel/fieldservice/purchase-po" },
  { name: "field service, fs, settings, action plans, slas", path: "/panel/fieldservice/settings/sla" },

  { name: "settings, roles, employees", path: "/panel/setting/roles" },
  { name: "settings, Category Type", path: "/panel/setting/CategoryType" },
  { name: "settings, Cluster Level", path: "/panel/setting/clusterLevel" },
  { name: "settings, department", path: "/panel/setting/department" },
  { name: "settings, shippments, carriers", path: "/panel/setting/shippment" },
  { name: "settings, actionLog", path: "/panel/setting/actionLog" },
  { name: "settings, attendance", path: "/panel/setting/attendance/attendances" },
];
