import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import { IInvoice } from "api/invoice";
import { createAModelDocument } from "api/document";
import { arrayBufferToBase64, getId } from "logic/utils";
import { formatTimestampToDate } from "logic/date";
import { addElementToPdf } from "logic/pdf";
import Toast from "app/Toast";
import MyDialog from "app/Dialog";

import InvoiceOld from "PDFTemplates/InvoiceOld";
import { useHistory } from "react-router-dom";

export default function InvoicePdfModal({
  open,
  invoice,
  onClose,
  onDone,
}: {
  invoice: IInvoice;
  open: boolean;
  onClose: () => void;
  onDone?: () => void;
}) {
  const history = useHistory();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [uploading, setUploading] = useState(true);

  const handleExport = useCallback(
    async ({ upload, print }: { upload?: boolean; print?: boolean }) => {
      try {
        setUploading(true);
        if (headerRef.current) {
          let doc = new jsPDF();

          doc = await addElementToPdf({
            pdf: doc,
            element: headerRef.current,
            x: 5,
            y: 5,
          });

          doc.save(
            invoice?.number
              ? `Invoice-${invoice?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`
              : `Invoice-${formatTimestampToDate(Number(new Date()))}.pdf`
          );
          if (upload) {
            const generatedPdf = doc.output("blob");
            await createAModelDocument({
              model: "invoice",
              id: getId(invoice),
              file: generatedPdf,
              description: `${new Date().toJSON().slice(0, 19)} - ${invoice.number}`,
              name: `invoice_${invoice.number}.pdf`,
              fileName: `invoice_${invoice.number}.pdf`,
            });
            Toast("Document saved", "success");
          }
          if (print) {
            const buffer = doc.output("blob");
            const result = URL.createObjectURL(buffer);
            history.push(`/print?pdf=${result}`);
          }
          // onClose();
          // onDone && onDone();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
      }
    },
    [history, invoice]
  );

  useEffect(() => {
    if (!open) {
      setUploading(true);
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }
    const t = setTimeout(() => {
      // handleExport({ upload: true });
      setUploading(false);
    }, 2000);

    return () => clearTimeout(t);
  }, [handleExport, open]);

  return (
    <MyDialog title="Invoice Pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <Box width="21cm" m={2} mx="auto">
        <Button variant="contained" disabled={uploading} onClick={() => handleExport({ upload: true })} sx={{ mb: 1 }}>
          Save
        </Button>
        <Button
          variant="contained"
          disabled={uploading}
          onClick={() => handleExport({ upload: false, print: true })}
          sx={{ mb: 1, mx: 2 }}
        >
          Print
        </Button>
        <InvoiceOld invoice={invoice} ref={headerRef} />
      </Box>
    </MyDialog>
  );
}
