import React, { forwardRef } from "react";

import email from "assets/pdf/quote/email.svg";
import footer from "assets/pdf/quote/footer_circle.png";
import numberBg from "assets/pdf/quote/noBg.png";

const PageFooter = forwardRef<HTMLDivElement, { number?: string; phone?: string; email?: string }>(
  ({ number, phone, email }, ref) => {
    return (
      <div
        style={{
          width: "21cm",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "32px",
            backgroundColor: "#1c2532",
            position: "relative",
            alignItems: "center",
            zIndex: 6,
            bottom: 50,
          }}
        >
          <img alt="" src={footer} style={{ position: "absolute", bottom: -25, left: 0, objectFit: "contain" }} />
          <div
            ref={ref}
            style={{
              width: "75%",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 40,
              paddingLeft: 80,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <img alt="phone" src={phone} />
              <span style={{ fontSize: 12, marginLeft: 10 }}>{phone}</span>
            </div>
            <p style={{ color: "#FFFFFF" }}>|</p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <img alt="email" src={email} />
              <span style={{ fontSize: 12, marginLeft: 10 }}>{email || "Quote@dspmanufacturing.com"}</span>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", position: "absolute", bottom: -15, right: 0 }}>
            <img
              alt=""
              src={numberBg}
              width={200}
              height={45}
              style={{ position: "absolute", bottom: 10, right: 0, objectFit: "contain" }}
            />
            <div
              style={{ display: "flex", justifyContent: "space-around", position: "relative", zIndex: 6, bottom: 10 }}
            >
              <p
                style={{
                  fontSize: "12px",
                  color: "#1C2532",
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                No:
              </p>
              <p
                style={{
                  fontSize: "12px",
                  color: "#1C2532",
                  fontWeight: "bold",
                  marginRight: "20px",
                  marginTop: "10px",
                }}
              >
                {number}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PageFooter;
