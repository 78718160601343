// import Logo from '../../../assets/svg/logo.svg?react';
// import HeaderLogo from "./header2.svg?react";
import pageHeader from "assets/pdf/pdf_page_header.png";

function Header() {
  return (
    <img
      src={pageHeader}
      alt=""
      style={{
        width: "20.5cm",
        height: "auto",
        objectFit: "contain",
        margin: 8,
      }}
    />
  );
}

export default Header;
