import React, { useState } from "react";
import { Tabs as MuiTabs, Tab as MuiTab, Box } from "@mui/material";
import { ReactNode } from "react";
import { LockButton } from "common/Lock";

export default function Tabs({
  width,
  children,
  addForm,
  number,
  disabled,
  mt,
  sx,
  modal,
  unlockPasswordTab,
  onSubmit,
}: {
  width?: number | string;
  children: ReactNode[] | ReactNode;
  addForm?: boolean;
  number?: number[];
  disabled?: boolean;
  mt?: any;
  sx?: any;
  modal?: boolean;
  unlockPasswordTab?: number;
  onSubmit?: () => void;
}) {
  const [tab, setTab] = useState(0);

  const tabs = React.Children.toArray(children)
    .filter(Boolean)
    .map((c: any) => ({
      label: c.props?.label,
      disabled: c.props?.disabled,
      children: c.props?.children,
    }));

  return (
    <>
      <Box display="flex" alignItems="center">
        {!addForm && (
          <LockButton
            disabled={disabled ? Boolean(number?.find((i) => i === tab)) : false}
            needUnlockPassword={tab === unlockPasswordTab}
            onSubmit={onSubmit}
          />
        )}
        <MuiTabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          variant="scrollable"
          sx={{
            width,
            ...sx,
            "& .MuiTabs-indicator": {
              mb: modal ? 1 : 0,
            },
          }}
        >
          {tabs?.map((t: any, i: any) => (
            <MuiTab sx={{}} key={i} label={t.label} disabled={t.disabled} />
          ))}
        </MuiTabs>
      </Box>
      <Box mt={mt ? 0 : 1} key={tab}>
        {tabs?.[tab]?.children}
      </Box>
    </>
  );
}
