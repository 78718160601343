import React, { forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import { sortSteps } from "logic/Instruction/utils";
import { instructionType } from "api/instruction";
import Photos from "common/Upload/Photos";
import { getId } from "logic/utils";

const ProcedureSteps = forwardRef<
  HTMLDivElement,
  {
    steps: instructionType[] | any;
  }
>(({ steps }, ref) => {
  return (
    <div ref={ref} style={{ width: "20cm", margin: "auto" }}>
      {sortSteps(steps)?.map((step, i) => (
        <Box my="20px" position={"relative"} mr={3}>
          <Box display={"flex"} alignItems={"center"}>
            <Box width="100px" display={"flex"} sx={{ background: "#1763AA" }} p={1} justifyContent={"flex-end"}>
              <Typography sx={{ color: "#FFF" }} fontWeight={"bold"}>
                {step?.step}
              </Typography>
            </Box>
            <Typography color="#1763AA" fontWeight={"bold"} fontSize={"18px"} mx="20px">
              {step?.title}
            </Typography>
            <Typography color="#7D7E7E" fontWeight={"bold"} fontSize={"16px"}>
              {step.subTitle}
            </Typography>
          </Box>
          <Typography sx={{ background: "#F4F4F4" }} p={1} mt="12px" mr={2}>
            Content:
          </Typography>
          <Typography maxWidth="17cm" textAlign="justify" my={1} ml={1}>
            {step?.content}
          </Typography>
          <Box width="90%" display="flex" gap={1}>
            <Box flexGrow={1}>
              {step.args && step.args.length > 0 && (
                <Typography sx={{ background: "#F4F4F4" }} p={1}>
                  Arguments:
                </Typography>
              )}
              <Typography mt={1} ml={3}>
                {step.args?.length > 0 &&
                  step.args.map((a: any, i: number) => (
                    <div key={i}>
                      <span>{a.key}=</span>
                      <span>{a.value}</span>
                      <span>{a.unit}</span>
                    </div>
                  ))}
              </Typography>
            </Box>
            <Box width="30%" display="flex" flexDirection="column" gap={2}>
              <Photos url={`/photo/step/${getId(step)}`} disabled imageBaseUrl="https://erp.phocuss.com" />
              {step.photo && (
                <img
                  src={URL.createObjectURL(step.photo)}
                  alt=""
                  style={{ width: "100%", height: "100px", objectFit: "contain" }}
                />
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </div>
  );
});

export default ProcedureSteps;
