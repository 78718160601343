import React, { useRef } from "react";
import queryString from "query-string";
import PDFPreview from "components/PDFPreview";

export default function PrintPage() {
  const containerRef = useRef<HTMLDivElement>(null);
  const queries = queryString.parse(window.location.search);
  const pdfFile = queries.pdf as string;

  if (!pdfFile) {
    return <>No pdf found.</>;
  }

  return (
    <div ref={containerRef} className="print-only" style={{ zoom: "150%" }}>
      <PDFPreview
        clear
        pdf={pdfFile}
        onLoad={() => {
          console.log("print");

          setTimeout(() => {
            window.print();
          }, 1500);
        }}
      />
    </div>
  );
}
