import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import SectionHeader2 from "./SectionHeader2";
export default function QuotePage20({ data, pageNumber }) {
  return (
    <div className="relative flex flex-col items-center h-full w-[98%] mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div style={{ position: "absolute", left: 0, top: "25cm", width: "20.5cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const tableData = [
    {
      qty: "2 to 5",
      unitPrice: "1.00",
    },
    {
      qty: "6 to 10",
      unitPrice: "0.90",
    },
    {
      qty: "11 to 15",
      unitPrice: "0.82",
    },
    {
      qty: "16 to 20",
      unitPrice: "0.75",
    },
    {
      qty: "21 to 25",
      unitPrice: "0.70",
    },
    {
      qty: "26 to 30",
      unitPrice: "0.65",
    },
    {
      qty: "31 to 35",
      unitPrice: "0.60",
    },
  ];
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <SectionHeader2
            title={
              "Multiple Unit discount for more than one unit at a single location only, and startups are all at the same time."
            }
          />
        </div>
        <div className="mt-[4%]">
          <table className="w-1/3 ">
            <thead>
              <tr>
                <th className="text-left">
                  <div className="flex flex-col  p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">Qty</span>
                  </div>
                </th>
                <th className="">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">Unit Price</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td className="">
                    <div className="flex flex-col p-2">
                      <span className="text-[11px] text font-light">{row.qty}</span>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="flex flex-col items-center align-middle">
                      <span className="text-[11px] text font-light">{row.unitPrice}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-[4%]">
          <SectionHeader2 title={"CUSTOMER SERVICE RATES"} />
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <div>
              <span className="font-bold text-[14px]">Labor Rates:</span>
            </div>
            <div className="flex flex-row mt-[2%]">
              <span className="font-bold text-[14px]">Repair Charges</span>
              <span className="font-light text-[14px] ml-1">(Customer Site Time)</span>
            </div>
            <div className="flex flex-row mt-[2%] justify-start items-end">
              <div className="flex flex-col">
                <div className="mt-2">
                  <span className="text-[14px] font-light">8 to 5 Monday thru Friday excluding holidays</span>
                </div>
                <div className="mt-2">
                  <span className="text-[14px] font-light">All other times excluding holidays</span>
                </div>
                <div className="mt-2">
                  <span className="text-[14px] font-light">Holidays</span>
                </div>
              </div>
              <div className="flex flex-col ml-[4%]">
                <div className="mt-2">
                  <span className="text-[14px] font-bold">$140.00</span>
                </div>
                <div className="mt-2">
                  <span className="text-[14px] font-bold">$210,00 </span>
                </div>
                <div className="mt-2">
                  <span className="text-[14px] font-bold">$280.00 </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-[4%]">
              <span className="font-bold text-[14px]">Travel Charges</span>
              <span className="font-light text-[14px] ml-1">(Round Trip)</span>
            </div>
            <div className="flex flex-row mt-[2%] justify-start items-end">
              <div className="flex flex-col">
                <div className="mt-2">
                  <span className="text-[14px] font-light">All travel time excluding holidays</span>
                </div>
                <div className="mt-2">
                  <span className="text-[14px] font-light">Travel time for holidays</span>
                </div>
              </div>
              <div className="flex flex-col ml-[16%]">
                <div className="mt-2">
                  <span className="text-[14px] font-bold">$140.00</span>
                </div>
                <div className="mt-2">
                  <span className="text-[14px] font-bold">$210,00 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[4%]">
          <SectionHeader2 title={"Expenses:"} />
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-row">
            <span className="text-[16px] font-light">•</span>
            <span className="text-[16px] font-light ml-1">
              Travel expenses for all services NOT covered under an EXTENDED WARRANTY PLAN or a SERVICE CONTRACT will be
              billed at actual cost.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
