import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, LinearProgress, Typography } from "@mui/material";
import useSWR from "swr";
import jsPDF from "jspdf";
import QRCode from "app/QRCode";
import MyDialog from "app/Dialog";

import { ISO } from "api/so";
import { ILineItem } from "api/lineItem";
import { createAModelDocument } from "api/document";

import { getId, sleep } from "logic/utils";
import { QR } from "logic/QR";
import SOPage1 from "PDFTemplates/New/SO/SOpage1";
import SOpagex from "PDFTemplates/New/SO/SOpagex";
import ReactDOM from "react-dom";
import ItemTable from "PDFTemplates/New/Quote/ItemTable";
import SectionHeader from "PDFTemplates/New/Quote/SectionHeader";
import SOpage2 from "PDFTemplates/New/SO/SOpage2";
import SOpage3 from "PDFTemplates/New/SO/SOpage3";
import Terms from "PDFTemplates/New/SO/Terms";
import { format } from "date-fns";

export default function SOPdfDialog({
  soId,
  open,
  onClose,
  onDone,
}: {
  soId: string;
  open: boolean;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data } = useSWR<ISO>(`/so/${soId}`);
  const { data: lines } = useSWR(`/lineitem?SOId=${soId}`);
  const createdSo = data;

  const totalFreight =
    lines?.result
      ?.filter((i: any) => i?.freight || i.itemNo?.includes("FREIGHT"))
      ?.reduce((p: any, c: any) => p + (c?.total || c?.price || 0), 0) || 0;

  const [loading, setLoading] = useState(false);

  const SOpagesRef = useRef<HTMLDivElement | null>(null);
  const [totalPagesContent, setTotalPagesContent] = useState<ILineItem[][]>([]);
  const MAX_CONTENT_HEIGHT = 720; // Adjust this based on the A4 page content height
  const [heights, setHeights] = useState<number[]>([]);

  const [commission, setCommission] = useState(false);
  const [title, setTitle] = useState("Order Acknowledgment");
  const [uploaded, setUploaded] = useState(false);

  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState<string>();

  const getQrUrl = () => {
    const qr = new QR("so");
    return qr.generateSoUrl({ soId });
  };

  const SOComponents = [
    {
      component: (
        <SOPage1
          data={data}
          pageNumber={1}
          QrCode={<QRCode width={69} height={69} value={getQrUrl()} />}
          title={title}
          footerTitle="Order"
        />
      ),
      key: "page1",
    },
    // Dynamically add SOPageX components
    ...Array.from({ length: totalPagesContent.length }, (_, index) => ({
      component: (
        <SOpagex
          data={data}
          page={index}
          lastPage={totalPagesContent.length - 1}
          pageNumber={index + 3} // Start from page 3
          Items={totalPagesContent[index]}
          totalFreight={totalFreight}
          commission={commission}
          title="Order"
        />
      ),
      key: `pagex${index + 1}`, // Adjust key accordingly
    })),
    {
      component: <Terms pageNumber={String(totalPagesContent.length + 1)} number={data?.number || ""} title="Order" />,
      key: "page3",
    },
    {
      component: (
        <SOpage2 pageNumber={String(totalPagesContent.length + 1)} number={data?.number || ""} title="Order" />
      ),
      key: "page4",
    },
    {
      component: (
        <SOpage3 pageNumber={String(totalPagesContent.length + 2)} number={data?.number || ""} title="Order" />
      ),
      key: "page5",
    },
  ];

  const SO = SOComponents.map((item, index) => {
    const pageNumber = index + 1;
    return {
      component: React.cloneElement(item.component, { pageNumber }), // Pass pageNumber as a prop
      key: item.key, // Keep the original key for identification
    };
  });

  const handleDownload = useCallback(
    async ({ download, title }: { download: boolean; title: string }) => {
      setLoading(true);
      try {
        if (!data || !lines || !SOpagesRef.current) {
          return;
        }

        const pdf = new jsPDF("p", "pt", "a4");
        const pages = SOpagesRef.current.children;

        for (let i = 0; i < pages.length; i++) {
          const page = pages[i] as HTMLElement;

          if (i > 0) pdf.addPage();
          await pdf.html(page, {
            x: 10,
            y: i * 830 + i * 10,
            width: 560,
            html2canvas: {
              scale: 0.72,
              height: 830,
              windowHeight: 830,
            },
          });
        }

        const fileName = `SO_${title}_${data.number}.pdf`;
        if (download) {
          pdf.save(fileName);
        }

        const generatedPdf = pdf.output("blob");
        return generatedPdf;
        // await createAModelDocument({
        //   model: "so",
        //   id: getId(data),
        //   file: generatedPdf,
        //   description: `${new Date().toJSON().slice(0, 19)} - ${data.number}`,
        //   name: fileName,
        //   fileName: fileName,
        // });
        // onDone && onDone();
        // setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [data, lines]
  );

  useEffect(() => {
    if (lines?.result) {
      CalcPages(MAX_CONTENT_HEIGHT, lines.result);
    }
  }, [heights, lines]);

  useEffect(() => {
    const fetchDataAndCalculate = async () => {
      if (lines?.result) {
        // Run your async function here
        var heights = [];
        for (const item of lines.result) {
          const size = await measureDiv(item);
          heights.push(size.height);
        }
        setHeights(heights);
      }
    };
    fetchDataAndCalculate(); // Call the async function
  }, [lines]);

  useEffect(() => {
    const generate = async () => {
      if (!open || !data || !lines || uploaded) {
        return;
      }

      setProgress(0);
      setProgressText("Creating Order PDF");
      setTitle("Order Acknowledgment");
      setCommission(true);
      await sleep(1000);
      const orderPdf = await handleDownload({ download: true, title: "order" });

      setProgress(25);
      setProgressText("Creating Rep PDF");
      setTitle("Rep Acknowledgment");
      setCommission(true);
      await sleep(1000);
      const repPdf = await handleDownload({ download: true, title: "rep" });

      setProgress(50);
      setProgressText("Creating Customer PDF");
      setTitle("Customer Acknowledgment");
      setCommission(false);
      await sleep(1000);
      const customerPdf = await handleDownload({ download: true, title: "customer" });

      let cnt = 0;
      const pdfs = [orderPdf, repPdf, customerPdf];
      const promises: Promise<any>[] = [];
      for (const title of ["order", "rep", "customer"]) {
        const fileName = `SO_${title}_${data.number}.pdf`;

        promises.push(
          createAModelDocument({
            model: "so",
            id: getId(data),
            file: pdfs[cnt],
            description: `${format(new Date(), "yyyy-MM-dd")}-${data.number}`,
            name: fileName,
            fileName: fileName,
          })
        );

        cnt += 1;
      }
      setLoading(true);
      setProgress(75);
      setProgressText("Uploading PDFs");
      try {
        await Promise.all(promises);
      } catch {
      } finally {
        setUploaded(true);
        onClose();
        onDone?.();
        setLoading(false);

        setProgress(0);
        setProgressText(undefined);
      }
    };
    let t = setTimeout(() => {
      generate();
    }, 1000);

    return () => clearTimeout(t);
  }, [data, handleDownload, lines, onClose, onDone, open, uploaded]);

  function CalcPages(maxHeight: number, totalItems: ILineItem[]) {
    const pages: ILineItem[][] = [];
    let currentPage: ILineItem[] = [];
    let cumulativeHeight = 0;

    // Define the height for all pages except the last page
    const standardPageMaxHeight = maxHeight;

    let count = 0;

    for (let i = 0; i < totalItems.length; i++) {
      const item = totalItems[i];
      const itemHeight = heights[count] - 28; // Adjust the item height
      count += 1;

      // Determine if adding the next item would exceed the height limit of the current page
      if (cumulativeHeight + itemHeight > standardPageMaxHeight) {
        // Push the current page to pages and reset for the next page
        pages.push(currentPage);
        currentPage = [];
        cumulativeHeight = 0;
      }

      // Add the item and update the cumulative height
      currentPage.push(item);
      cumulativeHeight += itemHeight;
    }

    // Handle the last page case
    if (currentPage.length > 0) {
      // Check the cumulative height of the current page
      const remainingSpace = maxHeight - cumulativeHeight;

      // If there is not enough space for the div (e.g., 300)
      if (remainingSpace < 500) {
        pages.push(currentPage); // Push the current page
        pages.push([]); // Push an empty page to indicate that the next page is needed
      } else {
        // If there is enough space, just push the current page
        pages.push(currentPage);
      }
    } else {
      // If no items were added at all, you can also decide to push an empty page here
      pages.push([]); // Optional: push an empty page if needed
    }

    setTotalPagesContent(pages);
  }

  if (!createdSo || !lines?.result) {
    return (
      <MyDialog title="SO Pdf" open={open} onClose={onClose}>
        <Box m={2}>
          <CircularProgress />
        </Box>
      </MyDialog>
    );
  }

  return (
    <MyDialog title="SO Pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth keepMounted>
      <Box>
        <Button
          variant="contained"
          onClick={() => handleDownload({ download: true, title: "order" })}
          disabled={loading}
        >
          Download
        </Button>
        {(progress || progressText) && (
          <Box display="flex" flexDirection="column" textAlign="center" gap={1} my={1}>
            <Typography variant="caption">{progressText}</Typography>
            <LinearProgress variant="determinate" value={progress} title={progressText} />
          </Box>
        )}
        <div className="flex-1 overflow-y-auto bg-gray-100" ref={SOpagesRef}>
          {SO.map(({ component, key }) => (
            <div className="w-[21cm] min-h-[30cm] bg-white mx-auto my-4 shadow-lg pdf-page" key={key}>
              {component}
            </div>
          ))}
        </div>
      </Box>
    </MyDialog>
  );
}

const SectionTitle = (item: any) => {
  if (item.freight) return "Freight";
  if (item?.ItemId) return item.ItemId.no;
  else return "Service Program Options";
};

function measureDiv(item: ILineItem): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    // Create a temporary container
    const tempDiv = document.createElement("div");

    // Apply styles for offscreen positioning and sizing
    tempDiv.style.position = "absolute";
    tempDiv.style.top = "-9999px";
    tempDiv.style.left = "-9999px";
    tempDiv.style.width = "672.938px";
    tempDiv.style.height = "auto";

    // Append the tempDiv to the document body
    document.body.appendChild(tempDiv);

    // Render the ItemTable component into tempDiv
    ReactDOM.render(
      <div className="w-full h-auto">
        <div className="mt-[4%]">
          {item.sort === 1 ? <SectionHeader title={SectionTitle(item)} number={item.group || ""} /> : <></>}
        </div>
        <ItemTable
          no={item.itemNo || item.ItemId?.no || item.ItemId?.name || ""}
          groupLine={item.group && item.sort ? `[${item.group}]-${item.sort}` : ""}
          description={item.description || ""}
          qty={item.qty}
          unitPrice={item.price}
          totalPrice={item.total}
        />
      </div>,
      tempDiv
    );

    // Use requestAnimationFrame to measure after rendering is complete
    requestAnimationFrame(() => {
      const dimensions = {
        width: tempDiv.offsetWidth,
        height: tempDiv.offsetHeight,
      };

      // Clean up
      ReactDOM.unmountComponentAtNode(tempDiv);
      document.body.removeChild(tempDiv);

      // Resolve with the measured dimensions
      resolve(dimensions);
    });
  });
}
