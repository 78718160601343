import React, { forwardRef } from "react";
import phone from "assets/pdf/quote/phone.svg";
import email from "assets/pdf/quote/email.svg";
import { PartFlowType } from "api/partFlow";

const IssueFooter = forwardRef<HTMLDivElement, { data?: PartFlowType }>(({ data }, ref) => {
  return (
    <div
      style={{
        width: "21cm",
        display: "flex",
        justifyContent: "center",
        bottom: 0,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "42px",
          backgroundColor: "#1c2532",
          position: "relative",
          alignItems: "center",
          zIndex: 6,
          bottom: 50,
        }}
      >
        <div
          ref={ref}
          style={{
            width: "75%",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: 40,
            paddingLeft: 80,
            paddingTop: 10,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img alt="phone" src={phone} />
            <span style={{ fontSize: 12, marginLeft: 10 }}>1.877.377.6769</span>
          </div>
          <p style={{ color: "#FFFFFF" }}>|</p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img alt="email" src={email} />
            <span style={{ fontSize: 12, marginLeft: 10 }}>Issue@dspmanufacturing.com</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default IssueFooter;
