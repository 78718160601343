import React, { forwardRef } from "react";
import { styled } from "@mui/material";
import { format } from "date-fns";
import useSWR from "swr";

import { formatCurrency, getId, resizeArray } from "logic/utils";
import { IPO, POLineItem } from "api/po";
import { IVendor } from "api/vendor";

import dspm from "assets/icons/dspm.png";
import { ISO } from "api/so";

const Wrapper = styled("div")`
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  color: #333;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .logo-section {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .logo {
    width: 120px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 24px;
  }

  .company-info {
    font-size: 14px;
  }

  .po-details {
    border: 1px solid #000;
    padding: 10px;
    width: 200px;
  }

  .po-details div {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }

  .so-number {
    margin: 10px 0;
    font-weight: bold;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }

  .address-box {
    background: #f0f0f0;
    padding: 15px;
  }

  .address-box h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f0f0f0;
  }

  .info-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin: 20px 0;
  }

  .info-box {
    background: #f0f0f0;
    padding: 8px;
    text-align: center;
  }

  .total {
    text-align: right;
  }

  .notes {
    margin: 20px 0;
    font-style: italic;
    color: #666;
  }

  .footer {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
    color: #666;
  }
`;

function PurchasePoOld({ po, vendor }: { po: IPO; vendor?: IVendor }, ref: any) {
  const { data: poLineItems } = useSWR<{ result: POLineItem[]; total: number }>(`/polineitem?POId=${getId(po)}`);
  const { data: so } = useSWR<ISO>(po?.sos?.[0] ? `/so/${getId(po?.sos?.[0])}` : null);

  return (
    <Wrapper ref={ref}>
      <div className="header">
        <div className="logo-section">
          <div className="logo">
            <img alt="dspm" src={dspm} style={{ width: "120px", height: "auto", marginTop: "20px" }} />
          </div>
          <div className="company-info">
            <h2>Digital Signal Power Manufacturer, Inc.</h2>
            <p>
              439 S. Stoddard Ave
              <br />
              San Bernardino, CA 92401
              <br />
              (909) 930-3353 • FAX (909) 930-3335
            </p>
          </div>
        </div>
        <div className="po-details">
          <h2>Purchase Order</h2>
          <div>
            <span>Date:</span>
            <span> {po.date ? format(po.date, "MM-dd-yy") : ""}</span>
          </div>
          <div>
            <span>PO#:</span>
            <span>{po?.number}</span>
          </div>
          <div>
            <span>SO No:</span>
            <span>{so?.number}</span>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="address-box">
          <h3>Vendor:</h3>
          <p>
            {po.vendorName}
            <br />
            {po.vendorEmail}
          </p>
        </div>
        <div className="address-box">
          <h3>Bill To:</h3>
          <p>
            {po?.billingCompany || "Digital Signal Power Manufacturer,Inc"}
            <br />
            {po?.billingAddress || "439 S. Stoddard Ave"}
            <br />
            {po?.billingState}
            {po?.billingState ? "," : ""} {po?.billingCity || "San Bernardino"}
            {po?.billingCity ? "," : ""} {po?.billingZipcode || "CA 92401"}
            {/* {po?.billingCompany}
            <br />
            {po?.billingAddress}
            <br />
            {po?.billingState}
            {po?.billingState ? "," : ""} {po?.billingCity}
            {po?.billingCity ? "," : ""} {po?.billingZipcode} */}
          </p>
        </div>
        <div className="address-box">
          <h3>Ship To:</h3>
          <p>
            {po?.shippingCompany}
            <br />
            Attn: Diego
            <br />
            {po?.shippingAddress}
            <br />
            {po?.shippingState}
            {po?.shippingState ? "," : ""} {po?.shippingCity}
            {po?.shippingCity ? "," : ""} {po?.billingZipcode}
            <br />
            (323) 363-7777
            <br />
            dserrano@dspmanufacturing.com
          </p>
        </div>
      </div>

      <table className="info-table">
        <tr>
          <th style={{ width: "15%" }}>Phone</th>
          <th style={{ width: "15%" }}>Fax</th>
          <th style={{ width: "15%" }}>RMA NO</th>
          <th style={{ width: "15%" }}>Terms</th>
          <th style={{ width: "15%" }}>Date Acknowledged</th>
          <th style={{ width: "15%" }}>Ship Via</th>
        </tr>
        <tr>
          <td>{po?.billingPhone}</td>
          <td>{po?.billingFax}</td>
          <td>{po?.billingFax}</td>
          <td>{po?.terms}</td>
          <td>{po?.ackDate ? format(po?.ackDate, "MM-dd-yy") : ""}</td>
          <td></td>
        </tr>
      </table>
      <table className="line-items-table" style={{ margin: 0 }}>
        <tr>
          <th>Item</th>
          <th>Description</th>
          <th>Vendor SKU</th>
          <th style={{ width: "1cm" }}>Qty</th>
          <th style={{ width: "1cm" }}>U/M</th>
          <th style={{ width: "1cm" }}>Rate</th>
          <th>Amount</th>
        </tr>
        {resizeArray(poLineItems?.result || [], 15).map((i) => (
          <tr key={getId(i)}>
            <td>
              {typeof i === "number" ? <div style={{ height: "0.5cm" }} /> : i.itemNo || i.ItemId?.no || i.ItemId?.name}
            </td>
            <td>
              {typeof i === "number" ? <div style={{ height: "0.5cm" }} /> : i.itemDescription || i.ItemId?.description}
            </td>
            <td>{typeof i === "number" ? <div style={{ height: "0.5cm" }} /> : i.vendorSKU || i.vending?.vendorSKU}</td>
            <td style={{ width: "1cm" }}>{typeof i === "number" ? <div style={{ height: "0.5cm" }} /> : i.qty}</td>
            <td style={{ width: "1cm" }}>
              {typeof i === "number" ? <div style={{ height: "0.5cm" }} /> : i.ItemId?.uom}
            </td>
            <td style={{ width: "1cm" }}>
              {typeof i === "number" ? <div style={{ height: "0.5cm" }} /> : formatCurrency(i.cost || 0)}
            </td>
            <td>
              {typeof i === "number" ? (
                <div style={{ height: "0.5cm" }} />
              ) : (
                formatCurrency((i.cost || 0) * (i.qty || 0))
              )}
            </td>
          </tr>
        ))}
      </table>

      <div className="total" style={{ marginTop: 8 }}>
        <strong style={{ border: "1px solid", padding: "8px 24px" }}>
          Total: <span style={{ margin: "0 16px" }} />
          {formatCurrency(poLineItems?.result.reduce((p, c) => p + (c.qty || 0) * (c.cost || 0), 0))}
        </strong>
      </div>

      <div className="notes">
        <strong>Note:</strong> Any product that requires a data sheet, drawings, or specs, needs to be made to the DSPM
        specifications provided on documents or it will not be accepted.
      </div>

      <div className="footer">439 S. Stoddard Ave, San Bernardino, CA 92401 - Phone (909) 930-3353</div>
    </Wrapper>
  );
}

export default forwardRef(PurchasePoOld);
