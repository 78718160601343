import React, { ChangeEvent, useState } from "react";
import { Box, TextField, useMediaQuery, Chip } from "@mui/material";
import { Form, Formik } from "formik";
import Dialog from "app/Dialog";
import Button from "app/Button";
import { post } from "api";
import AsyncCombo from "common/AsyncCombo";
import { clusterType } from "api/cluster";
import { mutate } from "swr";
import FileUploader from "app/UploadButton";
import { toast } from "react-toastify";

// const schema = Yup.object().shape({
//   email: Yup.array().min(1, "At least one email is required"),
//   subject: Yup.string(),
//   description: Yup.string(),
// });

export function createFormData(obj: any): FormData {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
        formData.append(key, String(value));
      } else if (value instanceof File) {
        formData.append(key, value);
      } else if (Array.isArray(value) && value.some((f) => f instanceof File)) {
        value.forEach((item: any) => {
          if (item instanceof File) {
            formData.append(key, item);
          } else {
            formData.append(key, String(item));
          }
        });
      } else if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else if (typeof value === "object" && value !== null) {
        formData.append(key, JSON.stringify(value));
      }
    }
  }

  return formData;
}

export default function AddPost({ open, onClose, setRefresh }: { open: boolean; onClose: any; setRefresh: any }) {
  const phone = useMediaQuery("(max-width:600px)");
  const [selectedTopic, setSelectedTopic] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentTag, setCurrentTag] = useState("");

  const handleSubmit = (data: any) => {
    setIsSubmitting(true);
    const modifiedData = {
      ...data,
      timeDuration: Number(data?.timeDuration),
      TopicId: selectedTopic?.id,
      tags: data?.tags && data?.tags?.length > 0 ? data?.tags?.join(",") : undefined,
    };
    const formData = createFormData(modifiedData);

    post(`/post`, formData)
      .then(() => {
        mutate("/post");
        onClose();
        setRefresh((p: any) => p + 1);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Dialog title="Add Post" open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box m={phone ? 1 : 2}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ tags: [] } as any}
          // validationSchema={schema}
        >
          {({ setFieldValue, values, handleSubmit }) => (
            <Form>
              <Box sx={{ display: "grid", gridTemplateColumns: phone ? "1fr" : "1fr 1fr", gap: 2 }}>
                <TextField
                  value={values?.title}
                  name="title"
                  label="Title"
                  onChange={(e) => setFieldValue("title", e.target.value)}
                  sx={{ gridColumnEnd: !phone ? "span 2" : "" }}
                />
                <AsyncCombo
                  label="Topic"
                  placeholder="All"
                  url="/topic"
                  filterBy=""
                  getOptionLabel={(o) => String(o?.name) || ""}
                  getOptionSelected={(o, v) => o?.id === v?.id}
                  value={selectedTopic}
                  onChange={(e, nv) => setSelectedTopic(nv)}
                />
                <TextField
                  value={values?.timeDuration}
                  name="timeDuration"
                  label="Time Duration"
                  onChange={(e) => setFieldValue("timeDuration", e.target.value)}
                  sx={{ height: "3rem" }}
                />
                <TextField
                  value={values?.summary}
                  name="summary"
                  label="Summary"
                  onChange={(e) => setFieldValue("summary", e.target.value)}
                  sx={{ gridColumnEnd: !phone ? "span 2" : "" }}
                  multiline
                  rows={6}
                />

                <TextField value={currentTag} name="tag" label="Tag" onChange={(e) => setCurrentTag(e.target.value)} />
                <Button
                  variant="contained"
                  sx={{ width: "11rem" }}
                  onClick={() => {
                    const temp = [...values?.tags, currentTag];
                    setFieldValue("tags", temp);
                    setCurrentTag("");
                  }}
                >
                  Add Tag
                </Button>

                <Box sx={{ display: "flex", gridColumnEnd: !phone ? "span 2" : "", flexWrap: "wrap", gap: "0.5rem" }}>
                  {values?.tags?.map((tag: string, index: number) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => {
                        const temp = values?.tags?.filter((t: string) => t !== tag);
                        setFieldValue("tags", temp);
                      }}
                    />
                  ))}
                </Box>

                <Box sx={{ mt: "2rem", width: "20rem" }}>
                  <FileUploader
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        setFieldValue("header", file);
                      }
                    }}
                    onError={(err) => toast.error(err)}
                    allowedTypes={["image"]}
                    maxSize={5}
                    title={"header photo"}
                  />
                </Box>
              </Box>
              <Button
                sx={{ mt: "2rem" }}
                type="button"
                onClick={() => handleSubmit()}
                kind="add"
                variant="contained"
                color="success"
                disabled={!values?.title || !values?.timeDuration || !values?.summary || !selectedTopic || isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}
