import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, TextField, Chip } from "@mui/material";
import { Form, Formik } from "formik";
import Dialog from "app/Dialog";
import Button from "app/Button";
import { post } from "api";
import AsyncCombo from "common/AsyncCombo";
import { clusterType } from "api/cluster";
import { mutate } from "swr";
import { toast } from "react-toastify";

interface ClusterType {
  id: string; 
  clusterValue: string;
}

export default function AddTopicModal({
  open,
  onClose,
  setRefresh,
  initialTopic,
}: {
  open: boolean;
  onClose: any;
  setRefresh: any;
  initialTopic?: any;
}) {
  const [selectedClusters, setSelectedClusters] = useState<ClusterType[]>([]);
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comboValue, setComboValue] = useState<ClusterType | null>(null);

  useEffect(() => {
    if (!initialTopic || !initialTopic.ClusterId) return;
    setSelectedClusters(initialTopic.ClusterId);
  }, [initialTopic]);

  const handleClusterChange = (e: any, newValue: ClusterType | null) => {
    if (!newValue) return;
    const isSelected = selectedClusters.some(cluster => cluster.id === newValue.id);
    
    if (!isSelected) {

      setSelectedClusters(prev => [...prev, newValue]);
    }
  
    setComboValue(null);
  };

  const handleDelete = (clusterId: string) => { 
    setSelectedClusters(prev => prev.filter(cluster => cluster.id !== clusterId));
  };

  const handleSubmit = (data: any) => {
    const topicData = {
      name: data.name,
      ClusterId: checked ? null : selectedClusters.map(cluster => cluster.id),
    };

    setIsSubmitting(true);
    post(`/topic`, topicData)
      .then(() => {
        mutate("/topic");
        onClose();
        setRefresh((p: any) => p + 1);
        toast.success("Topic Added");
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Dialog title="Add Topic" open={open} onClose={onClose} maxWidth="sm">
      <Formik onSubmit={handleSubmit} initialValues={{ name: initialTopic?.name || "" } as any}>
        {({ setFieldValue, values, handleSubmit }) => (
          <Form style={{ width: "30rem" }}>
            <Box display="grid" gridTemplateColumns="1fr" gap={2}>
              <TextField
                value={values?.name}
                name="name"
                label="Name"
                onChange={(e) => setFieldValue("name", e.target.value)}
              />

              <FormControlLabel
                label="Do Not Use Model Number"
                control={<Checkbox checked={checked} onChange={(e, nv) => setChecked(nv)} />}
                sx={{ ml: "-0.5rem" }}
              />

              {!checked && (
                <Box>
                  <Box sx={{ mb: 4, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {selectedClusters.map((cluster) => (
                      <Chip
                        key={cluster.id}
                        label={cluster.clusterValue}
                        onDelete={() => handleDelete(cluster.id)}
                        variant="outlined"
                      />
                    ))}
                  </Box>

                  <AsyncCombo
                    label="Add Model"
                    placeholder="Select models..."
                    url="/cluster"
                    filterBy="clusterValue"
                    defaultParams={{ class: "device" }}
                    getOptionLabel={(o) => String(o?.clusterValue) || ""}
                    getOptionSelected={(o, v) => o?.id === v?.id}
                    value={comboValue}
                    onChange={handleClusterChange}
                    style={{ marginTop: "-0.8rem" }}
                  />
                </Box>
              )}
            </Box>
            <Button
              sx={{ mt: "3rem" }}
              type="button"
              onClick={() => handleSubmit()}
              kind="add"
              variant="contained"
              color="success"
              disabled={isSubmitting || !values?.name || (!checked && selectedClusters.length === 0)}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}