import { ListResponse } from "api";
import { PartFlowType } from "api/partFlow";
import { formatCurrency, getId } from "logic/utils";
import React, { forwardRef } from "react";
import useSWR from "swr";

const IssueTotal = forwardRef<HTMLDivElement, { partflow: PartFlowType }>(({ partflow }, ref) => {
  const { data } = useSWR<ListResponse<any>>(`/goodslineitem?GoodsIssueId=${getId(partflow)}`);

  return (
    <div style={{ width: "20.5cm", marginTop: "30px" }} ref={ref}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{
            background: "#F8B11B",
            width: "200px",
            height: "auto",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            alignItems: "center",
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Total:</p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>
              {formatCurrency(data?.result?.reduce((p, c) => p + (c.valuation || 0), 0))}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "40px",
          marginLeft: "5px",
        }}
      >
        <div style={{ width: "50%" }}>
          <p style={{ fontSize: "12px", margin: 0 }}>Notes:</p>
          <p style={{ color: "#0D568A", fontSize: "14px", fontWeight: "800", maxWidth: "200px" }}>{partflow?.note}</p>
        </div>
        <div style={{ marginBottom: "40px", marginTop: "20px", marginRight: "10px" }}>
          <p style={{ fontSize: "medium", fontWeight: "bold", margin: 0 }}>DSPM Manufacturing Inc.</p>
          <p style={{ fontSize: "small", margin: 0.5, color: "#545454" }}>
            439 S. Stoddard Ave,
            <br /> San Bernardino, CA 92401{" "}
          </p>
          <p style={{ fontSize: "small", margin: 0, color: "#545454" }}>(909) 930-3353</p>
          <p style={{ fontSize: "small", margin: 0, color: "#545454" }}>www.DSPManufacturing.com</p>
        </div>
      </div>
    </div>
  );
});

export default IssueTotal;
