import { delete_, get, ListResponse, patch, post } from ".";

export interface IAddress {
  id: string;
  companyName: string;
  address: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  country: string;
  type: "Shipping" | "Billing";
  phone?: string;
  ext: string;
  specialInstruction: string;
  account: string;
  name: string;
  main: Boolean;
  modelType: String;
  modelId: String;
  lat: Number;
  lon: Number;
  firstname: string;
  lastname: string;
  MI: string;
  prefferedCompany: string;
  prefferedService: string;
  attn?: string;
  fax?: string;
  twentyFourHourName?: string;
  twentyFourHourPhone?: string;
}

export const getAddresses = () => {
  return get("/address");
};

export const getAllModelAddress = (model: string, id: string) => {
  return get(`/address/${model}/${id}`);
};

export const createAModelAddress = (model: string, id: string, data: IAddress) => {
  return post(`/address/${model}/${id}`, data);
};

export const updateAModelAddress = (id: string, data: IAddress) => {
  return patch(`/address/${id}`, data);
};

export const deleteAModelAddress = (id: string) => {
  return delete_(`/address/${id}`);
};

export const getBillingAddress = (id: string) => {
  return get(`/address?modelId=${id}&type=Billing`) as Promise<ListResponse<IAddress>>;
};

export const getShippingAddress = (id: string) => {
  return get(`/address?modelId=${id}&type=Shipping`) as Promise<ListResponse<IAddress>>;
};
