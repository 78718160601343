import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const DatePickerEditor = forwardRef((props: any, ref: any) => {
  console.log('DatePickerEditor Props:', props);

  const [value, setValue] = useState(props.value ? new Date(props.value) : null);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log('DatePickerEditor Mounted');
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      console.log('GetValue called:', value);
      return value ? value.getTime() : null;
    },

    afterGuiAttached() {
      console.log('AfterGuiAttached called');
      refContainer.current?.focus();
    },

    // Ensure the editor works with AG Grid
    isPopup() {
      return true;
    }
  }));

  const handleChange = (newValue: Date | null) => {
    console.log('Date Changed:', newValue);
    setValue(newValue);
    
    // Explicitly call stopEditing if it exists
    if (props.stopEditing) {
      console.log('Stopping Edit');
      props.stopEditing();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div ref={refContainer} style={{ width: '100%' }}>
        <DateTimePicker
          label="Edit Date"
          value={value}
          onChange={handleChange}
          slotProps={{ 
            textField: { 
              variant: 'standard',
              fullWidth: true 
            } 
          }}
          // Add these props to ensure interaction
          open={true}
          onClose={() => {
            console.log('Picker Closed');
            props.stopEditing?.();
          }}
        />
      </div>
    </LocalizationProvider>
  );
});

DatePickerEditor.displayName = 'DatePickerEditor';