import Footer from "./footer/Footer";
import Header from "../PO/header/Header";

export default function QuotePage5({ data, pageNumber }) {
  return (
    <div className="relative flex flex-col items-center h-full w-[98%] mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div style={{ position: "absolute", left: 0, top: "25cm", width: "20.5cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    TermsPayment:
      "The Seller reserves the right to require payment in advance or C.O.D. and otherwise modify credit terms. Unless otherwise agreed, partial shipments can be made and payment shall become due in accordance with the designated terms upon submission of invoices. Each shipment shall be invoiced separately and Buyer shall pay for each shipment as invoiced. If shipments are delayed by Buyer without Seller's prior written consent, payments shall become due on the date when Seller is prepared to make shipment. Products held for the Buyer by the Seller shall be at the risk and expense of the Buyer. Seller reserves the right to add a monthly service charge as allowed by law.",
    TitleDelivery:
      "Unless otherwise instructed in writing, Seller shall ship by the methods it deems most advantageous. Transportation shall be collect, or if prepaid, will be subsequently billed to the Buyer. Title shall pass to the Buyer, and Seller's liability as to delivery shall cease on delivery of products to carrier at Seller's facility, regardless of any provisions for payment of freight or insurance or the form of shipping documents. Risk of loss or damage in transit shall rest with the Buyer. All claims for loss or damage will be filed with the carrier. Claims against Seller for shortages occurring before delivery to carrier shall be waived unless made within thirty (30) days after delivery of shipment to the Buyer.",
    Contingencies:
      "All shipping dates and quantities are approximate. Seller shall use reasonable efforts to fill all orders according to the agreed upon schedule and quantity. Seller shall not be responsible for any failure to perform resulting from unforeseen circumstances or causes beyond Seller's reasonable control. Such causes include, but are not limited to; strikes, floods, fires, labor disputes, accidents, inability to maintain materials or supplies, excessive demand for products over the available supply, custom duties or surcharges, and interruption for any reason in the manufacture of Seller's products by Seller's suppliers, any act of God, or the action of any government. In the event of any delay caused by such a contingency, the date of delivery shall, at the request of the Seller be deferred for period equal to the period of delay.",
  };
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Terms of Payment:</span>
            <span className="text-[16px] font-light text-justify">{texts.TermsPayment}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Title and Delivery:</span>
            <span className="text-[16px] font-light text-justify">{texts.TitleDelivery}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Contingencies:</span>
            <span className="text-[16px] font-light text-justify">{texts.Contingencies}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
