import { toCanvas } from "html-to-image";
import { jsPDF } from "jspdf";
import { arrayBufferToBase64 } from "logic/utils";

export const exportPdf = async (input: HTMLElement, name?: string): Promise<Blob | undefined> => {
  const doc = new jsPDF({ unit: "px", orientation: "portrait", format: "A4" });
  let res: Blob | undefined;

  await doc.html(input, {
    callback: (doc) => {
      doc.save(name);
      res = doc.output("blob");
    },
    x: 15,
    y: 15,
    html2canvas: { scale: 0.5, letterRendering: true },
  });

  return res;
};

const addNewPage = ({
  pdf,
  afterAddNewPage,
  beforeAddNewPage,
}: {
  pdf: jsPDF;
  beforeAddNewPage?: () => void;
  afterAddNewPage?: () => void;
}) => {
  beforeAddNewPage && beforeAddNewPage();
  pdf.addPage();
  afterAddNewPage && afterAddNewPage();
};

export const writeFooter = ({ pdf, page }: { pdf: jsPDF; page: number }) => {
  const docWidth = pdf.internal.pageSize.getWidth();
  const docHeight = pdf.internal.pageSize.getHeight();

  pdf.setFontSize(11);
  pdf.text("www.dspmanufacturing.com", 10, docHeight - 10);
  pdf.text("Page " + page, docWidth - 20, docHeight - 10);
};

export async function takeSnapshot({
  docWidth,
  element,
  scale,
}: {
  element: HTMLElement;
  docWidth: number;
  scale?: number;
}) {
  const start = performance.now();
  const canvas = await toCanvas(element, {
    cacheBust: true,
    backgroundColor: "white",
    style: {
      padding: "0",
      margin: "0",
    },
    pixelRatio: 3,
  });
  const end = performance.now();
  console.log("Took:", end - start);

  const imgHeight = (canvas.height * docWidth) / canvas.width;

  return { data: canvas.toDataURL("image/jpeg", 1), height: imgHeight };
}

export async function addElementToPdf({
  x,
  y,
  pdf,
  width,
  element,
  scale,
}: {
  pdf: jsPDF;
  x: number;
  y: number;
  width?: number;
  element: HTMLElement;
  scale?: number;
}) {
  const docWidth = pdf.internal.pageSize.getWidth();
  const { data, height } = await takeSnapshot({ element, docWidth, scale });

  pdf.addImage(data, "JPEG", x, y, width || docWidth, height);
  return pdf;
}

export async function renderList({
  x,
  y,
  pdf,
  list,
  pageMarginY,
  elementWidth,
  marginBottom = 0,
  marginBetweenItems = 15,
  afterAddNewPage,
  beforeAddNewPage,
  onNewPageAdded,
}: {
  x?: number;
  y?: number;
  pdf: jsPDF;
  list: HTMLCollection;
  pageMarginY?: number;
  marginBottom?: number;
  elementWidth?: number;
  marginBetweenItems?: number;
  beforeAddNewPage?: () => void;
  afterAddNewPage?: () => void;
  onNewPageAdded?: () => void;
}) {
  const docWidth = pdf.internal.pageSize.getWidth();
  const docHeight = pdf.internal.pageSize.getHeight();

  let positionX = x ?? 10;
  let positionY = y ?? 10;
  let currentPage = 1;

  const renderContent = (child: HTMLElement) => {
    return new Promise((resolve) => {
      takeSnapshot({ docWidth: elementWidth || docWidth, element: child }).then(
        ({ data: imgData, height: imgHeight }) => {
          if (positionY + imgHeight + marginBottom > docHeight) {
            addNewPage({
              pdf,
              beforeAddNewPage() {
                if (beforeAddNewPage) {
                  beforeAddNewPage();
                } else {
                  writeFooter({
                    pdf,
                    page: currentPage,
                  });
                }
              },
              afterAddNewPage() {
                if (afterAddNewPage) {
                  afterAddNewPage();
                } else {
                  positionY = (pageMarginY || 0) + 20;
                  currentPage++;
                }
              },
            });
            onNewPageAdded?.();
          }

          pdf.addImage(imgData, "JPEG", positionX, positionY, elementWidth || docWidth, imgHeight);
          positionY += imgHeight + marginBetweenItems;

          resolve([]);
        }
      );
    });
  };

  const children = Array.from(list);

  for (let i = 0; i < children.length; i++) {
    const child = children[i];

    await renderContent(child as HTMLElement);
  }
  if (beforeAddNewPage) {
    beforeAddNewPage();
  } else {
    writeFooter({
      pdf,
      page: currentPage,
    });
  }
  return positionY;
}

export function addWrappedText({
  text,
  textWidth,
  doc,
  fontSize = 10,
  lineSpacing = 5,
  xPosition = 10,
  initialYPosition = 10,
  pageWrapInitialYPosition = 10,
  marginBottom = 10,
  onAfterAddPage,
}: {
  text: string;
  textWidth: number;
  doc: jsPDF;
  fontSize?: number;
  lineSpacing?: number;
  xPosition?: number;
  marginBottom?: number;
  initialYPosition?: number;
  pageWrapInitialYPosition?: number;
  onAfterAddPage?: () => void;
}) {
  let cursorY = initialYPosition;
  const pageHeight = doc.internal.pageSize.height;

  doc.setFontSize(fontSize);
  const splitted = doc.splitTextToSize(text, textWidth);
  const splittedHeight = splitted.length * lineSpacing;

  if (cursorY + splittedHeight + marginBottom > pageHeight) {
    doc.addPage();
    onAfterAddPage && onAfterAddPage();
    cursorY = pageWrapInitialYPosition;
  }
  doc.setFontSize(fontSize);
  doc.text(splitted, xPosition, cursorY, { align: "justify", maxWidth: textWidth + 20 });
  cursorY += splittedHeight;

  return cursorY;
}

/**
 * load a font from remote and add it to jsPDF doc
 * @param path the path of the font, e.g. '/app/fonts/My-Font.ttf'
 * @param doc the jsPDF doc instance to install the font to
 * @return {Promise<void>}
 */
export async function loadFont(path: string, doc: jsPDF): Promise<void> {
  let response = await fetch(path).catch((e) => console.error(e));
  if (!response) {
    return;
  }
  let fontName = path.substring(path.lastIndexOf("/") + 1);

  let contentBuffer = await response.arrayBuffer();
  let contentString = arrayBufferToBase64(contentBuffer);
  if (contentString) {
    doc.addFileToVFS(fontName, contentString);
    doc.addFont(fontName, fontName, "normal");
    doc.setFont(fontName);
  }
}
