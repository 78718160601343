function ItemTable({ groupLine, description, qty, unitPrice, totalPrice, no }) {
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="p-2 text-left">
            <div className="">
              <span className="text-[10px] text-primaryOrange">[Group]-Line</span>
            </div>
          </th>
          <th className="p-2 text-left">
            <div>
              <span className="text-[10px]">Item NO./Description</span>
            </div>
          </th>
          <th className="p-2 text-left">
            <div>
              <span className="text-[10px]">Qty</span>
            </div>
          </th>
          <th className="p-2 text-left">
            <div>
              <span className="text-[10px]">Unit Price</span>
            </div>
          </th>
          <th className="p-2 text-left">
            <div>
              <span className="text-[10px]">Total Price</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-2 text-left align-top">
            <div>
              <span className="text-[10px]">{groupLine}</span>
            </div>
          </td>
          <td className="text-left p-2 align-top w-[60%]" style={{ textAlign: "justify" }}>
            <b>{no}</b>
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: description ? description?.replaceAll("\n", "<br />").replaceAll("\r", "<br />") : "",
              }}
            />
          </td>
          <td className="p-2 text-left align-top">
            <div>
              <span className="text-[10px]">{qty}</span>
            </div>
          </td>
          <td className="p-2 text-left align-top">
            <div>
              <span className="text-[10px] font-light">{unitPrice}</span>
            </div>
          </td>
          <td className="p-2 text-left align-top">
            <div>
              <span className="text-[10px] font-light">{totalPrice}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ItemTable;
