import React, { CSSProperties } from "react";
import { ListAltRounded, SearchRounded } from "@mui/icons-material";
import addnote from "assets/Note.svg";
import { useHistory } from "react-router-dom";
import { IconButton, useMediaQuery } from "@mui/material";
import { openRequestedSinglePopup } from "logic/window";
import { Delete, DetialIcon, Edit, Plus } from "app/IconPack";
import { useLock } from "./Lock";

type iconsType = "delete" | "edit" | "add" | "view" | "openModal" | "addNote";

function Icon({ icon, style }: { icon: iconsType; style?: React.CSSProperties }) {
  switch (icon) {
    case "delete":
      return <Delete sx={style} />;
    case "add":
      return <Plus sx={style} />;
    case "edit":
      return <Edit sx={style} />;
    case "view":
      return <DetialIcon sx={style} />;
    case "openModal":
      return <ListAltRounded />;
    case "addNote":
      return <img style={{ width: "12px", height: "12px" }} src={addnote} />;
    default:
      return <SearchRounded style={style} />;
  }
}

export default function DataGridAction({
  icon,
  href,
  style,
  activeColor,
  openAs,
  controlledLock,
  onClick,
  disabled,
}: {
  href?: string;
  icon: iconsType;
  activeColor?: string;
  style?: CSSProperties;
  openAs?: "new_window" | "current_window";
  controlledLock?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}) {
  const { lock } = useLock();
  const finalLock = controlledLock !== undefined ? controlledLock : lock;
  const color = activeColor || "#426792";
  const phone = useMediaQuery("(max-width: 500px)");
  const history = useHistory();

  return (
    <div title={icon.toLocaleUpperCase()}>
      <IconButton sx={{ p: 0 }} disabled={disabled}>
        <div
          className={`datagrid-action-${icon}`}
          onClick={() => {
            if (href && (phone || openAs === "current_window")) {
              history.push(href);
            } else if (href && (!phone || openAs === "new_window")) {
              openRequestedSinglePopup({ url: href });
            } else if (onClick) {
              onClick();
            }
          }}
        >
          <Icon
            icon={icon}
            style={{
              width: icon === "delete" ? 12 : "",
              fontSize: "1.8rem",
              color: finalLock ? "#ccc" : color,
              cursor: finalLock ? "auto" : "pointer",
              ...(style && (style as any)),
            }}
          />
        </div>
      </IconButton>
    </div>
  );
}
