import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import SectionHeader2 from "./SectionHeader2";
export default function QuotePage19({ data, pageNumber }) {
  return (
    <div className="relative flex flex-col items-center h-full w-[98%] mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div style={{ position: "absolute", left: 0, top: "25cm", width: "20.5cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const tableData = [
    {
      planType: "TOS",
      coverage: "8-5 Mon-Fri",
      labor: "Plan Type",
      parts: "Plan Type",
      travelExpenses: "Purchased During Warranty",
      emergencyCalls: "",
      preventiveMaintenance: "",
      mustBePurchased: false,
      callsPerYear: false,
    },
    {
      planType: "CI",
      coverage: "Service is as per customer request",
      labor: "Plan Type",
      parts: "",
      travelExpenses: "Plan Type",
      emergencyCalls: "",
      preventiveMaintenance: "",
      mustBePurchased: false,
      callsPerYear: false,
    },
    {
      planType: "SW58",
      coverage: "8-5 Mon-Fri",
      labor: "Plan Type",
      parts: "Plan Type",
      travelExpenses: "Plan Type",
      emergencyCalls: "Plan Type",
      preventiveMaintenance: "",
      mustBePurchased: true,
      callsPerYear: false,
    },
    {
      planType: "SW724",
      coverage: "7 days per week 24 hours per day Except Holidays",
      labor: "Plan Type",
      parts: "Plan Type",
      travelExpenses: "Plan Type",
      emergencyCalls: "Plan Type",
      preventiveMaintenance: "",
      mustBePurchased: true,
      callsPerYear: false,
    },
    {
      planType: "SW365",
      coverage: "7 days per week 24 hours per day Except Holidays",
      labor: "Plan Type",
      parts: "Plan Type",
      travelExpenses: "Plan Type",
      emergencyCalls: "Plan Type",
      preventiveMaintenance: "",
      mustBePurchased: true,
      callsPerYear: false,
    },
    {
      planType: "SF58",
      coverage: "8-5 Mon-Fri",
      labor: "Plan Type",
      parts: "Plan Type",
      travelExpenses: "Plan Type",
      emergencyCalls: "Plan Type",
      preventiveMaintenance: "Plan Type",
      mustBePurchased: true,
      callsPerYear: true,
    },
    {
      planType: "SF724",
      coverage: "7 days per week 24 hours per day Except Holidays",
      labor: "Plan Type",
      parts: "Plan Type",
      travelExpenses: "Plan Type",
      emergencyCalls: "Plan Type",
      preventiveMaintenance: "Plan Type",
      mustBePurchased: true,
      callsPerYear: true,
    },
    {
      planType: "SF365",
      coverage: "7 days per week 24 hours per day Except Holidays",
      labor: "Plan Type",
      parts: "Plan Type",
      travelExpenses: "Plan Type",
      emergencyCalls: "Plan Type",
      preventiveMaintenance: "Plan Type",
      mustBePurchased: true,
      callsPerYear: true,
    },
  ];
  const Notes = [
    "X  Included with Plan Type.",
    "Included If Purchased During Warranty Period or With Unit Under a Service Contract. Coverage for parts must be from other coverage such as an Extended Warranty or Service Contract.",
    "-n   Indicates the number of Preventive Maintenance Calls per year.",
    "*  Must be purchased during an active original factory warranty, during an active Extended Warranty, during an active Service Contract, or After a CI with DSPM Management approval. Multiple Unit discount for more than one unit at a single location only, and startups are all at the same time.",
  ];

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <SectionHeader2 title={"Comparison Chart"} />
        </div>
        <div className="mt-[4%]">
          <table className="w-full border border-[#cccccc] border-collapse">
            <thead>
              <tr>
                <th className="border border-[#cccccc] border-collapse w-[11%]">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">Plan Type</span>
                  </div>
                </th>
                <th className="border border-[#cccccc] border-collapse w-[40%]">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">COVERAGE</span>
                  </div>
                </th>
                <th className="border border-[#cccccc] border-collapse">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">LABOR</span>
                  </div>
                </th>
                <th className="border border-[#cccccc] border-collapse">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">PARTS</span>
                  </div>
                </th>
                <th className="border border-[#cccccc] border-collapse">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">TRAVEL EXPANSES</span>
                  </div>
                </th>
                <th className="border border-[#cccccc] border-collapse">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">EMERGENCY CALLS</span>
                  </div>
                </th>
                <th className="border border-[#cccccc] border-collapse">
                  <div className="flex flex-col items-center p-2">
                    <span className="text-[12px] font-bold ml-[1%] text-black">PREVENTIVE MAINTENANCE</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td className="border border-[#cccccc] border-collapse p-2">
                    <div className="flex flex-row">
                      <span className="text-[11px] text-primaryBlue">{row.planType}</span>
                      {row.callsPerYear ? <span className="text-[11px] text-primaryBlue font-light">-n</span> : ""}
                      {row.mustBePurchased ? <span className="text-[11px] text-primaryOrange">*</span> : ""}
                    </div>
                  </td>
                  <td className="border border-[#cccccc] border-collapse align-middle">
                    <div className="flex flex-col items-center align-middle">
                      <span className="text-[11px] text-center font-light">{row.coverage}</span>
                    </div>
                  </td>
                  <td className="border border-[#cccccc] border-collapse">
                    {row.labor !== "" ? (
                      row.labor === "Plan Type" ? (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text-justify font-light">X</span>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">
                            X<sup>1</sup>
                          </span>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="border border-[#cccccc] border-collapse">
                    {row.parts !== "" ? (
                      row.parts === "Plan Type" ? (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">X</span>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">
                            X<sup>1</sup>
                          </span>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="border border-[#cccccc] border-collapse">
                    {row.travelExpenses !== "" ? (
                      row.travelExpenses === "Plan Type" ? (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">X</span>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">
                            X<sup>1</sup>
                          </span>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="border border-[#cccccc] border-collapse">
                    {row.emergencyCalls !== "" ? (
                      row.emergencyCalls === "Plan Type" ? (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">X</span>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">
                            X<sup>1</sup>
                          </span>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="border border-[#cccccc] border-collapse">
                    {row.preventiveMaintenance !== "" ? (
                      row.preventiveMaintenance === "Plan Type" ? (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">X</span>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <span className="text-[11px] text font-light">
                            X<sup>1</sup>
                          </span>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-[4%]">
          <SectionHeader2 title={"NOTES"} />
        </div>
        <div className="mt-[2%]">
          {Notes.map((item, index) => {
            return (
              <div className="flex flex-row" key={index}>
                <span className="font-light">•</span>
                {index === 1 ? (
                  <>
                    <span className="text-[16px] font-light ml-1">
                      X<sup>1</sup>
                    </span>

                    <span className="text-[16px] font-light ml-1">{item}</span>
                  </>
                ) : (
                  <span className="text-[16px] font-light ml-1">{item}</span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
