import React from "react";
import DataGridAction from "common/DataGridAction";
import DataTable from "common/DataTable";
import { getId } from "logic/utils";
import { Field } from "formik";

const columns = [
  {
    minWidth: 20,
    field: "action",
    type: "action",

    cellRenderer: ({ data }: any) => {
      return (
        <DataGridAction
          icon="view"
          href={`/panel/website-settings/cluster-type/${getId(data)}`}
          openAs="current_window"
        />
      );
    },
  },
  { field: "name", headerName: "name", minWidth: 100 },
  { field: "description", headerName: "Description", minWidth: 100 },
  { field: "createdAt", flex: 1, type: "date", minWidth: 200 },
];

export default function ClusterTypeList() {
  return <DataTable id="clusterType-list" url="/clustertype" columns={columns} />;
}
