import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import useSWR from "swr";

import QRCode from "app/QRCode";
import MyDialog from "app/Dialog";

import { host } from "host";
import { formatCurrency, getId } from "logic/utils";
import { formatTimestampToDate } from "logic/date";
import Toast from "app/Toast";
import { get } from "api";
import { PDF } from "logic/pdf/PDF";
import IssueTableHeader from "PDFTemplates/GoodIssue/TableHeader";
import IssueFooter from "PDFTemplates/GoodIssue/footer";
import IssueHeader from "PDFTemplates/GoodIssue/header";
import IssueLineItems from "PDFTemplates/GoodIssue/lineItemTable";
import IssueTotal from "PDFTemplates/GoodIssue/subTotal";
import { createAModelDocument } from "api/document";

export default function IssuePdfDialog({
  id,
  open,
  onClose,
  onDone,
}: {
  id: string;
  open: boolean;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data } = useSWR<any>(`/goodsissue/${id}`);
  const [loading, setLoading] = useState(false);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  const lineItemsRef = useRef<HTMLDivElement | null>(null);
  const totalRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const qrRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = useCallback(
    async (number?: string) => {
      try {
        if (!data) {
          return;
        }
        setLoading(true);
        const lines = await get(`/goodslineitem?GoodsIssueId=${getId(data)}`);
        const unit = data?.UnitId ? await get(`/unit/${getId(data.UnitId)}`) : null;
        const deliverTo = data?.DeliveredTo ? await get(`/employee/${getId(data?.DeliveredTo)}`) : null;
        const deliverBy = data?.DeliveredBy ? await get(`/employee/${getId(data?.DeliveredBy)}`) : null;
        const requester = data?.RequesterId ? await get(`/employee/${getId(data?.RequesterId)}`) : null;

        const pdf = new PDF({
          title: "Issue",
          summaryData: {
            "Issue NO.": number || "",
            "Request NO.": data?.partRequestNo || "",
            Date: data?.date ? formatTimestampToDate(data.date) : "",
            "Unit NO.": data?.unitNumber || "",
            Serial: unit?.serial || "",
            "SO NO.": data?.soNumber || "",
          },
          headerCardData: {
            col1: ["Deliver to", deliverTo?.username || ""],
            col2: ["Deliver by", deliverBy?.username || ""],
            col3: ["Requested by", requester?.username || ""],
          },
        });

        if (qrRef.current) {
          const qrCanvas = qrRef.current.getElementsByTagName("canvas")[0];
          pdf.doc.addImage(qrCanvas, "image/png", 100, 5, 20, 20);
        }

        pdf.addTable({
          head: [{ line: "Line", name: "Name", qty: "QTY", um: "U/M", price: "Price", amount: "Amount" }],
          body: lines?.result?.map((l: any, idx: number) => ({
            line: String(idx + 1),
            name: l.ItemId?.name || l.ItemId?.no,
            qty: String(l.quantity || l.mainQty || 0),
            um: l?.ItemId?.uom || "",
            price: formatCurrency(l?.valuation || 0),
            amount: formatCurrency((l?.Qty || l.quantity || l?.quantityDelivered || 0) * (l?.valuation || 0)),
          })),
          note: data?.note || "",
          y: pdf.cursor.y + 5,
        });

        pdf.save(
          data
            ? `${data?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`
            : `GoodsIssue-${formatTimestampToDate(Number(new Date()))}.pdf`
        );
        const blob = pdf.output();

        await createAModelDocument({
          id: getId(data),
          model: "issue",
          file: blob,
          description: data.number || "issue",
          fileName: (data.number || "issue") + ".pdf",
          name: (data.number || "issue") + ".pdf",
          number: data.number || "issue",
        });
        Toast("Document Saved", "success");
        onDone && onDone();
      } catch (error) {
        console.log(error);
      }
    },
    [data, onDone]
  );

  useEffect(() => {
    if (!data || !open) {
      return;
    }
    let t = setTimeout(() => {
      handleDownload(data.number);
    }, 1500);

    return () => clearTimeout(t);
  }, [data, handleDownload, open]);

  if (!data) {
    return (
      <MyDialog title="Issue Pdf" open={open} onClose={onClose}>
        <Box m={2}>
          <CircularProgress />
        </Box>
      </MyDialog>
    );
  }

  return (
    <MyDialog title="Issue Pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <Box>
        <Button variant="contained" onClick={() => handleDownload(data.number)} disabled={loading}>
          Download
        </Button>
        <Box width="21cm" m={2} mx="auto">
          {getId(data) && (
            <div ref={qrRef} style={{ position: "absolute", right: "14cm" }}>
              <QRCode width={100} height={100} value={`${host}/panel/inventory/PartFlow/issues/${getId(data)}`} />
            </div>
          )}
          <IssueHeader ref={headerRef} data={data} />
          <IssueTableHeader ref={tableHeaderRef} />
          <IssueLineItems ref={lineItemsRef} issueId={data.id} partflowId="" />
          <IssueTotal ref={totalRef} partflow={data} />
          <IssueFooter ref={footerRef} data={data} />
        </Box>
      </Box>
    </MyDialog>
  );
}
