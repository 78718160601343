import React, { forwardRef } from "react";
import { formatTimestampToDate } from "logic/date";
import useSWR from "swr";
import { PartFlowType } from "api/partFlow";
import dspm from "assets/icons/dspm.png";
import receiveCircle from "assets/icons/receiveCircle.png";
import { getId } from "logic/utils";

const IssueHeader = forwardRef<HTMLDivElement, { data: PartFlowType }>(({ data }, ref) => {
  const { data: so } = useSWR<any>(`/so/${data?.SOId}`);
  const { data: unit } = useSWR<any>(data?.UnitId ? `/unit/${data?.UnitId}` : null);
  const { data: deliveredBy } = useSWR(`/employee/${getId(data?.DeliveredBy)}`);
  const { data: deliveredTo } = useSWR(`/employee/${getId(data?.DeliveredTo)}`);
  const { data: requester } = useSWR(`/employee/${getId(data?.RequesterId)}`);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        marginBottom: "80px",
        position: "relative",
      }}
    >
      <img alt="dspm" src={dspm} style={{ width: "120px", height: "auto", marginTop: "20px" }} />
      <p
        style={{
          fontSize: "22px",
          fontWeight: "bold",
          margin: "20px",
        }}
      >
        Issue
      </p>
      <div
        style={{
          background: "#f1f1f1",
          width: "75%",
          padding: "20px",
          paddingRight: 60,
          borderTopRightRadius: 50,
          borderBottomRightRadius: 50,
          display: "flex",
          gap: 2,
        }}
      >
        <div style={{ flex: 1 }}>
          <h3 style={{ fontSize: 16, margin: "8px 0" }}>Deliver to:</h3>
          <div>{deliveredTo?.username || ""}</div>
        </div>
        <div style={{ flex: 1, borderLeft: "2px solid white", paddingLeft: 10 }}>
          <h3 style={{ fontSize: 16, margin: "8px 0" }}>Deliver by:</h3>
          <div>{deliveredBy?.username || ""}</div>
        </div>
        <div style={{ flex: 1, borderLeft: "2px solid white", paddingLeft: 10 }}>
          <h3 style={{ fontSize: 16, margin: "8px 0" }}>Requested by:</h3>
          <div>{requester?.username || ""}</div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 320,
          height: 280,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={receiveCircle}
          alt=""
          style={{ position: "absolute", top: 0, right: 0, width: "100%", height: "auto", objectFit: "contain" }}
        />
        <div
          style={{
            marginTop: 15,
            position: "relative",
            zIndex: 5,
            color: "white",
            width: 200,
          }}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}> Issue No:</span>
            <span style={{ fontSize: 12 }}>{data?.number}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Request No:</span>
            <span style={{ fontSize: 12 }}>{data?.partRequestNo}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Date :</span>
            <span style={{ fontSize: 12 }}>{formatTimestampToDate(data?.date)}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Unit No:</span>
            <span style={{ fontSize: 12 }}>{so?.unitNumber || unit?.number}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Serial:</span>
            <span style={{ fontSize: 12 }}>{so?.unitNumber || unit?.serial}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>SO No:</span>
            <span style={{ fontSize: 12 }}>{so?.number}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Terms:</span>
            <span style={{ fontSize: 12 }}>{so?.terms}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default IssueHeader;
