import React, { useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

// Define the data type for our chart
type DataPoint = {
  name: string;
  value: number;
};

// Define the props interface
interface ResponsiveBarChartProps {
  data: DataPoint[];
}

// Generate a random pastel color
const generatePastelColor = () => {
  // Use HSL color space for better control over saturation and lightness
  const hue = Math.floor(Math.random() * 360); // Random hue (0-360)
  const saturation = 70 + Math.random() * 10; // Higher saturation (70-80%)
  const lightness = 65 + Math.random() * 10; // Controlled lightness (65-75%)
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export default function SimpleBarChart({ data }: ResponsiveBarChartProps) {
  // Generate random colors for each data point
  const coloredData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      fill: generatePastelColor(),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length]);

  return (
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={coloredData}>
          <XAxis dataKey="name" tick={{ fill: "#6B7280" }} tickLine={{ stroke: "#6B7280" }} />
          <YAxis tick={{ fill: "#6B7280" }} tickLine={{ stroke: "#6B7280" }} />
          <Tooltip
            contentStyle={{
              backgroundColor: "#ffffff",
              border: "1px solid #e5e7eb",
              borderRadius: "6px",
            }}
          />
          <Bar
            dataKey="value"
            fill="#3B82F6" // This will be overridden by individual bar colors
            radius={[4, 4, 0, 0]}
            maxBarSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
