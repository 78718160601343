import React, { useRef, useState } from "react";
import useSWRImmutable from "swr/immutable";

import { delete_, ListResponse } from "api";
import { addPhoto, photoType, updatePhoto } from "api/photo";
import { Box, Button, IconButton, LinearProgress, Typography } from "@mui/material";
import { getDocumentUrl } from "api/document";
import MyDialog from "app/Dialog";
import { Form, Formik } from "formik";
import TextField from "app/TextField";
import Toast from "app/Toast";
import Confirm from "./Confirm";
import { getId } from "logic/utils";
import DataTable from "./DataTable";
import DataGridAction from "./DataGridAction";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { host } from "host";
import { log } from "util";

export default function PhotosTab({
  model,
  modelId,
  engineering,
  id,
  post,
}: {
  model: string;
  modelId: string;
  engineering?: boolean;
  id?: string;
  post?: boolean;
}) {
  const { data, isLoading, mutate } = useSWRImmutable<photoType[]>(`/photo/${model}/${modelId}`);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [refresh, setRefresh] = useState(0);
  const [open, setOpen] = useState(false);
  const [editingPhoto, setEditingPhoto] = useState<photoType | null>(null);
  const [viewPhoto, setViewPhoto] = useState<string | null>(null);

  const newColumn = [
    {
      field: "action",
      headerName: "",
      type: "action",
      minWidth: 200,
      cellRenderer({ data }: any) {
        return (
          <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
            {!engineering && (
              <IconButton
                sx={{ width: 30, height: 30 }}
                onClick={() => {
                  setEditingPhoto(data);
                  setOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            )}

            <IconButton
              sx={{ width: 25, height: 25 }}
              title="Delete"
              onClick={() => {
                Confirm({
                  text: "Are you sure you want to delete this photo?",
                  async onConfirm() {
                    try {
                      await delete_(`/photo/${getId(data)}`);
                      Toast("Photo deleted successfully", "success");
                      mutate();
                      setRefresh((p) => p + 1);
                    } catch (error) {
                      console.log(error);
                      Toast("Failed to delete photo", "error");
                    }
                  },
                });
              }}
            >
              <Delete />
            </IconButton>
            <Button onClick={() => setViewPhoto(data?.path?.includes("http") ? data?.path : host + data?.path)}>
              view photo
            </Button>
          </Box>
        );
      },
    },
    {
      field: "name",
      minWidth: 300,
      headerName: post ? "Link" : "Title",
      cellRenderer: ({ data }: any) => data?.name || data.url,
    },
    {
      field: "description",
      minWidth: 200,
      flex: 1,
      headerName: "Description",
    },
  ];

  // Photo View Modal
  const PhotoViewModal = () => (
    <MyDialog title="Photo View" open={!!viewPhoto} onClose={() => setViewPhoto(null)} maxWidth="md" fullWidth>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "500px",
        }}
      >
        <img
          src={viewPhoto || ""}
          alt=""
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
    </MyDialog>
  );

  return (
    <>
      {/* Photo View Modal */}
      {viewPhoto && <PhotoViewModal />}

      {/* Add/Edit Photo Modal */}
      {open && (
        <MyDialog
          title={editingPhoto ? "Edit Photo" : "Add Photo"}
          open={open}
          onClose={() => {
            setOpen(false);
            setEditingPhoto(null);
          }}
          maxWidth="xs"
          fullWidth
        >
          <Formik
            initialValues={{
              name: model.includes("problem")
                ? "Problem"
                : model.includes("solution")
                ? "Solution"
                : editingPhoto?.name || "",
              description: editingPhoto?.description || "",
              photo: null as File | null,
            }}
            onSubmit={async (data) => {
              try {
                if (editingPhoto) {
                  await updatePhoto({
                    id: editingPhoto?.id,
                    url: post ? data?.name : undefined,
                    name: post ? undefined : data.name || undefined,
                    description: data.description || undefined,
                    photo: data.photo || undefined,
                  });
                  Toast("Photo updated successfully", "success");
                  mutate();
                  setRefresh((p) => p + 1);
                } else {
                  // Add new photo
                  if (!data.photo) {
                    Toast("Please select a photo", "error");
                    return;
                  }
                  await addPhoto({
                    model,
                    id: modelId,
                    url: post ? data?.name : undefined,
                    name: post ? undefined : data.name || undefined,
                    description: data.description || undefined,
                    photo: data.photo,
                  });
                  Toast("Photo added successfully", "success");
                }

                setOpen(false);
                setEditingPhoto(null);
                mutate();
                setRefresh((p) => p + 1);
              } catch (error) {
                console.log(error);
                Toast("Failed to process photo", "error");
              }
            }}
          >
            {({ values, setFieldValue, getFieldProps }) => (
              <Form>
                <Box display="flex" flexDirection="column" gap={2}>
                  <TextField
                    label={post ? "Link" : "Title"}
                    {...getFieldProps("name")}
                    disabled={model.includes("problem") || model.includes("solution")}
                  />
                  <TextField label="Description" {...getFieldProps("description")} multiline rows={6} />
                  <input
                    hidden
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      setFieldValue("photo", e.target.files?.[0]);
                    }}
                  />

                  {editingPhoto && (
                    <img
                      src={getDocumentUrl(editingPhoto?.path, true)}
                      alt=""
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  {!editingPhoto && (
                    <Button variant="outlined" onClick={() => fileInputRef.current?.click()}>
                      {editingPhoto ? "Change Photo" : "Upload"}
                    </Button>
                  )}
                  {values.photo ? (
                    <Box sx={{}}>
                      {" "}
                      <img
                        src={typeof values.photo === "string" ? host + values.photo : URL.createObjectURL(values.photo)}
                        alt=""
                      />
                      <Box sx={{ display: "flex" }}>
                        <Typography>{values.photo.name}</Typography>{" "}
                        <IconButton
                          onClick={() => {
                            setFieldValue("photo", undefined);
                          }}
                          sx={{ height: "20px", px: 2, width: "20px" }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Button variant="contained" type="submit">
                    {editingPhoto ? "Update" : "Submit"}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </MyDialog>
      )}

      <Button variant="outlined" onClick={() => setOpen(true)} sx={{ mb: 2, width: "150px" }}>
        add
      </Button>
      <Box>
        <DataTable
          height="calc(100vh - 270px)"
          key={refresh}
          rowHeight={35}
          url={`/photo/${model}/${modelId}`}
          columns={newColumn}
          id={id || "webSite-Photo"}
        />
      </Box>
    </>
  );
}
