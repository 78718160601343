import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import FileUploader from "app/UploadButton";
import { post } from "api";
import { toast } from "react-toastify";
import useSWR from "swr";
import { host } from "host";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Clear, Delete } from "@mui/icons-material";
import { deleteAModelDocument } from "api/document";
import Toast from "app/Toast";

export default function HomePage() {
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, isLoading, mutate } = useSWR(`/document/website/homepage-1`);

  const handleDeletVideo = async (id: string) => {
    try {
      await deleteAModelDocument(id);
      Toast("deleted successfully");
      mutate();
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleUploadVideo = () => {
    if (file) {
      setIsSubmitting(true);
      const data = new FormData();
      data.append("document", file);
      post(`/document/website/homepage-1`, data)
        .then(() => toast.success("video uploaded successfully"))

        .finally(() => {
          mutate();
          setIsSubmitting(false);
        });
    } else {
      console.error("No file selected");
    }
  };
  const handleVideoUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              width: "calc(100vw - 100px)",
              height: "calc(100vh - 150px)",
              display: "flex",
              flexDirection: "column",
              borderRadius: "1rem",
              border: "1px solid gray",
              p: "1.5rem",
              mt: "0.5rem",
              mx: "2rem",
            }}
          >
            <Typography
              sx={{
                mt: "-2.4rem",
                fontSize: "15px",
                fontWeight: 700,
                bgcolor: "#FFF",
                width: "11rem",
                mb: "1rem",
                pl: "0.4rem",
              }}
            >
              Home Page Video
            </Typography>
            <Swiper
              modules={[Navigation, Pagination]}
              navigation={true}
              pagination={true}
              style={{ width: "100%", height: "100%" }}
              slidesPerView={1}
            >
              {data?.result?.map((i: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box>
                      <Box sx={{ position: "relative" }}>
                        <SwiperSlide>
                          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                color: "white",
                                zIndex: 10,
                                backgroundColor: "GrayText",
                                // Optional: add background to make it more visible
                                "&:hover": {
                                  bgcolor: "lightgray", // Optional: add hover effect
                                },
                              }}
                              title="Remove File"
                              onClick={() => handleDeletVideo(i?.id)}
                            >
                              <Clear />
                            </IconButton>
                            <video
                              controls
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            >
                              <source src={host + i?.path} type="video/mp4" />
                            </video>
                          </Box>
                        </SwiperSlide>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Swiper>

            <Box sx={{ display: "flex", mt: "2rem", width: "20rem" }}>
              <FileUploader
                onChange={handleVideoUpload}
                onError={(err) => toast.error(err)}
                allowedTypes={["video"]}
                maxSize={5}
                title={data?.result?.length !== 0 ? "choose new video" : "choose video"}
              />
            </Box>
            <Button
              disabled={!file || isSubmitting}
              onClick={handleUploadVideo}
              sx={{ width: "20rem", mt: "2rem" }}
              variant="contained"
            >
              {isSubmitting ? "Uploading..." : "Upload"}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
