import React from "react";
import plus from "assets/icons/Plus.svg";
import edit from "assets/icons/Edit.svg";
import duplicate from "assets/icons/Duplicate.svg";
import revise from "assets/icons/revise.svg";
import deleteIcon from "assets/icons/Delete.svg";
import download from "assets/icons/Download.svg";
import leadtime from "assets/icons/leadicon.svg";
import { ReactComponent as NarrowIcon } from "assets/icons/tableIcons/narrowDown.svg";

export const Plus = ({ sx }: { sx?: any }) => {
  return <img alt="" src={plus} style={{ width: 18, height: 18, ...sx }} />;
};

export const Edit = ({ sx }: { sx?: any }) => {
  return <img alt="" src={edit} style={{ width: 18, height: 18, ...sx }} />;
};

export const Duplicate = ({ sx }: { sx?: any }) => {
  return <img alt="" src={duplicate} style={{ width: 22, height: 22, ...sx }} />;
};

export const Revise = ({ sx }: { sx?: any }) => {
  return <img alt="" src={revise} style={{ width: 20, height: 20, ...sx }} />;
};

export const Delete = ({ sx }: { sx?: any }) => {
  return <img alt="" src={deleteIcon} style={{ width: 20, height: 20, ...sx }} />;
};

export const Download = ({ sx }: { sx?: any }) => {
  return <img alt="" src={download} style={{ width: 18, height: 18, ...sx }} />;
};

export const DetialIcon = ({ sx }: { sx?: any }) => {
  return <NarrowIcon style={{ width: 12, height: 12, ...sx }} />;
};
export const LeadTimeIcon = ({ sx }: { sx?: any }) => {
  return <img alt="" src={leadtime} style={{ ...sx }} />;
};
