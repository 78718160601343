import React, { useState } from "react";
import { Dialog, Box, IconButton, useMediaQuery, LinearProgress } from "@mui/material";
import Close from "@mui/icons-material/Close";

import { host } from "host";
import { photoType } from "api/photo";
import ImageZoom from "components/ImageZoom";

export default function ImageSlider({
  images,
  open,
  onClose,
}: {
  images: photoType[];
  open: boolean;
  onClose: () => void;
}) {
  const phone = useMediaQuery("(max-width:550px)");
  const [img, setImg] = useState(0);
  const [loading, setLoading] = useState(true);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" fullScreen={phone}>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={2}>
        <Box width="100%" textAlign="right" mb={1}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        {loading && (
          <Box width={100}>
            <LinearProgress />
          </Box>
        )}
        <ImageZoom
          height={500}
          src={"https://erp.phocuss.com" + images[img]?.path}
          onNext={() => {
            setLoading(true);
            setImg((img + 1) % images.length);
          }}
          onPrevious={() => {
            setLoading(true);
            setImg(Math.abs((img - 1) % images.length));
          }}
          onImageLoad={() => setLoading(false)}
        />
      </Box>
    </Dialog>
  );
}
