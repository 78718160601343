import { delete_, get, patch, post } from "api";
import { getId } from "logic/utils";
import { photoType } from "./photo";

export type IInsCluster = {
  id: string;
  typeName: string;
  title: string;
  subTitle: string;
  content: string;
  contentType: string;
  step: string;
  args: { key: string; unit: string; min: number; max: number; exact: string }[];
  checkBoxes: { title: string; requiredValue: boolean }[];
  photos: string[];
  attachments: string[];
};

export const createInstruction = async (
  data: Partial<IInsCluster> & { context?: string; ClusterId?: string; ItemId?: string }
) => {
  const url = data.ItemId ? `/instruction2/device/${getId(data.ItemId)}` : "/instruction2";

  if (data?.attachments?.length || data?.photos?.length) {
    const photosFormData = new FormData();
    if (data.photos && data.photos.length > 0) {
      data.photos.forEach((p) => photosFormData.append("photo", p));
    }

    const attachmentsFormData = new FormData();
    if (data.attachments && data.attachments.length > 0) {
      data.attachments.forEach((p) => attachmentsFormData.append("document", p));
    }

    const r = await post(url, {
      ClusterId: data.ClusterId || undefined,
      ItemId: data.ItemId || undefined,

      context: data.context || undefined,
      title: data.title || undefined,
      content: data.content || undefined,
      step: data.step || undefined,
      contentType: data.contentType || undefined,
      subTitle: data.subTitle || undefined,
      typeName: data.typeName || undefined,

      args: data.args || undefined,
      checkBoxes: data.checkBoxes || undefined,
    });
    if (getId(r) && data.photos && data.photos.length > 0) {
      await post(`/photo/instruction/${getId(r)}`, photosFormData);
    }
    if (getId(r) && data.attachments && data.attachments.length > 0) {
      await post(`/document/instruction/${getId(r)}`, attachmentsFormData);
    }
    return r;
  }

  return post(url, {
    ClusterId: data.ClusterId || undefined,
    ItemId: data.ItemId || undefined,

    context: data.context || undefined,
    title: data.title || undefined,
    content: data.content || undefined,
    step: data.step || undefined,
    contentType: data.contentType || undefined,
    subTitle: data.subTitle || undefined,
    args: data.args || undefined,
    checkBoxes: data.checkBoxes || undefined,
    typeName: data.typeName || undefined,
  });
};

export const updateInstruction = (id: string, data: Partial<IInsCluster> & { ClusterId?: string; ItemId?: string }) => {
  const url = data.ItemId ? `/instruction2/${id}/device/${getId(data.ItemId)}` : `/instruction2/${id}`;
  const promises: Promise<any>[] = [];

  if (data?.photos && data.photos.length > 0) {
    const formData = new FormData();
    data.photos.forEach((p) => formData.append("photo", p));
    promises.push(post(`/photo/instruction/${id}`, formData));
  }

  if (data.attachments && data.attachments.length > 0) {
    const formData = new FormData();
    data.attachments.forEach((p) => formData.append("document", p));
    promises.push(post(`/document/instruction/${id}`, formData));
  }

  promises.push(
    patch(url, {
      ClusterId: data.ClusterId || undefined,
      ItemId: data.ItemId || undefined,

      typeName: data.typeName || "",
      title: data.title || undefined,
      content: data.content || undefined,
      step: data.step || undefined,
      contentType: data.contentType || undefined,
      subTitle: data.subTitle || undefined,

      args: data.args || undefined,
      checkBoxes: data.checkBoxes || undefined,
    })
  );

  return Promise.all(promises);
};

export const applyClusterInstructions = (processId: string) => {
  return get(`/instruction/process/${processId}/apply`);
};

export type instructionArgType = { key: any; unit: string; min: string; max: string; exact: any };
export type instructionCheckboxType = { title: string; requiredValue: boolean; selected?: boolean };

export type instructionType = {
  TestingInstructionId: string;
  QualityControlId: string;
  ManufacturingId: string;
  _id?: string;
  id: string;
  ClusterId: string;
  cluster: string;
  AssigneeId: any;
  assigneeName: any;
  endTime: any;
  name: any;
  startTime: any;
  // photo: boolean | string[];
  // attachment: boolean | string[];
  contentType: string;
  subTitle: string;

  title: string;
  content: string;
  photos: string[];
  attachments: string[];
  step: string;
  args?: instructionArgType[];
  checkBoxes?: instructionCheckboxType[];
};

export type variantType = "manufacturing" | "startup" | "testing" | "qualitycontrol";

export function getApi(variant: variantType) {
  switch (variant) {
    case "manufacturing":
      return "manufacturing";
    case "startup":
      return "startupinstruction";
    case "testing":
      return "testinginstruction";
    case "qualitycontrol":
      return "qualitycontrol";
    default:
      throw new Error("Unknown variant");
  }
}

export const updateStep = (id: string, data: { cancel: boolean }) => {
  return patch(`/step/${id}`, data);
};

export type duplicateType = "manufacturing" | "startup" | "testing" | "qualitycontrol";

export function duplicateTests(
  data: { origin: string | string[]; destination: string; selectedSteps: string[] },
  variant: duplicateType
) {
  switch (variant) {
    case "manufacturing":
      return post("manufacturing/duplicate", data);
    case "startup":
      return post("startupinstruction/duplicate", data);
    case "testing":
      return post("testinginstruction/duplicate", data);
    case "qualitycontrol":
      return post("qualitycontrol/duplicate", data);
    default:
      throw new Error("Unknown variant");
  }
}

export async function saveProcedureStep(
  variant: "Internal Startup" | "Manufacturing" | "Quality Control" | "QualityControl" | "Test" | "Startup",
  body: {
    UnitId: string;
    step: string;
    stepId: string;
    TestingInstructionId?: string;
    QualityControlId?: string;
    serial?: string;
    photo?: File;
    args: any;
    attachments?: File;
    checkBoxes: any;
  }
) {
  const formData = new FormData();

  body.photo && formData.append("photo", body.photo);

  const resp = await patch(`/step/${getId(body.stepId)}?processType=${variant}`, { ...body, photo: undefined });

  if (body.photo) {
    await post<photoType[]>(`/photo/${variant}/${resp._id || resp.id}`, formData);
  }
  return new Promise<{ msg: string }>((resolve) => resolve({ msg: "done" }));
}

export const getPhoto = (id: any, variant: variantType) => {
  return get(`/photo/${getApi(variant)}/${id}`);
};

export const deleteImage = (id: string) => {
  return delete_(`/photo/${id}`);
};

export const deleteDocument = (id: string) => {
  return delete_(`/document/${id}`);
};

export const deleteTestInstruction = (id: string, variant: variantType) => {
  return delete_(`/${getApi(variant)}/${id}`);
};

export const uploadInstructionExcel = (data: {
  section: string;
  processType: string;
  clusterNames: string[];
  file: File;
}) => {
  const formData = new FormData();

  formData.append("section", data.section);
  formData.append("processType", data.processType);
  formData.append("clusterNames", JSON.stringify(data.clusterNames));
  formData.append("document", data.file);

  return post("/document/upload/cluster/instruction", formData);
};

export const pasteInstructions = (data: {
  sourceClusterId?: string;
  destinationClusterId?: string;
  InstructionIds?: string[];
  sourceDeviceId?: string;
  destinationDeviceId?: string;
}) => {
  return post(`/instruction2/copy`, data);
};

export const pasteSteps = (data: { sourceUnitId?: string; destinationUnitId: string; StepIds?: string[] }) => {
  return post(`/step/copy`, data);
};

export const pasteInstructionToSteps = (data: { unitId?: string; context?: string; StepIds?: string[] }) => {
  return post(`/instruction2/copy/unit/${data.unitId}`, data);
};
