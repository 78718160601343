import React, { useState } from "react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import useSWR from "swr";
import Checked from "modules/Engineering/Instruction/Checked";
import InvoicePdfModal from "modules/Accounting/Invoice/PdfModal";
import { INote } from "api/note";
import { IInvoice } from "api/invoice";
import ReceivePdfModal from "modules/Inventory/ReceivePdf";
import POPdf from "modules/Purchase/POPdf";
import TravelerPdfModal from "common/Traveler/Pdf";
import Button from "app/Button";
import DocumentModal from "./Modal";
import { useLock } from "../Lock";
import DocumentTable from "./Table";
import QuotePdfModal from "modules/Sales/QuotePdfModal";
import SOPdfDialog from "modules/Sales/SOPdf";
import IssuePdfDialog from "modules/Inventory/Issues/IssuePdfModal";
import ShippingPdfModal from "modules/Shipping/ShippingPdfModal";
import ShippingLabelPdfModal from "modules/Shipping/LabelPdfModal";
import OldPurchasePOPdf from "modules/Purchase/OldPurchasePoPdf";
import FieldServicePOPdf from "modules/Purchase/FieldServicePdf";

export default function DocumentTab({
  itemId,
  model,
  url,
  showAdd,
  downloadAs,
  controlledRefresh,
  unitCheck,
  traveler,
  invoice,
  values,
  receive,
  po,
  ticket,
  quote,
  sales,
  shipping,
  id,

  fieldService,
}: {
  model: string;
  itemId: string;
  url?: any;
  showAdd?: boolean;
  downloadAs?: string;
  controlledRefresh?: number;
  unitCheck?: boolean;
  traveler?: boolean;
  invoice?: boolean;
  values?: IInvoice;
  receive?: boolean;
  po?: boolean;
  ticket?: boolean;
  quote?: boolean;
  sales?: boolean;
  shipping?: boolean;
  id?: string;

  fieldService?: boolean;
}) {
  const { data: receiveDocuments, mutate: mutateReceiveDocuments } = useSWR<any>(
    receive ? `/document/receive/${itemId}` : null
  );
  const { data: poDocuments, mutate: mutatePoDocuments } = useSWR<any>(po ? `/document/po/${itemId}` : null);
  const { data: invoiceDocuments, mutate: mutateInvoiceDocuments } = useSWR<any>(
    invoice ? `/document/invoice/${itemId}` : null
  );
  const { data: issueDocuments, mutate: mutateIssueDocuments } = useSWR<any>(
    model === "issue" ? `/document/issue/${itemId}` : null
  );

  const { lock } = useLock();
  const [addModal, setAddModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [selected, setSelected] = useState<INote>();
  const [refresh, setRefresh] = useState(0);
  const [travelerModal, setTravelerModal] = useState(false);
  const [receiveModal, setReceiveModal] = useState(false);
  const [poModal, setPoModal] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);
  const [soModal, setSoModal] = useState(false);
  const [issueModal, setIssueModal] = useState(false);

  const [shippingModal, setShippingModal] = useState(false);
  const [shippingLabel, setShippingLabel] = useState(false);

  const phone = useMediaQuery("(max-width:900px)");

  return (
    <>
      {invoice && (
        <InvoicePdfModal
          invoice={values as IInvoice}
          open={pdfModal}
          onClose={() => setPdfModal(false)}
          onDone={() => {
            setRefresh((p) => p + 1);
            mutateInvoiceDocuments();
          }}
        />
      )}
      {receive && (
        <ReceivePdfModal
          receiveId={itemId}
          open={receiveModal}
          onClose={() => setReceiveModal(false)}
          onDone={() => {
            setRefresh((p) => p + 1);
            mutateReceiveDocuments();
          }}
        />
      )}
      {po && !fieldService && (
        <OldPurchasePOPdf
          POId={itemId}
          open={poModal}
          onClose={() => setPoModal(false)}
          onDone={() => {
            setRefresh((p) => p + 1);
            mutatePoDocuments();
          }}
        />
      )}
      {po && fieldService && (
        <FieldServicePOPdf
          POId={itemId}
          open={poModal}
          onClose={() => setPoModal(false)}
          onDone={() => {
            setRefresh((p) => p + 1);
            mutatePoDocuments();
          }}
        />
      )}
      {quote && (
        <QuotePdfModal
          quoteId={itemId}
          open={quoteModal}
          onClose={() => setQuoteModal(false)}
          onDone={() => {
            setRefresh((p) => p + 1);
          }}
        />
      )}
      {model === "so" && soModal && (
        <SOPdfDialog
          soId={itemId}
          open={soModal}
          onClose={() => setSoModal(false)}
          onDone={() => {
            setRefresh((p) => p + 1);
            setSoModal(false);
          }}
        />
      )}
      {model === "issue" && (
        <IssuePdfDialog
          id={itemId}
          open={issueModal}
          onClose={() => setIssueModal(false)}
          onDone={() => {
            setRefresh((p) => p + 1);
            mutateIssueDocuments();
            setIssueModal(false);
          }}
        />
      )}
      <DocumentModal
        open={addModal}
        onClose={() => {
          setAddModal(false);
          setSelected(undefined);
        }}
        model={model}
        itemId={itemId}
        data={selected}
        setRefresh={setRefresh}
        sales={sales}
      />
      {travelerModal && (
        <TravelerPdfModal
          open={travelerModal}
          onClose={() => setTravelerModal(false)}
          unitId={itemId}
          onDone={() => setRefresh((p) => p + 1)}
        />
      )}

      <ShippingPdfModal shippingId={itemId} open={shippingModal} onClose={() => setShippingModal(false)} />
      <ShippingLabelPdfModal shippingId={itemId} open={shippingLabel} onClose={() => setShippingLabel(false)} />

      <Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          {showAdd !== false && (
            <Box
              width="100%"
              justifyContent="space-between"
              display="flex"
              alignItems="center"
              sx={{ justifyContent: { xs: "column", lg: "row" } }}
            >
              {!ticket && !shipping && (
                <Button onClick={() => setAddModal(true)} disabled={lock} variant="outlined">
                  ADD
                </Button>
              )}
              {shipping && (
                <Box
                  sx={
                    phone
                      ? {
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }
                      : {
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          gap: 1,
                        }
                  }
                >
                  <Button onClick={() => setAddModal(true)} disabled={lock} variant="outlined">
                    ADD
                  </Button>
                  <Button disabled={lock} variant="outlined" onClick={() => setShippingLabel(true)}>
                    Label
                  </Button>
                  <Button disabled={lock} variant="outlined" onClick={() => setShippingModal(true)}>
                    Packing Slip
                  </Button>
                </Box>
              )}
              {traveler && (
                <IconButton
                  style={{ margin: "4px 0" }}
                  onClick={() => setTravelerModal(true)}
                  disabled={lock}
                  title="Open Traveler PDF Modal"
                >
                  <TravelExploreIcon />
                </IconButton>
              )}
              {invoice && (
                <IconButton onClick={() => setPdfModal(true)} title="Generate pdf">
                  <DocumentScannerIcon />
                </IconButton>
              )}
              {unitCheck && model === "unit-crosscheck" && (
                <Checked itemId={itemId} model="unit" variant="cross" sx={{ mx: 2 }} />
              )}
              {receive &&
                (receiveDocuments?.length < 1 ||
                  receiveDocuments?.result?.length < 1 ||
                  receiveDocuments?.total < 1) && (
                  <Button variant="outlined" sx={{ ml: 2, mr: "auto" }} onClick={() => setReceiveModal(true)}>
                    PDF
                  </Button>
                )}
              {po && (
                <Button variant="outlined" sx={{ ml: 2, mr: "auto" }} onClick={() => setPoModal(true)}>
                  PDF
                </Button>
              )}
              {quote && (
                <Button variant="outlined" sx={{ ml: 2, mr: "auto" }} onClick={() => setQuoteModal(true)}>
                  PDF
                </Button>
              )}
              {model === "so" && (
                <Button variant="outlined" sx={{ ml: 2, mr: "auto" }} onClick={() => setSoModal(true)}>
                  PDF
                </Button>
              )}
              {model === "issue" &&
                (issueDocuments?.length < 1 || issueDocuments?.result?.length < 1 || issueDocuments?.total < 1) && (
                  <Button variant="outlined" sx={{ ml: 2, mr: "auto" }} onClick={() => setIssueModal(true)}>
                    PDF
                  </Button>
                )}
            </Box>
          )}
        </Box>
        <DocumentTable
          url={url}
          model={model}
          itemId={itemId}
          downloadAs={downloadAs}
          refresh={controlledRefresh || refresh}
          setAddModal={setAddModal}
          setRefresh={setRefresh}
          setSelected={setSelected}
          ticket={ticket}
        />
      </Box>
    </>
  );
}
