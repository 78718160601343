import Footer from "./footer/Footer";
import Header from "../PO/header/Header";

export default function QuotePage12({ data, pageNumber }) {
  return (
    <div className="relative flex flex-col items-center h-full w-[98%] mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div style={{ position: "absolute", left: 0, top: "25cm", width: "20.5cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    b: "The decision of the arbitrator shall be considered as a final and binding resolution of the disagreement which may be entered as a judgment by any court of competent jurisdiction.",
    c: "The arbitration shall be held in Los Angeles County, California.",
    d: "Neither party shall sue the other except for enforcement of the arbitrator's decision. All disagreements or controversies of any kind whether claimed in tort, contract or otherwise, either concerning this agreement or any other matter whatsoever, shall be arbitrated according to the provisions of this paragraph and shall be brought within one (1) year after the accrual of the disagreement or controversy.",
    Modifications:
      "No addition to or deletion from, nor any modifications of these terms and conditions of sale shall be binding upon the Seller unless acknowledged and accepted in writing by an officer of the Seller. Any change made by Seller will be deemed accepted by Buyer unless within ten (10) days from notice of such change. Buyer notifies Seller of Buyer's exception to such change. A waiver by Seller of any default or of any of the terms and conditions of sales shall not be deemed to be continuing waiver of any other default or of any other of these terms and conditions of sale, but shall apply solely to the instance to which the waiver is directed.",
  };
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <div>
              <div className="flex gap-1">
                <span className="text-primaryOrange text-[16px]">A.</span>
                <span className="font-light text-[16px] text-justify">{texts.a}</span>
              </div>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">B.</span>
              <span className="text-[16px] font-light text-justify">{texts.b}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">C.</span>
              <span className="text-[16px] font-light text-justify">{texts.c}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">D.</span>
              <span className="text-[16px] font-light text-justify">{texts.d}</span>
            </div>
            <div className="mt-[4%]">
              <div className="flex flex-col">
                <span className="text-[16px] font-bold">Modifications:</span>
                <span className="text-[16px] font-light text-justify">{texts.Modifications}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
