import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, LinearProgress } from "@mui/material";
import useSWR from "swr";

import { IPO, POLineItem, uploadPODocument } from "api/po";
import { IVendor } from "api/vendor";
import MyDialog from "app/Dialog";
import Toast from "app/Toast";

import PurchasePoOld from "PDFTemplates/PurchasePoOld/2";
import jsPDF from "jspdf";
import { addElementToPdf } from "logic/pdf";

export default function OldPurchasePOPdf({
  open,
  POId,
  onClose,
  onDone,
}: {
  open: boolean;
  POId: string;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data: po } = useSWR<IPO>(open && POId ? `/po/${POId}` : null);
  const { data: poLineItems } = useSWR<{ result: POLineItem[]; total: number }>(
    open && POId ? `/polineitem?POId=${POId}` : null
  );

  const { data: vendor } = useSWR<IVendor>(po?.VendorId ? `/vendor/${po?.VendorId}` : null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const headerRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = useCallback(
    async (download?: boolean) => {
      try {
        if (!po || !poLineItems || !vendor || uploading) {
          return;
        }
        if (!headerRef.current) {
          return;
        }
        const pdf = new jsPDF();

        await addElementToPdf({
          pdf,
          element: headerRef.current,
          x: 0,
          y: 0,
          scale: 3,
        });

        if (download) {
          pdf.save(`PO_${po.number}.pdf`);
        }
        return pdf.output("blob");
      } catch (error) {
        console.log(error);
      }
    },
    [po, poLineItems, uploading, vendor]
  );

  useEffect(() => {
    const upload = async () => {
      try {
        setUploading(true);
        const pdf = await handleDownload(true);
        if (!pdf) {
          return;
        }

        await uploadPODocument({
          blob: pdf,
          POId,
          poNumber: po?.number,
        });

        Toast("PO document uploaded", "success");

        onDone && onDone();
        onClose();
        setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
      }
    };

    let t = setTimeout(() => {
      if (!uploaded && open && !uploading) {
        upload();
      }
    }, 1000);

    return () => clearTimeout(t);
  }, [POId, handleDownload, onClose, onDone, open, po, uploaded, uploading, vendor]);

  return (
    <MyDialog title="PO pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth disabled={uploading}>
      <Box sx={{ height: "80vh", overflowY: "auto" }}>
        <Button disabled={uploading} sx={{ ml: 3, mb: 2 }} variant="contained" onClick={() => handleDownload(true)}>
          {uploading ? "Uploading" : "Download"}
        </Button>
        {po ? (
          <div style={{ width: "21cm", height: "29cm", border: "1px solid", margin: "auto" }}>
            <PurchasePoOld ref={headerRef} po={po} vendor={vendor} />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Box>
    </MyDialog>
  );
}
