import Footer from "./footer/Footer";
import Header from "./header/Header";
import SignatureComponent from "./SignatureComponent";
export default function POpage7({ data }) {
  return (
    <div className="relative flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={7} DocNumber={data?.number} />
      </div>
    </div>
  );
}

function Content() {
  const batteryData = Array.from({ length: 68 }, (_, index) => {
    return {
      BatteryNumber: index + 1,
      NoLoad: "",
      Load: "",
    };
  });
  // Calculate the midpoint
  const midpoint = Math.ceil(batteryData.length / 2);

  // Split the array into two halves
  const batteryTable1 = batteryData.slice(0, midpoint);
  const batteryTable2 = batteryData.slice(midpoint);

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div>
          <table className="min-w-full border-collapse">
            <tbody>
              <tr>
                <td className="border border-table p-[1%] w-1/2">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Model No.:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">&#8203;</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Serial No.:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">&#8203;</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <div className="flex flex-row flex-1">
            <table className="w-1/2 border-collapse">
              <thead>
                <tr>
                  <th className="border border-table text-start p-[2%] bg-[#f6f6f6] w-[28%]">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Battery No.</span>
                    </div>
                  </th>

                  <th className=" border-l-0 border border-table text-start p-[2%] w-[36%]">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">No Load</span>
                    </div>
                  </th>
                  <th className="border-l-0 border border-table text-start p-[2%] w-[36%]">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Load</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {batteryTable1?.map((row) => (
                  <tr key={row.BatteryNumber}>
                    <td className="border-t-0 border border-table border-spacing-0 w-[30%] align-text-top bg-[#f6f6f6]">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-light">{row.BatteryNumber}</span>
                      </div>
                    </td>
                    <td className="border-l-0 border-t-0 border border-table  border-spacing-0 w-[35%]]">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-light">{row.NoLoad}</span>
                      </div>
                    </td>
                    <td className="border-l-0 border-t-0 border border-table  border-spacing-0 w-[35%]">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-light">{row.Load}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="w-1/2 border-collapse border-l-0">
              <thead>
                <tr>
                  <th className="border-l-0 border border-table text-start p-[2%] bg-[#f6f6f6] w-[28%]">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Battery No.</span>
                    </div>
                  </th>

                  <th className="border-l-0 border border-table text-start p-[2%] w-[36%]">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">No Load</span>
                    </div>
                  </th>
                  <th className="border-l-0 border border-table text-start p-[2%] w-[36%]">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Load</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {batteryTable2?.map((row) => (
                  <tr key={row.BatteryNumber}>
                    <td className="border-l-0 border-t-0 border border-table border-spacing-0 text-top w-[30%] bg-[#f6f6f6]">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-light">{row.BatteryNumber}</span>
                      </div>
                    </td>
                    <td className="border-l-0 border-t-0 border border-table border-spacing-0 w-[35%]">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-light">{row.NoLoad}</span>
                      </div>
                    </td>
                    <td className="border-l-0 border-t-0 border border-table border-spacing-0 w-[35%]">
                      <div className="flex flex-col">
                        <span className="text-[10px] font-light">{row.Load}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-[2%]">
          <SignatureComponent />
        </div>
      </div>
    </div>
  );
}
