import { RestartAlt, SkipNext, SkipPrevious, ZoomIn, ZoomOut } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function ImageZoom({
  src,
  alt,
  height,
  onNext,
  onPrevious,
  onImageLoad,
}: {
  src: string;
  alt?: string;
  height?: number | string;
  onNext: () => void;
  onPrevious: () => void;
  onImageLoad?: () => void;
}) {
  return (
    <TransformWrapper initialScale={1} centerOnInit initialPositionX={0} initialPositionY={0}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <div className="tools">
            <IconButton onClick={() => zoomIn()}>
              <ZoomIn />
            </IconButton>
            <IconButton onClick={() => zoomOut()}>
              <ZoomOut />
            </IconButton>
            <IconButton onClick={() => resetTransform()} sx={{ mr: 4 }}>
              <RestartAlt />
            </IconButton>
            <IconButton onClick={onPrevious}>
              <SkipPrevious />
            </IconButton>
            <IconButton onClick={onNext}>
              <SkipNext />
            </IconButton>
          </div>
          <TransformComponent
            wrapperStyle={{
              width: 700,
              height,
            }}
          >
            <img src={src} alt={alt} style={{ width: "100%", height }} onLoad={onImageLoad} />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
}
