import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { styled, Drawer, Box, Avatar } from "@mui/material";
import { CloseRounded, SupportAgentRounded } from "@mui/icons-material";
import MarkdownPreview from "@uiw/react-markdown-preview";

import { post } from "api";
import SimplePieChart from "./AI/Pie";

import style from "./styles.module.css";
import SimpleBarChart from "./AI/Bar";

const Loader = styled("div")`
  width: 28px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;

  @keyframes l3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }
`;

export default function AIInput() {
  const inputRef = React.useRef<any>(null);
  const [value, setValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [history, setHistory] = React.useState<
    {
      role: "user" | "assistant";
      message: string;
      data?: any;
      chart_type?: string;
    }[]
  >([]);

  const handleSendQuestion = async () => {
    try {
      if (value) {
        setIsLoading(true);
        const response = await post<{
          timestamp: string;
          message: string;
          data: any;
          chart_type: string;
        }>(`https://digital.phocuss.com/api/ai/completion/`, { history, message: value.replaceAll("assistant:", "") });

        setHistory((p) => [
          ...p,
          {
            role: "user",
            message: value,
          },
          {
            role: "assistant",
            data: response.data,
            message: response.message,
            chart_type: response.chart_type,
          },
        ]);
        setValue("");
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setHistory([]);
    setOpen(false);
  };

  return (
    <>
      <Drawer variant="temporary" open={open} onClose={handleClose}>
        <Box
          p={2}
          width={{ xs: "100vw", md: "40vw" }}
          height="100vh"
          sx={{ overflow: "auto" }}
          display="flex"
          flexDirection="column"
          gap={1}
          bgcolor="#252525"
        >
          <Box display="flex" justifyContent="end" onClick={handleClose}>
            <IconButton>
              <CloseRounded sx={{ color: "white" }} />
            </IconButton>
          </Box>
          <div style={{ flexGrow: 1 }}>
            {history.map((i, idx) => (
              <Paper
                key={idx}
                sx={{ bgcolor: "#404040", color: "white", p: 1, my: 2, display: "flex", alignItems: "center", gap: 2 }}
              >
                <Avatar sx={{ bgcolor: i.role === "user" ? "gray" : "primary.main", width: 24, height: 24 }}>
                  {i.role === "user" ? <></> : <SupportAgentRounded />}
                </Avatar>
                <div>
                  <MarkdownPreview source={i?.message || ""} style={{ padding: 16, background: "transparent" }} />
                  {i?.chart_type === "pie" ? (
                    <SimplePieChart
                      data={i.data.map((i: any) => ({
                        name: i.find((j: any) => typeof j === "string") || "None",
                        value: i.find((j: any) => typeof j === "number") || 0,
                      }))}
                    />
                  ) : i.chart_type === "bar" ? (
                    <SimpleBarChart
                      data={i.data.map((i: any) => ({
                        name: i.find((j: any) => typeof j === "string") || "None",
                        value: i.find((j: any) => typeof j === "number") || 0,
                      }))}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Paper>
            ))}
            {isLoading ? <Loader /> : <></>}
          </div>

          <Paper
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSendQuestion();
            }}
            className={isLoading ? style.boxContainer : ""}
            sx={{
              p: "2px 4px",
              borderRadius: "10px",
              height: 40,
              display: "flex",
              alignItems: "center",
              width: "100%",
              bgcolor: "rgb(64,64,64)",
              position: "relative",
            }}
            ref={inputRef}
          >
            <InputBase
              disabled={isLoading}
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Ask me anything..."
              inputProps={{ style: { backgroundColor: "rgb(64,64,64)", color: "white", fontSize: "12px" } }}
            />

            <IconButton
              onClick={handleSendQuestion}
              color="primary"
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <AutoAwesomeRoundedIcon />
            </IconButton>
          </Paper>
        </Box>
      </Drawer>
      <IconButton onClick={() => setOpen(true)} color="primary" type="button" sx={{ p: "10px" }} aria-label="search">
        <AutoAwesomeRoundedIcon />
      </IconButton>
    </>
  );
}
