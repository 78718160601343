import React, { useState, useEffect, CSSProperties } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Autocomplete, Box } from "@mui/material";
import { get } from "api";
import GoToButton from "./GoToButton";
import { getId } from "logic/utils";
import TextField from "app/TextField";
import { useHistory } from "react-router-dom";

const useValue = ({
  url,
  initial,
  nullable,
  multiple,
  fetchAlways,
}: {
  url: string;
  nullable?: boolean;
  multiple?: boolean;
  initial: string | any;
  fetchAlways?: boolean;
}) => {
  const [value, setValue] = useState(initial);

  useEffect(() => {
    if (fetchAlways || (initial && typeof initial === "string")) {
      get(`${url}/${initial}`)
        .then((d) => setValue(d))
        .catch((e) => console.log(e));
      return;
    }
    if (fetchAlways || (initial && Array.isArray(initial))) {
      let promises = initial
        ?.filter((i: string) => typeof i === "string")
        .map((i: string) =>
          get(`${url}/${i}`)
            .then((d) =>
              setValue((p: any[]) => {
                const temp = p.concat();
                const index = temp.findIndex((i) => i === getId(d));
                if (index !== -1) {
                  temp.splice(index, 1);
                }
                return [...temp, d];
              })
            )
            .catch((e) => console.log(e))
        );

      Promise.all(promises);
      return;
    }
    if (nullable && !initial) {
      setValue(null);
    }
    if (multiple && !initial) {
      setValue([]);
    }
  }, [fetchAlways, initial, multiple, nullable, url]);

  return [value, setValue];
};

export default function AsyncCombo({
  url,
  label,
  value,
  path,
  replacePath,
  error,
  style,
  size,
  filterBy,
  valueUrl,
  disabled,
  btnDisabled,
  defaultParams,
  placeholder,
  controlledValue,
  onChange,
  getOptionLabel,
  getOptionSelected = (o, v) => getId(o) === getId(v),
  defaultValue,
  required,
  fetchAlways,
  nullable,
  multiple,
  excludeOptions,
  allPath,
  onKeyDown,
  InputLabelProps,
  helperText,
  filterPrefix,
  height,
}: {
  replacePath?: string;
  height?: any;
  filterPrefix?: string;
  url: string;
  label?: string;
  path?: string;
  error?: boolean;
  value?: any | string;
  style?: CSSProperties;
  filterBy: string;
  valueUrl?: string;
  disabled?: boolean;
  btnDisabled?: boolean;
  defaultParams?: any;
  placeholder?: string;
  controlledValue?: any;
  size?: "small" | "medium";
  onChange?: (e: any, nv: any) => void;
  getOptionLabel: (o: any) => string;
  getOptionSelected?: (o: any, v: any) => boolean;
  defaultValue?: any;
  required?: boolean;
  fetchAlways?: boolean;
  nullable?: boolean;
  multiple?: boolean;
  excludeOptions?: string[];
  allPath?: string;
  onKeyDown?: any;
  InputLabelProps?: any;
  helperText?: any;
}) {
  const [selectedValue, setSelectedValue] = useValue({
    initial: value || controlledValue || (multiple ? [] : null),
    url: valueUrl || url,
    fetchAlways,
    nullable,
    multiple,
  });

  const [inputValue, setInputValue] = useState<string>();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let active = true;

    if (!open) {
      setOptions([]);
      return;
    }

    const t = setTimeout(async () => {
      try {
        setLoading(true);
        let response = [];

        if (inputValue) {
          response = await get(url, {
            params: { [`startsWith${filterBy}`]: (filterPrefix || "") + inputValue, ...defaultParams },
          });
        } else if (filterPrefix) {
          response = await get(url, {
            params: {
              [`startsWith${filterBy}`]: filterPrefix,
              ...defaultParams,
            },
          });
        } else {
          response = await get(url, { params: { ...defaultParams } });
        }

        let responseNormalized = response?.result || response?.results || response || [];
        if (!Array.isArray(responseNormalized)) {
          responseNormalized = [responseNormalized];
        }

        if (active) {
          if (excludeOptions) {
            setOptions(responseNormalized?.filter((i: any) => !excludeOptions.includes(getId(i))));
          } else {
            setOptions(responseNormalized);
          }
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 450);

    return () => {
      clearTimeout(t);
      active = false;
    };
  }, [defaultParams, excludeOptions, filterBy, filterPrefix, inputValue, open, url]);

  return (
    <Box style={style} sx={{ display: "flex !important", alignItems: "center" }}>
      <Autocomplete
        placeholder={placeholder}
        style={{ width: path ? "95%" : "100%", marginLeft: "auto" }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onKeyDown={onKeyDown}
        multiple={multiple}
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        autoComplete
        filterOptions={(x) => x}
        filterSelectedOptions
        defaultValue={defaultValue}
        value={controlledValue || selectedValue || (multiple ? [] : null)}
        onChange={(e, nv) => {
          setSelectedValue && setSelectedValue(nv);
          onChange && onChange(e, nv);
        }}
        onInputChange={(e, nv) => {
          setInputValue(nv);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            label={label}
            required={required}
            error={error}
            helperText={helperText}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              style: { color: "black", fontSize: "10px", height: height ? height : "unset" },
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {path || allPath ? (
        <GoToButton
          href={allPath ? allPath : path + (selectedValue?.id || selectedValue)}
          disabled={btnDisabled || !selectedValue?.id}
        />
      ) : (
        replacePath && <GoToButton  disabled={btnDisabled} onClick={() => history.replace(replacePath)} />
      )}
    </Box>
  );
}
