import QuoteHeaderPage1 from "./header/QuoteHeaderPage1";
export default function QuotePage0({ title }) {
  return (
    <div className="relative flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <QuoteHeaderPage1 />
      </div>
      <div className="flex flex-1 w-full">
        <Content title={title} />
      </div>
    </div>
  );
}

function Content({ title }) {
  const mainText =
    "DSPM is committed to provide the highest quality products possible through our highly skilled and dedicated employees. Utilizing state- of-the-art manufacturing processes and patented products to pursue an aggressive, on-going research and development program. Insuring DSPM's future as a leader in Power Conversion products. DSPM provides the highest standards of service available through our highly trained representatives and customer service personnel. DSPM employees are expected to serve the customer with the highest level of technical knowledge in the industry";
  return (
    <div className="flex flex-col items-center h-[80%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div>
          <span className="text-[24px] font-bold text-primaryBlue">{title || "Quote"}</span>
        </div>
        <div className="mt-[2%]">
          <span className="font-light text-[16px]  text-justify" style={{ textAlign: "justify", display: "block" }}>
            {mainText}
          </span>
        </div>
        <div className="mt-[20%]">
          <div className="flex flex-col w-fit">
            <div className="flex-col flex">
              <span className="text-primaryBlue text-[16px]">DSPM:</span>
            </div>
            <div className="flex-col flex">
              <span className="text-[18px] text-primaryOrange font-bold">&quot;Power to Make a Difference&quot;</span>
            </div>
            <div className="flex-col flex justify-end items-end">
              <span className="text-primaryBlue text-[16px]">2024</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
