import React, { useState } from "react";
import { Box, CircularProgress, IconButton, useMediaQuery } from "@mui/material";
import useSWR from "swr";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { addPhoto, deletePhoto, photoType } from "api/photo";
import UploadButton from "app/UploadButton";
import Toast from "app/Toast";
import { useLock } from "common/Lock";
import ImageSlider from "./imageSlider";
import { Delete } from "app/IconPack";
import ConfirmModal from "features/Modals/Confirm";
import { getDocumentUrl } from "api/document";

export default function PhotoTab({ id, model, product }: { id: string; model: string; product?: boolean }) {
  const phone = useMediaQuery("(max-width:600px)");
  const { data: photos, mutate: mutatePhotos } = useSWR<{ result: photoType[] } | photoType[]>(`/photo/${model}/${id}`);
  const [img, setImg] = useState<any>();
  const [open, setOpen] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [photoId, setPhotoId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { lock } = useLock();

  const photosList = (photos as { result: photoType[] })?.result || photos || [];

  const handleFileChange = async (e: any) => {
    try {
      if (!e.target.files) {
        return;
      }
      let file = e.target.files[0];
      let url = URL.createObjectURL(file);
      setLoading(true);
      await addPhoto({ model, id, photo: file });
      setImg(url);
      setLoading(false);
      Toast("Photo uploaded", "success");
    } catch (error) {
      console.log(error);
    } finally {
      mutatePhotos();
    }
  };

  const handleDeletePhoto = async () => {
    try {
      await deletePhoto(photoId);
      Toast("Photo deleted", "success");
    } catch (error) {
      console.log(error);
    } finally {
      mutatePhotos();
      setDeleteOpen(false);
    }
  };

  return (
    <>
      {deleteOpen && (
        <ConfirmModal
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          onConfirm={handleDeletePhoto}
          text="a photo"
        />
      )}
      {photosList?.length > 0 && <ImageSlider open={open} onClose={() => setOpen(false)} images={photosList} />}
      <Box
        width={phone ? "70vw" : "unset"}
        mt={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <Box display="flex" justifyContent="center" alignItems="center" gap={2} width="100%" flexWrap={"wrap"}>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={true}
            pagination={true}
            style={{ width: 400, height: 250 }}
            slidesPerView={1}
          >
            {photosList?.map((photo) => (
              <SwiperSlide>
                <Box display="flex">
                  {!lock && (
                    <IconButton
                      onClick={() => {
                        setDeleteOpen(true);
                        setPhotoId(photo?.id);
                      }}
                      style={{ position: "absolute", background: "#dbdbdb", right: 0, padding: 4 }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                  <img
                    style={{
                      height: 250,
                      margin: "0px auto",
                    }}
                    alt=""
                    src={img || getDocumentUrl(photo.path, true)}
                    onClick={() => setOpen(true)}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          !lock && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginRight: "15px",
              }}
            >
              <UploadButton onChange={handleFileChange} accept="image/*" width={"200px"} />
            </div>
          )
        )}
      </Box>
    </>
  );
}
