import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { ChangeEvent, useMemo, useState } from "react";
import FileUploader from "app/UploadButton";
import { toast } from "react-toastify";
import { delete_, post } from "api";
import { PostType } from "api/magazine";
import DataGridAction from "common/DataGridAction";
import { Download } from "@mui/icons-material";
import { host } from "host";
import Confirm from "common/Confirm";
import { mutate } from "swr";
import Toast from "app/Toast";
import DataTable from "common/DataTable";

export default function PostDocumnets({ selectedPost }: { selectedPost: PostType }) {
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUploadFile = () => {
    if (file) {
      setIsSubmitting(true);
      const data = new FormData();
      data.append("document", file);
      post(`/document/file/${selectedPost?.id}`, data)
        .then(() => {
          toast.success("file uploaded successfully");
          mutate(`document/file/${selectedPost?.id}`);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      console.error("No file selected");
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const docColumns = useMemo(() => {
    let res: any[] = [
      {
        field: "action",
        type: "action",
        initialWidth: 30,
        cellRenderer({ data }: any) {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <div title="Download">
                <IconButton sx={{ p: 0 }}>
                  <a download={true} href={data?.path?.includes("http") ? data?.path : host + data?.path}>
                    <Download sx={{ width: 12, height: 12 }} />
                  </a>
                </IconButton>
              </div>
            </Box>
          );
        },
      },
      {
        field: "action",
        type: "action",
        initialWidth: 30,
        cellRenderer({ data }: any) {
          function setRefresh(arg0: (p: any) => any) {
            throw new Error("Function not implemented.");
          }

          return (
            <Box display="flex" gap={1} alignItems="center">
              <DataGridAction
                icon="delete"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Confirm({
                    text: "Are you sure you want to delete this document?",
                    async onConfirm() {
                      try {
                        await delete_(`/document/file/${data?.id}`);
                        mutate(`document/file/${selectedPost?.id}`);
                        Toast("Document deleted successfully", "success");
                        setRefresh((p) => p + 1);
                      } catch (error) {
                        console.log(error);
                      }
                    },
                  });
                }}
              />
            </Box>
          );
        },
      },
      { field: "createdAt", headerName: "Date", defaultOperator: "startsWith", type: "date", flex: 1 },
      {
        field: "fileName",
        headerName: "File Name",
        defaultOperator: "startsWith",
        flex: 1,
      },
    ];
    return res;
  }, [selectedPost?.id]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", width: "20rem" }}>
        <FileUploader
          onChange={handleFileUpload}
          onError={(err) => toast.error(err)}
          allowedTypes={["pdf", "excel"]}
          maxSize={5}
          title={"Add Document"}
        />
      </Box>
      <Button
        disabled={!file || isSubmitting}
        onClick={handleUploadFile}
        sx={{ width: "20rem", my: "1rem" }}
        variant="contained"
      >
        {isSubmitting ? "Uploading..." : "Upload"}
      </Button>
      <Box sx={{ width: "100%" }}>
        <DataTable
          height={"calc(100vh - 420px)"}
          rowHeight={28}
          // key={refresh}
          columns={docColumns}
          url={`document/file/${selectedPost?.id}`}
        />
      </Box>
    </Box>
  );
}
