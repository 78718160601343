import { Box, Paper } from "@mui/material";
import { patch } from "api";
import { BasePaper } from "app/Paper";
import TextField from "app/TextField";
import Toast from "app/Toast";
import DocumentTab from "common/Document/Tab";
import { LockButton, useLock } from "common/Lock";
import PhotosTab from "common/PhotosTab";
import PhotoTab from "common/PhotoTab";
import Tabs from "common/Tabs";
import Tab from "common/Tabs/Tab";
import { Form, Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export default function ClusterTypeDetails() {
  const { id } = useParams<{ id: string }>();
  const { data: clusterType } = useSWR(`/clustertype/${id}`);
  const { lock } = useLock();

  const handleSubmit = async (data: any) => {
    try {
      await patch(`/clustertype/${id}`, { description: data?.description });
      Toast("updated", "success");
    } catch (error: any) {
      console.log(error);
      Toast("error", "error");
    }
  };
  const isElevator = clusterType?.name === "Elevator";

  return (
    <BasePaper sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", md: "1fr 3fr" }, gap: 2, minHeight: "70vh" }}>
      <div>
        <Paper elevation={1} sx={{ p: 2 }}>
          <Formik enableReinitialize initialValues={clusterType} onSubmit={(data) => handleSubmit(data)}>
            {({ getFieldProps, handleSubmit }) => (
              <Form>
                <LockButton onSubmit={handleSubmit} />
                <TextField sx={{ my: 2 }} label="Name" {...getFieldProps("name")} disabled fullWidth />
                <TextField
                  multiline
                  rows={13}
                  sx={{ my: 2 }}
                  label="Description"
                  {...getFieldProps("description")}
                  fullWidth
                  disabled={lock}
                />
              </Form>
            )}
          </Formik>
        </Paper>
        <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
          <Tabs>
            <Tab label="Top Card Background">
              <PhotoTab model="cluster-type-top-card" id={id} />
            </Tab>
          </Tabs>
        </Paper>
      </div>
      <Box sx={{ width: { xs: "85vw", sm: "92vw", md: "unset" } }}>
        <Tabs>
          <Tab label="Catalog">
            <DocumentTab model="cluster-type-catalog" itemId={id} />
          </Tab>
          {/* <Tab label="Diagram">
            <PhotosTab model="cluster-type-diagram" modelId={id} />
          </Tab>
          <Tab label="Data">
            <PhotosTab model="cluster-type-data" modelId={id} />
          </Tab>
          <Tab label="Ordering">
            <PhotosTab model="cluster-type-ordering" modelId={id} />
          </Tab> */}
          {isElevator && (
            <Tab label="Problem">
              <PhotosTab model="cluster-type-problem" modelId={id} />
            </Tab>
          )}
          {isElevator && (
            <Tab label="Solution">
              <PhotosTab model="cluster-type-solution" modelId={id} />
            </Tab>
          )}
        </Tabs>
      </Box>
    </BasePaper>
  );
}
