import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import useSWR from "swr";
import jsPDF from "jspdf";

import { IPO, POLineItem } from "api/po";
import { IVendor } from "api/vendor";

import MyDialog from "app/Dialog";

import { getId } from "logic/utils";
import QRCode from "app/QRCode";

import CoverPage from "PDFTemplates/FieldServicePO/Cover";
import CustomerServiceForm from "PDFTemplates/FieldServicePO/CustomerServiceForm";
import CustomerSecondaryForm from "PDFTemplates/FieldServicePO/CustomerSercondaryForm";
import PreventiveForm from "PDFTemplates/FieldServicePO/PreventiveForm";
import PreventiveTable from "PDFTemplates/FieldServicePO/PreventiveTable";

import PoTotal from "PDFTemplates/SalesPo/Total";
import PoHeader from "PDFTemplates/SalesPo/header";
import PoLineItems from "PDFTemplates/SalesPo/lineitems";
import PageFooter from "PDFTemplates/footer";
import { QR } from "logic/QR";
import autoTable from "jspdf-autotable";
import { formatTimestampToDate } from "logic/date";
import Toast from "app/Toast";
import { createAModelDocument } from "api/document";

export default function FieldServicePOPdf({
  open,
  POId,
  onClose,
  onDone,
}: {
  open: boolean;
  POId: string;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data: po } = useSWR<IPO>(open && POId ? `/po/${POId}` : null);
  const { data: poLineItems } = useSWR<{ result: POLineItem[]; total: number }>(
    open && POId ? `/polineitem?POId=${POId}` : null
  );

  const { data: vendor } = useSWR<IVendor>(po?.VendorId ? `/vendor/${po?.VendorId}` : null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const lineItemsRef = useRef<HTMLTableElement | null>(null);
  const totalRef = useRef<HTMLDivElement | null>(null);
  const qrRef = useRef<HTMLDivElement | null>(null);
  const coverRef = useRef<HTMLDivElement>(null);
  const serviceFormRef = useRef<HTMLDivElement>(null);
  const secondaryFormRef = useRef<HTMLDivElement>(null);
  const preventiveFormRef = useRef<HTMLDivElement>(null);
  const preventiveTableRef = useRef<HTMLDivElement>(null);

  const handleFieldServiceDownload = async (download?: boolean, number?: string) => {
    try {
      if (
        headerRef.current &&
        lineItemsRef.current &&
        totalRef.current &&
        qrRef.current &&
        coverRef.current &&
        serviceFormRef.current &&
        secondaryFormRef.current &&
        preventiveFormRef.current &&
        preventiveTableRef.current
      ) {
        const scale = 0.26;
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

        const qrCanvas = qrRef.current.getElementsByTagName("canvas")[0];

        const topMargin = 5;

        const contents = [
          coverRef.current,
          serviceFormRef.current,
          secondaryFormRef.current,
          preventiveFormRef.current,
          preventiveTableRef.current,
        ];

        for (let i = 0; i < contents.length; i++) {
          await doc.html(contents[i], {
            x: 5,
            y: pageHeight * i + topMargin,
            html2canvas: {
              scale: 0.24,
              letterRendering: true,
            },
          });
          doc.addPage();
        }

        await doc.html(headerRef.current, {
          x: 2,
          y: pageHeight * 5,
          html2canvas: { scale, letterRendering: true },
        });

        doc.addImage(qrCanvas, "image/png", 100, 5, 20, 20);

        autoTable(doc, {
          startY: 105,
          html: lineItemsRef.current,
          useCss: true,
          margin: { bottom: 50 },
          bodyStyles: { minCellHeight: 40 },
          headStyles: {
            lineWidth: {
              bottom: 0.5,
            },
            lineColor: "#6a6a6a",
          },
          columnStyles: {
            0: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            1: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            2: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            3: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            4: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            5: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            6: { fontSize: 7, cellWidth: 30, minCellHeight: 35 },
          },

          didDrawPage(data) {
            doc.setFillColor(28, 37, 50);
            doc.rect(0, pageHeight - 20, pageWidth, 15, "F");

            doc.setFillColor(28, 117, 188);
            doc.circle(-5, pageHeight - 12, 15, "F");

            doc.setTextColor("#fff");
            doc.setFontSize(13);
            doc.text(String(data.pageNumber), 5, pageHeight - 10);
            doc.setFontSize(11);
            doc.text("(909) 930-3353", 30, pageHeight - 10);
            doc.text("dserrano@dspmanufacturing.com", 120, pageHeight - 10);
          },
        });

        if ((doc as any).lastAutoTable.finalY + 50 > pageHeight) {
          doc.addPage();
        }
        await doc.html(totalRef.current, {
          x: 2,
          y: pageHeight * 6 - 80,
          html2canvas: { scale, letterRendering: true },
        });

        if (download) {
          doc.save(`DSPMPO-${number || ""}-${formatTimestampToDate(Number(new Date()))}.pdf`);
        }
        return doc.output("blob");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const upload = async () => {
      try {
        setUploading(true);
        const generatedRepPdf = await handleFieldServiceDownload(true, po?.number);

        if (!generatedRepPdf) {
          return;
        }
        await createAModelDocument({
          file: generatedRepPdf,
          model: "po",
          id: getId(po),
          description: `${new Date().toJSON().slice(0, 19)} - ${po?.number}`,
          fileName: `PO_${po?.number}.pdf`,
          name: `PO_${po?.number}.pdf`,
        });

        Toast("PO document uploaded", "success");

        onDone && onDone();
        onClose();
        setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
      }
    };

    let t = setTimeout(() => {
      if (!uploaded && open && !uploading) {
        upload();
      }
    }, 1000);

    return () => clearTimeout(t);
  }, [POId, onClose, onDone, open, po, po?.number, uploaded, uploading]);

  const getQrUrl = () => {
    const qr = new QR("po");
    return qr.generatePoUrl({ poId: getId(po) });
  };

  return (
    <MyDialog title="PO pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth disabled={uploading}>
      <Button
        disabled={uploading}
        sx={{ ml: 3, mb: 2 }}
        variant="contained"
        onClick={() => handleFieldServiceDownload(true)}
      >
        {uploading ? "Uploading" : "Download"}
      </Button>

      <div style={{ width: "21cm", margin: "auto" }}>
        <CoverPage ref={coverRef} />
        <CustomerServiceForm ref={serviceFormRef} />
        <CustomerSecondaryForm ref={secondaryFormRef} />
        <PreventiveForm ref={preventiveFormRef} />
        <PreventiveTable ref={preventiveTableRef} />
        {getId(po) && (
          <div ref={qrRef} style={{ position: "absolute", right: 400 }}>
            <QRCode width={100} height={100} value={getQrUrl()} />
          </div>
        )}
        <PoHeader ref={headerRef} vendor={vendor} po={po as any} />
        <PoLineItems ref={lineItemsRef} blanket={(po as any)?.blanketPO} lines={poLineItems?.result || []} />
        <PoTotal ref={totalRef} sum={po?.total || 0} notes={po?.publicNote} />
        <div style={{ marginTop: 60 }}>
          <PageFooter email="dserrano@dspmanufacturing.com" phone={po?.number} number="(909) 930-3353" />
        </div>
      </div>
    </MyDialog>
  );
}
