import React, { ChangeEvent, ChangeEventHandler, useRef, useState, useEffect } from "react";
import { Button, IconButton, useMediaQuery } from "@mui/material";
import { Delete } from "./IconPack";
import upload from "assets/icons/UploadIcon.svg";

type FileType = "video" | "pdf" | "excel" | "image";

const fileTypeMap: Record<FileType, string[]> = {
  video: [".mp4", ".avi", ".mov", ".wmv"],
  pdf: [".pdf"],
  excel: [".xlsx", ".xls", ".csv"],
  image: [".jpg", ".jpeg", ".png", ".gif"],
};

interface UploadButtonProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  multiple?: boolean;
  accept?: string;
  disabled?: boolean;
  receive?: boolean;
  values?: File[] | File | null;
  title?: string;
  width?: string | number;
  imagePreview?: boolean;
  allowedTypes?: FileType[]; 
  maxSize?: number; 
  onError?: (error: string) => void; 
}

const UploadButton = ({
  onChange,
  multiple,
  accept,
  disabled,
  values,
  title,
  receive,
  width,
  imagePreview,
  allowedTypes,
  maxSize,
  onError,
}: UploadButtonProps) => {
  const phone = useMediaQuery("(max-width:550px)");
  const fileUploader = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File[] | null>(values && !Array.isArray(values) ? [values] : null);
  const [preview, setPreview] = useState<string | null>(null);

  const generateAcceptString = () => {
    if (!allowedTypes) return accept;
    return allowedTypes
      .flatMap(type => fileTypeMap[type])
      .join(",");
  };

  const isValidFileType = (file: File): boolean => {
    if (!allowedTypes) return true;
    
    const extension = "." + file.name.split(".").pop()?.toLowerCase();
    return allowedTypes.some(type => 
      fileTypeMap[type].some(ext => ext.toLowerCase() === extension)
    );
  };

  const isValidFileSize = (file: File): boolean => {
    if (!maxSize) return true;
    const fileSizeInMB = file.size / (1024 * 1024);
    return fileSizeInMB <= maxSize;
  };

  useEffect(() => {
    if (file && file.length > 0 && imagePreview) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file[0]);
    } else {
      setPreview(null);
    }
  }, [file, imagePreview]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);

    const invalidTypeFiles = newFiles.filter(file => !isValidFileType(file));
    const invalidSizeFiles = newFiles.filter(file => !isValidFileSize(file));

    if (invalidTypeFiles.length > 0) {
      onError?.(
        `Invalid file type(s): ${invalidTypeFiles
          .map(f => f.name)
          .join(", ")}. Allowed types: ${allowedTypes?.join(", ")}`
      );
      event.target.value = "";
      return;
    }

    if (invalidSizeFiles.length > 0) {
      onError?.(
        `File(s) exceed maximum size of ${maxSize}MB: ${invalidSizeFiles
          .map(f => f.name)
          .join(", ")}`
      );
      event.target.value = "";
      return;
    }

    setFile(newFiles);
    if (onChange) {
      onChange(event);
    }
  };

  const removeFile = (index: number) => {
    setFile((prevFiles) => {
      const newFiles = prevFiles ? prevFiles.filter((_, idx) => idx !== index) : [];
      const dataTransfer = new DataTransfer();
      newFiles.forEach((file) => dataTransfer.items.add(file));

      if (fileUploader.current) {
        fileUploader.current.files = dataTransfer.files;
        fileUploader.current.dispatchEvent(new Event("change", { bubbles: true }));
      }

      return newFiles;
    });
  };

  return (
    <div style={{ width: width || "100%" }}>
      <input
        style={{ display: "none" }}
        name="custom-file-input"
        id="custom-file-input"
        type="file"
        ref={fileUploader}
        onChange={handleFileChange}
        multiple={multiple}
        accept={generateAcceptString()}
      />
      <label htmlFor="custom-file-input">
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={() => !disabled && fileUploader.current?.click()}
          disabled={disabled}
          startIcon={<img src={upload} alt="" style={{ width: 16, height: 16 }} />}
          fullWidth
        >
          {!receive ? title || "Upload" : ""}
        </Button>
      </label>
      {file && file.length > 0 && (
        <div style={{ marginTop: "1em" }}>
          {file.map((f, idx) => (
            <div
              key={idx}
              style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0.5em" }}
            >
              {imagePreview ? (
                <img
                  src={URL.createObjectURL(f)}
                  alt="Preview"
                  style={{ maxWidth: "100px", maxHeight: "100px", marginRight: "1em", borderRadius: "5px" }}
                />
              ) : (
                <span style={{ marginRight: "1em" }}>{f.name}</span>
              )}
              <IconButton title="Remove File" onClick={() => removeFile(idx)}>
                <Delete sx={{ width: 14, height: 14 }} />
              </IconButton>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadButton;