import React from "react";
import useSWR from "swr";

import { formatTimestampToDate } from "logic/date";
import { instructionType } from "api/instruction";
import { ISO } from "api/so";
import { getId } from "logic/utils";

import newHeaderBg from "assets/icons/new_header.png";

export default function UnitInfo({
  photo,
  unit,
  options,
}: {
  photo: string | any;
  unit: instructionType[] | any;
  options: any;
}) {
  const { data: so } = useSWR<ISO>(`/so/${getId(unit?.SOId)}`);

  return (
    <div
      style={{
        width: "100%",
        marginBottom: "80px",
        position: "relative",
      }}
    >
      <img alt="dspm" src={newHeaderBg} style={{ width: "21cm", height: "140px", position: "absolute" }} />
      {/* <img alt="dspm" src={dspm} style={{ width: "120px", height: "auto", marginTop: "20px" }} /> */}
      <p
        style={{
          fontSize: "22px",
          fontWeight: "bold",
          margin: "20px",
          paddingTop: 30,
          color: "white",
          zIndex: 1,
          position: "relative",
        }}
      >
        Traveler Document
      </p>
      <div
        style={{
          background: "#f1f1f1",
          marginTop: 90,
          padding: "20px",
          paddingRight: 60,
          display: "grid",
          gridTemplateColumns: "120px 2fr",
          gap: 2,
          marginBottom: 10,
        }}
      >
        <h3 style={{ fontSize: 12, margin: 0 }}>Shipping Date:</h3>
        <div>
          {unit.shipDate || so?.actualShipDate
            ? `${formatTimestampToDate(Number(unit.shipDate || so?.actualShipDate))}`
            : ""}
        </div>
        <h3 style={{ fontSize: 12, margin: 0 }}>SO Number:</h3>
        <div>{unit.so}</div>
        <h3 style={{ fontSize: 12, margin: 0 }}>Model Name:</h3>
        <div>{unit?.serial}</div>
        <h3 style={{ fontSize: 12, margin: 0 }}>Model Description:</h3>
        <div
          dangerouslySetInnerHTML={{ __html: unit?.description.replaceAll("\r", "<br />").replaceAll("\n", "<br />") }}
        />
      </div>
      {options?.result.map((option: any, idx: number) => (
        <div
          key={getId(option)}
          style={{
            background: "#f1f1f1",
            padding: "20px",
            paddingRight: 60,
            display: "grid",
            gridTemplateColumns: "120px 2fr",
            gap: 2,
            margin: "8px 0",
          }}
        >
          <h3 style={{ fontSize: 12, margin: 0 }}>Option {idx + 1} Name:</h3>
          <span>{option?.serial}</span>
          <h3 style={{ fontSize: 12, margin: 0 }}>Option Description:</h3>
          <div dangerouslySetInnerHTML={{ __html: option?.description?.replaceAll("\r", "<br />") }} />
        </div>
      ))}
    </div>
  );
}
