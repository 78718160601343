import arrowRight from "assets/pdf/arrow_right_orange.png";

export default function SectionHeader({ title }) {
  return (
    <div className="flex flex-row w-full items-center">
      <img src={arrowRight} alt="" style={{ width: "16px", height: "auto" }} />
      <span className="text-xl font-bold text-primaryOrange ml-4">{title}</span>
    </div>
  );
}
