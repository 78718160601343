import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

type PageFractions = "1/4" | "1/3" | "1/2" | "1";

export class PDF {
  doc: jsPDF;
  cursor: { x: number; y: number };
  pageWidth: number;
  pageHeight: number;
  pages: number;
  pageNumber = 1;
  title: string;

  phone?: string;

  constructor({
    title,
    summaryData,
    headerCardData,

    phone,
  }: {
    title: string;
    summaryData: { [key: string]: string };
    headerCardData: { col1: [string, string]; col2: [string, string]; col3: [string, string] };

    phone?: string;
  }) {
    this.doc = new jsPDF();
    this.cursor = { x: 0, y: 0 };
    this.pageWidth = this.doc.internal.pageSize.width || this.doc.internal.pageSize.getWidth();
    this.pageHeight = this.doc.internal.pageSize.height || this.doc.internal.pageSize.getHeight();
    this.pages = 1;

    this.title = title;

    this.addHeader({ headerCardData, summaryData });

    this.phone = phone;
  }

  private addHeader({
    summaryData,
    headerCardData,
  }: {
    summaryData: { [key: string]: string };
    headerCardData: { col1: [string, string]; col2: [string, string]; col3: [string, string] };
  }) {
    this.doc.addImage(require("assets/icons/dspm.png"), 5, 5, 50, 20, "", "FAST");
    this.doc.text(this.title, 12, 30);

    this.doc.setFillColor("#ccc");
    this.doc.roundedRect(0, 40, this.pageWidth - 50, 20, 5, 5, "F");

    this.doc.setLineWidth(4);
    this.doc.setDrawColor("#fff");
    this.doc.roundedRect(this.pageWidth - 75, 0, 75, 65, 5, 5, "S");
    this.doc.roundedRect(this.pageWidth - 75, 0, 75, 65, 5, 5, "F");

    let cnt = 5;
    for (const key in summaryData) {
      if (Object.prototype.hasOwnProperty.call(summaryData, key)) {
        const value = summaryData[key];

        this.doc.setFontSize(9);
        this.doc.setTextColor("#000");
        this.doc.setFont("Helvetica", "bold");
        this.doc.setFontSize(8);
        this.doc.text(key + ": ", 140, 5 + cnt);

        this.doc.setFontSize(7);
        this.doc.setFont("helvetica", "normal");
        this.addWrappedText({
          text: value,
          width: 35,
          x: 170,
          y: 5 + cnt,
        });
        cnt += 6;
      }
    }

    this.doc.setTextColor("#000");
    this.doc.setFontSize(12);
    this.doc.setFont("Helvetica", "bold");
    this.doc.text(headerCardData.col1[0], 5, 45);
    this.doc.setFontSize(8);
    this.doc.setFont("helvetica", "normal");
    this.addWrappedText({ text: headerCardData.col1[1], width: 40, x: 5, y: 50 });

    this.doc.setFontSize(12);
    this.doc.setFont("Helvetica", "bold");
    this.doc.text(headerCardData.col2[0], 50, 45);
    this.doc.setFontSize(8);
    this.doc.setFont("helvetica", "normal");
    this.addWrappedText({ text: headerCardData.col2[1], width: 40, x: 50, y: 50 });

    this.doc.setFontSize(12);
    this.doc.setFont("Helvetica", "bold");
    this.doc.text(headerCardData.col3[0], 90, 45);
    this.doc.setFontSize(8);
    this.doc.setFont("helvetica", "normal");
    this.addWrappedText({ text: headerCardData.col3[1], width: 40, x: 90, y: 50 });

    this.cursor = { x: 0, y: 80 };
  }

  getWidth(pr: PageFractions) {
    switch (pr) {
      case "1/4":
        return this.pageWidth / 4;
      case "1/3":
        return this.pageWidth / 3;
      case "1/2":
        return this.pageWidth / 2;
      case "1":
        return this.pageWidth;
    }
  }

  getTextHeight(text: string, maxWidth?: number) {
    const textHeight = this.doc.getTextDimensions(text, { maxWidth });
    return textHeight;
  }

  addTable({
    body,
    head,
    y,
    total,
    note,
  }: {
    y: number;
    head: any[];
    body: any[];
    total?: number | string[];
    note?: string;
  }) {
    const hasFooter = note !== undefined;

    autoTable(this.doc, {
      startY: y,
      head,
      body,
      theme: "plain",
      margin: { ...(hasFooter ? { bottom: 70 } : {}) },
      headStyles: {
        lineColor: "#a6a6a6",
        lineWidth: {
          bottom: 0.5,
        },
      },
      didDrawPage: (data) => {
        this.addPageFooter();
      },
    });

    if (total && typeof total === "number") {
      this.doc.setFillColor("#FFB61A");
      this.doc.roundedRect(this.pageWidth - 35, this.pageHeight - 70, 30, 5, 1, 1, "F");
      this.doc.setTextColor("#000");
      this.doc.setFont("Helvetica", "bold");
      this.doc.text("Total: " + total, this.pageWidth - 30, this.pageHeight - 70 + 3);
    }
    if (total && Array.isArray(total)) {
      this.doc.setFillColor("#FFB61A");
      this.doc.roundedRect(
        this.pageWidth - 50,
        (this.doc as any).lastAutoTable.finalY,
        45,
        7 * total.length,
        1,
        1,
        "F"
      );
      this.doc.setTextColor("#000");
      this.doc.setFont("Helvetica", "bold");

      let cnt = 0;
      for (const text of total) {
        this.doc.text(text, this.pageWidth - 45, (this.doc as any).lastAutoTable.finalY + 3 + cnt);
        cnt += 7;
      }
    }

    if (hasFooter) {
      this.addWrappedText({
        text: "Notes: " + note,
        width: this.pageWidth - 50,
        x: 5,
        y: this.pageHeight - 60,
      });
      this.doc.setFontSize(10);
      this.doc.text("DSPM Manufacturing Inc.", this.pageWidth - 50, this.pageHeight - 60);

      this.doc.setFont("Helvetica", "normal");
      this.doc.setFontSize(8);
      this.doc.text("439 S. Stoddard Ave,", this.pageWidth - 50, this.pageHeight - 55);
      this.doc.text("San Bernardino, CA 92401", this.pageWidth - 50, this.pageHeight - 50);
      this.doc.text("(909) 930-3353", this.pageWidth - 50, this.pageHeight - 45);
      this.doc.text("www.DSPManufacturing.com", this.pageWidth - 50, this.pageHeight - 40);
    }

    this.cursor = { x: 0, y: (this.doc as any).lastAutoTable.finalY };
  }

  addPageFooter() {
    this.doc.setFillColor(28, 37, 50);
    this.doc.rect(0, this.pageHeight - 30, this.pageWidth, 15, "F");

    this.doc.setFillColor(28, 117, 188);
    this.doc.circle(-5, this.pageHeight - 22, 15, "F");

    this.doc.setTextColor("#fff");
    this.doc.setFontSize(13);
    this.doc.text(String(this.pageNumber), 5, this.pageHeight - 20);
    this.doc.setFontSize(11);
    this.doc.text(this.phone || "1.877.377.6769", 30, this.pageHeight - 20);
    this.doc.text("Quote@dspmanufacturing.com", 120, this.pageHeight - 20);

    this.pageNumber += 1;
  }

  addCard({
    texts,
    width,
    x,
    y,
  }: {
    texts: { label: string; value: string }[];
    x: number;
    y: number;
    width: PageFractions;
  }) {
    const cardWidth = this.getWidth(width);
    let cardHeight = 0;
    texts.forEach((text) => {
      const textHeight = this.getTextHeight(text.label + ": " + text.value, cardWidth - 10).h;
      cardHeight += textHeight + 5;
    });

    this.doc.setFillColor("#ccc");
    this.doc.roundedRect(x, y, cardWidth, cardHeight, 5, 5, "F");

    this.doc.setTextColor("#000");
    let cnt = 0;
    texts.forEach((text) => {
      this.doc.text(text.label + ": " + text.value, x + 10, y + 10 + cnt);
      cnt += 5;
    });

    this.cursor = { x: x + cardWidth, y: y + cardHeight };
  }

  addBookmark({ text, x, y }: { text: string; x: number; y: number }) {
    const width = this.doc.getTextWidth(text);
    this.doc.setFillColor("#1d74bd");
    this.doc.roundedRect(x, y, width + 15, 10, 5, 5, "F");
    this.doc.setTextColor("#fff");
    this.doc.text(text, x + 10, y + 5);

    this.cursor = { x: width + 15, y: y + 10 };
  }

  addWrappedText({
    text,
    width,
    x,
    y,
    align,
    style,
  }: {
    text: string;
    width: number;
    x: number;
    y: number;
    align?: "left" | "center" | "right" | "justify";
    style?: "heading" | "italic";
  }) {
    if (style === "heading") {
      this.doc.setFontSize(12);
      this.doc.setFont("Helvetica", "bold");
    } else if (style === "italic") {
      this.doc.setFont("Helvetica", style);
    }
    const texts = this.doc.splitTextToSize(text, width);
    this.doc.text(texts, x, y, { align });

    this.cursor = { x, y };
  }

  addJustifiedText({
    text,
    textWidth,
    fontSize = 10,
    lineSpacing = 5,
    x = 10,
    y = 10,
    pageWrapInitialYPosition = 10,
    marginBottom = 10,
  }: {
    x?: number;
    y?: number;
    text: string;
    textWidth: number;
    fontSize?: number;
    lineSpacing?: number;
    marginBottom?: number;
    pageWrapInitialYPosition?: number;
  }) {
    let cursorY = y;

    this.doc.setFont("Helvetica", "normal");
    this.doc.setFontSize(fontSize);
    const splitted = this.doc.splitTextToSize(text, textWidth);
    const splittedHeight = splitted.length * lineSpacing;

    if (cursorY + splittedHeight + marginBottom > this.pageHeight) {
      this.doc.addPage();
      this.addPageFooter();
      cursorY = pageWrapInitialYPosition;
    }
    this.doc.text(splitted, x, cursorY, { align: "justify", maxWidth: textWidth + 20 });
    cursorY += splittedHeight;

    return cursorY;
  }

  output() {
    return this.doc.output("blob");
  }

  save(name?: string) {
    this.doc.save(name);
  }
}
