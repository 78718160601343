import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";

import { BasePaper } from "app/Paper";
import ClusterTypeDetails from "pages/ClusterType/details";
import ClusterTypeList from "pages/ClusterType/list";

export default function ClusterType() {
  const [tab, setTab] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const pathSplited = location.pathname.split("/").filter(Boolean);

  useEffect(() => {
    if (pathSplited.length > 3) {
      setTab(1);
    }
  }, [pathSplited.length]);

  return (
    <BasePaper>
      <Tabs
        value={tab}
        onChange={(e, v) => {
          if (v === 0) {
            history.push("/panel/website-settings/cluster-type");
          }
          setTab(v);
        }}
        sx={{ mb: 1 }}
      >
        <Tab label="List" />
        <Tab label="Details" disabled />
      </Tabs>
      <Switch>
        <Route exact path="/panel/website-settings/cluster-type" component={ClusterTypeList} />
        <Route exact path="/panel/website-settings/cluster-type/:id" component={ClusterTypeDetails} />
      </Switch>
    </BasePaper>
  );
}
