import section from "assets/svg/section.png";

export default function SectionHeader({ title, number }) {
  return (
    <div className="relative w-full">
      <img
        src={section}
        alt=""
        style={{
          width: "100%",
          height: "auto",
          objectFit: "contain",
        }}
      />
      <div className="absolute left-[10%] bottom-[25%] flex items-center">
        <span className="font-bold text-white text-[16px]">{number}.</span>
      </div>
      <div className="absolute left-[13%] bottom-[25%] flex items-center">
        <span className=" text-white font-bold text-[16px]">{title}</span>
      </div>
    </div>
  );
}
