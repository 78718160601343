import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  Popover,
  Switch,
  Typography,
} from "@mui/material";
import { CheckRounded, Circle, ClearRounded } from "@mui/icons-material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import useSWR from "swr";
import { useSession } from "features/Session/sessionsSlice";
import { getId } from "logic/utils";
import { formatTimestampToDate } from "logic/date";
import { employeeNotificationType, toggleSeenNotification } from "api/notification";
import { BasePaper } from "app/Paper";

export default function NotificationMenu({
  open,
  anchorEl,
  onClose,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}) {
  const employee = useSession();
  const [unread, setUnread] = useState(false);
  const { data, isValidating, mutate, isLoading } = useSWR<{ result: employeeNotificationType[]; total: number }>(
    unread ? `/employee/unread/notification` : getId(employee) ? `/employee/${getId(employee)}/notification` : null
  );

  const handleSeen = async (id: string) => {
    try {
      await toggleSeenNotification(id);
      mutate();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Popover
      id="notification-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box p={1} width={370} overflow="auto">
        <Box sx={{ py: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Notifications</Typography>
          <FormControlLabel
            control={<Switch checked={unread} onChange={(e: any) => setUnread((p) => !p)} />}
            label={"only show unread"}
          />
        </Box>
        <Divider sx={{ borderColor: "#758195" }} />
        {isValidating && <LinearProgress />}
        <List>
          {data?.result.map((i, idx) => (
            <Box sx={{ width: "100%", display: "grid", gridTemplateColumns: "3fr .25fr", alignItems: "start", mt: 2 }}>
              <BasePaper sx={{ background: "#F1F2F4" }} key={idx}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <IconButton
                    sx={{ color: i?.seenBy?.length > 0 ? "green" : "black" }}
                    onClick={() => handleSeen(getId(i))}
                  >
                    {i?.seenBy?.length > 0 ? <DoneAllIcon /> : <Typography sx={{ fontSize: "8px" }}>read</Typography>}
                  </IconButton>
                </Box>
                <Typography sx={{ fontSize: 14, ml: 1 }} mr="auto">
                  {i?.title}
                </Typography>
                <Typography sx={{ fontWeight: 600, my: 1, ml: 1 }}>{i?.data?.number}</Typography>
                <Typography ml={1} my={2} sx={{ fontSize: 14 }}>
                  {i?.body}
                  <span> to {formatTimestampToDate(i?.createdAt)}</span>
                </Typography>
              </BasePaper>
              {/* {i?.seenBy?.length > 0 ? (
                <IconButton
                  sx={{
                    width: 15,
                    height: 15,

                    border: 1,

                    borderRadius: "50%",
                    background: "#1D7AFC",
                    mx: 1,
                  }}
                ></IconButton>
              ) : (
                <IconButton
                  sx={{ width: 15, height: 15, borderColor: "#CCE0FF", background: "#FFF", borderRadius: "50%", mx: 1 }}
                  onClick={() => handleSeen(getId(i))}
                ></IconButton>
              )} */}
            </Box>
          ))}
        </List>
      </Box>
    </Popover>
  );
}
