import Footer from "../Quote/footer/Footer";
import Header from "../PO/header/Header";
import { formatCurrency, generateQuery, getId } from "logic/utils";
// import itemLines from "../../assets/data/SOLines.json"
// import ItemTable from "./ItemTable";
import { Fragment } from "react";
import SectionHeader from "../Quote/SectionHeader";
import ItemTable from "../Quote/ItemTable";
import useSWR from "swr";

export default function SOpagex({ data, Items, pageNumber, page, lastPage, totalFreight, commission, title }) {
  return (
    <div className="flex flex-col items-center h-full w-full mx-auto relative">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content
          data={data}
          Items={Items}
          page={page}
          lastPage={lastPage}
          totalFreight={totalFreight}
          commission={commission}
        />
      </div>
      <div style={{ position: "absolute", top: "27cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} title={title} email="sales-dspm@dspmanufacturing.com" />
      </div>
    </div>
  );
}

const SectionTitle = (item) => {
  if (item.freight) return "Freight";
  if (item?.ItemId) return item.ItemId.no;
  else return "Service Program Options";
};

function Content({ data, Items, page, lastPage, totalFreight, commission }) {
  const lineQuery = generateQuery({
    SOId: getId(data),
  });
  const { data: lineItems } = useSWR(`/lineitem?${lineQuery}`);
  const totalOverageCommission = lineItems?.result
    .map((i) => (i?.qty || 0) * (i?.overage || 0))
    .reduce((p, c) => p + c, 0);

  return (
    <div className="flex flex-col items-center h-[98%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        {/* {Items.length !== 0 ? (
          <div>
            <ItemTable Items={Items} />
          </div>
        ) : null} */}
        {Items.length !== 0 &&
          Items?.map((item, index) => (
            <Fragment key={index}>
              <div className="mt-[4%]">
                {item.sort === 1 ? <SectionHeader title={SectionTitle(item)} number={item.group || ""} /> : <></>}
              </div>
              <p className="my-4 font-bold ml-2">{item.sort === 1 ? item.note : <></>}</p>
              <div>
                <ItemTable
                  no={item.itemNo || item.no || item.itemName || item.ItemId?.no || item.ItemId?.name || ""}
                  groupLine={item.group && item.sort ? `[${item.group}]-${item.sort}` : ""}
                  description={item.description || ""}
                  qty={item.qty}
                  unitPrice={formatCurrency(item.price)}
                  totalPrice={formatCurrency(item.total)}
                />
              </div>
            </Fragment>
          ))}
        {lastPage === page ? (
          <div className="mt-[4%]">
            <div className="flex flex-col  items-center">
              <div className="flex flex-row bg-[#9e9e9e] w-2/3 py-4 px-8 justify-between">
                <span className="text-white text-[16px]">SO Subtotal</span>
                <span className="text-white font-bold text-[16px]">
                  {data.subtotal ? formatCurrency(data.subtotal) : "-"}
                </span>
              </div>
              <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                <span className="text-white text-[16px]">SO Subtotal Taxable:</span>
                <span className="text-white font-bold text-[16px]">
                  {data.taxable ? formatCurrency(data.taxable) : "-"}
                </span>
              </div>
              <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                <span className="text-white text-[16px]">Discount Amount:</span>
                <span className="text-white font-bold text-[16px]">
                  {data.discountAmount ? formatCurrency(data.discountAmount) : "-"}
                </span>
              </div>
              <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                <span className="text-white text-[16px]">Total Freight:</span>
                <span className="text-white font-bold text-[16px]">
                  {formatCurrency(
                    data.totalFreight || data?.shippingAndHandling || data?.freightCost || totalFreight || 0
                  )}
                </span>
              </div>
              <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                <span className="text-white text-[16px]">Discount Rate</span>
                <span className="text-white font-bold text-[16px]">{formatCurrency(data?.discountAmount || 0)}</span>
              </div>
              <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                <span className="text-white text-[16px]">Sales Tax Rate</span>
                <span className="text-white font-bold text-[16px]">{formatCurrency(data?.salesTaxAmount || 0)}</span>
              </div>
              {commission ? (
                <>
                  <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                    <span className="text-white text-[16px]">Overage Commission</span>
                    <span className="text-white font-bold text-[16px]">
                      {formatCurrency(totalOverageCommission || data?.overageCommission || 0)}
                    </span>
                  </div>
                  <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                    <span className="text-white text-[16px]">Regular Commission</span>
                    <span className="text-white font-bold text-[16px]">
                      {formatCurrency(data?.regularCommission || 0)}
                    </span>
                  </div>
                  <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
                    <span className="text-white text-[16px]">Total Commission</span>
                    <span className="text-white font-bold text-[16px]">
                      {formatCurrency(data?.totalCommission || 0)}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="flex flex-row bg-primaryOrange w-2/3 p-4 px-8 justify-between">
                <span className="text-white text-[16px]">Order Total:</span>
                <span className="text-white font-bold text-[16px]">
                  {data.orderTotal ? formatCurrency(data.orderTotal) : "-"}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
