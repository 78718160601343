import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken } from "../api";
import EmployeeRouter from "./Employee";
import AttendancePage from "pages/attendance";
import AutoAttendancePage from "pages/autoAttendance";
import { selectSession } from "../features/Session/sessionsSlice";
import LoginPage from "../pages/Login";
import PanelRouter from "./Panel";
import PdfPages from "pages/PdfPages";
import PrintPage from "pages/Print";

export default function BaseRouter() {
  const session = useSelector(selectSession);
  const isAuthenticated = Boolean(getToken()) || session.status === "Authorized";

  if (isAuthenticated) {
    return (
      <Switch>
        <Route exact path="/">
          <Redirect to="/panel/home" />
        </Route>
        <Route path="/panel">
          <PanelRouter />
        </Route>
        <Route path="/employee">
          <EmployeeRouter />
        </Route>
        <Route exact path="/attendance">
          <AttendancePage />
        </Route>
        <Route exact path="/attendance/:userId">
          <AutoAttendancePage />
        </Route>
        <Route exact path="/print">
          <PrintPage />
        </Route>
        <Route exact path="*">
          <PdfPages />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/attendance">
        <AttendancePage />
      </Route>
      <Route exact path="/attendance/:userId">
        <AutoAttendancePage />
      </Route>
      <Route exact path="*">
        <LoginPage />
      </Route>
    </Switch>
  );
}
