import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import useSWR from "swr";
import Swal from "sweetalert2";

import { delete_ } from "api";
import { photoType } from "api/photo";
import { getDocumentUrl } from "api/document";
import { getId } from "logic/utils";
import Toast from "app/Toast";

export default function Photos({
  imageBaseUrl,
  url,
  disabled,
  isProfile,
}: {
  imageBaseUrl?: string;
  url: string;
  disabled?: boolean;
  isProfile?: boolean;
}) {
  const { data, mutate, isLoading } = useSWR<{ result: photoType[] }>(url);
  async function handleDelete(i: any) {
    try {
      await delete_(`/photo/${getId(i)}`);
      mutate();
      Toast("your profile picture deleted");
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} justifyContent={"center"}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {data?.result?.map((i: any) => (
            <Button
              key={getId(i)}
              onClick={() => {
                if (isProfile) {
                  handleDelete(i);
                } else {
                  Swal.fire({
                    title: "Warning",
                    text: "Are you sure you want to delete this image?",
                    confirmButtonText: "Delete",
                    showCancelButton: true,
                  }).then(async (r) => {
                    if (r.isConfirmed) {
                      await delete_(`/photo/${getId(i)}`);
                      mutate();
                    }
                  });
                }
              }}
              disabled={disabled}
            >
              <img
                src={getDocumentUrl((imageBaseUrl || "") + i.path)}
                alt=""
                style={{ minWidth: 40, maxWidth: "140px", height: "auto" }}
              />
            </Button>
          ))}
        </>
      )}
    </Box>
  );
}
