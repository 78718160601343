import styled from "@emotion/styled";
import React, { forwardRef } from "react";

const Wrapper = styled.div`
  width: 21cm;
  height: 29.7cm;

  input {
    border: none;
    outline: none;
  }

  .form-container {
    max-width: 800px;
    margin: 0 auto;
    border: 2px solid #000;
  }
  .header {
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom: 2px solid #000;
  }
  .logo {
    font-weight: bold;
    font-size: 24px;
  }
  .title {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
  }
  .contact-info {
    font-size: 11px;
    text-align: center;
  }
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px;
  }
  .full-width {
    grid-column: 1 / -1;
  }
  .form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  label {
    font-weight: bold;
  }
  input {
    width: 50%;
    padding: 5px;
    border: 1px solid #ccc;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  th,
  td {
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
  }
  .highlight {
    background-color: yellow;
  }
  .note {
    background-color: yellow;
    padding: 5px;
    margin-top: 10px;
    font-size: 12px;
  }
  .signature-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .signature-field {
    width: 45%;
  }
`;

function PreventiveForm(props: any, ref: any) {
  return (
    <Wrapper ref={ref}>
      <div className="form-container">
        <div className="header">
          <div className="title">PREVENTIVE MAINTENANCE/STARTUP REPORT</div>
          <div className="contact-info">
            Digital Signal Power Manufacturer, Inc.- PowerClone
            <br />
            1921 So. Quaker Ridge Place
            <br />
            Ontario, CA 91761
            <br />
            Voice: (714) 970-2304 Fax: (909) 930-3335 Email: customersupport@dspmanufacturing.com
          </div>
        </div>
        <div className="form-grid">
          <div className="form-field">
            <label>Service At:</label>
            <input type="text" value="0" />
          </div>
          <div className="form-field">
            <label>Service Contact Title:</label>
            <input type="text" value="0" />
          </div>
          <div className="form-field">
            <label>Service Phone:</label>
            <input type="text" value="0" />
          </div>
          <div className="form-field">
            <label>Product Type (i.e. UPS Regulator):</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Model No.:</label>
            <input type="text" value="0" />
          </div>
          <div className="form-field">
            <label>Serial No.:</label>
            <input type="text" value="0" />
          </div>
          <div className="form-field">
            <label>Service Code:</label>
            <input type="text" value="0" />
          </div>
        </div>
        <table>
          <tr>
            <th>Voltages</th>
            <th>X - Y</th>
            <th>Y - Z</th>
            <th>Z - X</th>
            <th>X - N</th>
            <th>Y - N</th>
            <th>Z - N</th>
          </tr>
          <tr>
            <td>Input</td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>Output</td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>DC Bus Inverter</td>
            <td>
              <input type="text" />
            </td>
            <td className="highlight">
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>DC Bus Battery Charger</td>
            <td>
              <input type="text" />
            </td>
            <td className="highlight">
              <input type="text" />
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>Amperage</th>
            <th>X</th>
            <th>Y</th>
            <th>Z</th>
            <th>NEUTRAL</th>
            <th>GROUND</th>
            <th>KVA</th>
          </tr>
          <tr>
            <td>Input</td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>Output</td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>DC Bus Inverter</td>
            <td>
              <input type="text" />
            </td>
            <td className="highlight">
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>DC Bus Battery Charger</td>
            <td>
              <input type="text" />
            </td>
            <td className="highlight">
              <input type="text" />
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th colSpan={3}>ELECTRICAL CONNECTIONS</th>
            <th colSpan={2}>COMPONENTS</th>
          </tr>
          <tr>
            <td>INT. GROUND</td>
            <td>XFMR INPUTS</td>
            <td>DC BREAKER</td>
            <td>CIRCUIT BRKRS</td>
            <td>DIGITAL DISPLAY</td>
          </tr>
          <tr>
            <td>INT. NEUTRAL</td>
            <td>XFMR OUTPUTS</td>
            <td>BATTERY (S)</td>
            <td>LAMPS / LED's</td>
            <td>FUSES</td>
          </tr>
          <tr>
            <td>CABLES/GROUND</td>
            <td>XFMR TAPS</td>
            <td>J - BOX</td>
            <td>PC BOARDS</td>
            <td>FUSE LINKS</td>
          </tr>
          <tr>
            <td>CABLES/NEUTRAL</td>
            <td>MAIN INPUT BRKR</td>
            <td>PC BOARDS</td>
            <td>RELAYS</td>
            <td>SCR's</td>
          </tr>
          <tr>
            <td>CABLES/BRKRS</td>
            <td>MAIN OUTPUT BRKR</td>
            <td>EPO</td>
            <td>DC CAPACITORS</td>
            <td>FANS</td>
          </tr>
          <tr>
            <td>INTERFACES</td>
            <td>BY-PASS SWITCH</td>
            <td>REPO</td>
            <td>BATTERY (S)</td>
            <td>AC CAP's</td>
          </tr>
        </table>
        <div className="note">
          If more space is needed please use Secondary Sheet for any "Problems Found", "Comments" and/or "Parts Used"
        </div>
        <table>
          <tr>
            <th>PARTS USED</th>
            <th>QTY</th>
            <th>Problems Found:</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              <input type="text" />
            </td>
            <td rowSpan={10}>
              <textarea rows={10} style={{ width: "100%" }}></textarea>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              <input type="text" />
            </td>
          </tr>
        </table>
        <div className="signature-section">
          <div className="signature-field">
            <label>Service Person's Signature:</label>
            <input type="text" />
            <label>Date:</label>
          </div>
          <div className="signature-field">
            <label>Customer's Signature:</label>
            <input type="text" />
            <label>Date:</label>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default forwardRef(PreventiveForm);
