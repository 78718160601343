import React from "react";
import Footer from "../Quote/footer/Footer";
import styled from "@emotion/styled";
import Header from "../PO/header/Header";

const Wrapper = styled("div")`
  padding-top: 16px;

  p {
    padding: 0 16px;
    text-align: justify;
    font-size: 12px;
  }
`;

export default function SOpage2({ number, pageNumber, title }: { number: string; pageNumber: string; title: string }) {
  return (
    <div className="mt-8 mx-auto h-[28cm] relative">
      <div className="flex w-full">
        <Header />
      </div>
      <Wrapper>
        <p className="font-bold mt-8">Patents</p>
        <p>
          Buyer shall indemnify, defend and hold Seller harmless against all expenses, damages, costs, or losses
          resulting from any suit or proceeding brought for infringement of copyright, patent, trademarks, or other
          intellectual property rights or for unfair competition arising from compliance with Buyer's design
          specifications or instructions. With respect to products manufactured solely to Seller's design and
          specifications, Seller shall defend any suit or proceeding brought against Buyer so far as based on a claim
          that any such products of any parts there of furnished hereunder constitute an infringement of any United
          States patent or United States mask work rights, if notified promptly of such claim in writing and given
          authority, information and assistance in the defense of same, and Seller shall pay all damages and costs
          awarded therein against Buyer. Seller assumes no liability, consequential or otherwise for, and Buyer agrees
          to hold Seller harmless against infringement of patent claims covering completed equipment or any assembly,
          circuit, combination, method or process in which any products may be used. In no event shall Seller's total
          liability to Buyer under or as a result of compliance with provisions of this paragraph exceed the aggregate
          sum paid to Seller by Buyer for the purchase of allegedly infringing products or parts. The foregoing states
          the entire warranty by Seller and the exclusive remedy of the Buyer with respect to any alleged copyright,
          patent or other intellectual property right infringement by such products or parts. No costs or expenses under
          this paragraph shall in any event be incurred for the account of the Seller without its prior written consent.
          Sale of product or any part thereof does not convey to Buyer any license, express or by implication, estoppels
          or otherwise, under any patent claim with respect to which Seller can grant licenses covering complicated
          equipment, or any assembly, circuit, combination, method or process in which any such products are used as
          components (not withstanding) the fact that such products may have been designed for use in or may only be
          useful in any such patented equipment, assembly circuit, combination, method or process, and that such
          products may have been purchased and sold for such use). Seller expressly reserves all rights under such
          patent or patent claim.
        </p>

        <p className="font-bold mt-8">Warranty and Sole Remedy</p>
        <p>
          For the applicable warranty period specified below, Seller warrants that the products to be delivered
          hereunder will be free from defects in materials and workmanship under normal use and service. The obligations
          of the Seller under this warranty are limited to replacing, repairing or giving credit for, at its option, any
          of said products which shall, within the warranty period be returned as provided herein to the Seller,
          transportation charges prepaid and which are, after examination, disclosed to the satisfaction of the Seller
          to be thus defective. Buyer acknowledges and agrees that the provisions of this warranty constitute the sole
          and exclusive remedy available to it with regard to said defective products. No agent, employee, or
          representative of Seller has any authority to bind Seller to any affirmation, representation or warranty
          relating to the products other than as specifically provided herein. The warranty provided herein is subject
          to the following conditions: A. If products become defective during the warranty period, Buyer shall notify
          Seller promptly in writing of any such claims and provide information pertinent to delivery dates of the
          product and/or date code information. B. If Seller advises Buyer to return the product for repair or
          replacement, Buyer will follow Sellers Material Return Authorization procedures. Any order returned after 90
          days from the time the order arrived on site, will not get credit for the batteries. C. If product alleged by
          Buyer to be defective or returned to Seller for repair as provided in this section is either (I) not under
          warranty, or (ii) determined not to be defective, or (iii) defective due to any cause not covered under the
          warranty provided herein, Buyer agrees to reimburse Seller for all reasonable expenses incurred in traveling
          and/or shipping, handling, and inspection of such product. D. Products will be accepted by Seller for warranty
          claim verification only when returned by Buyer in a condition which allows for suitable testing by Seller. E.
          Seller shall reimburse Buyer for shipping charges to the extent of the percent of the total return that are
          found by Seller to be defective as specified herein. Reimbursement will be in the form of a credit adjustment
          to Buyer's account unless otherwise agreed upon. F. In no event shall Seller be liable for any defective
          products if examination discloses that the defective condition of such products was caused by misuse, abuse or
          improper installation, application, maintenance or repair assembly by other than Seller, alteration, accident
          or negligence in use, storage, transportation or handling outside of specified environmental conditions. G.
          Seller assumes no risk or liability for the suitability or unsuitability or results of its products, used in
          combination with any electrical or electronic components, circuits, systems, assemblies, or any other material
          substances, or environments. H. Any returned products electrically or mechanically destroyed by Buyer or third
          parties will not be covered under this warranty, and will not be returned to Buyer, but will be scrapped by
          Seller. I. This warranty shall exist for a period of twelve (12) months after the date of shipment from the
          Seller and is considered null and void if components or subassemblies other than those supplied or approved by
          Seller are used in the assembly of Seller’s products, or if Sellers product is modified in any way without
          written authorization of Seller. J. Developmental products of Seller are warranted to be free from defects in
          materials and workmanship and to meet the applicable specifications only at the time of receipt of Buyer and
          for no longer period of time.
        </p>
      </Wrapper>
      <div style={{ position: "absolute", top: "27cm" }}>
        <Footer DocNumber={number} pageNumber={pageNumber} title={title} email="sales-dspm@dspmanufacturing.com" />
      </div>
    </div>
  );
}
