import React, { ReactNode } from "react";
import { Tab as MuiTab } from "@mui/material";

export default function Tab({
  disabled,
  label,
  children,
}: {
  disabled?: boolean;
  label?: string | ReactNode;
  children?: ReactNode;
}) {
  return <MuiTab label={label} disabled={disabled} />;
}
