import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import SectionHeader2 from "./SectionHeader2";
export default function QuotePage4({ data, pageNumber }) {
  return (
    <div className="relative flex flex-col items-center h-full w-[98%]  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div style={{ position: "absolute", left: 0, top: "25cm", width: "20.5cm" }}>
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

const Content = () => {
  const texts = {
    Acceptance:
      "The terms of sale contained herein apply to all sales transactions including Quotations and Purchase Orders entered into by Seller directly with the Buyer for standard product. Custom product sales may be subject to additional terms and conditions. This acceptance is conditional upon Buyer's assent to the terms and conditions set out herein in lieu of those in Buyer's purchase orders. Modifications or additions to these terms of sale will be recognized only if accepted in writing by an officer of the Seller. Seller's failure to object to provisions contained in any communication from Buyer shall not be deemed a waiver of the provisions contained herein.",
    Prices:
      "Quotations automatically expire 30 days from the date issued and are subject to withdrawal or extension by notice within that period. Seller's published prices are subject to change without notice. New pricing will not be applied to existing orders accepted by Seller. Prices quoted are for products only and do not include any other charges unless specifically covered in writing by Seller. Any discrepancies relating to price must be called to Seller's attention for resolution within 30 days of the billing date.",
    Taxes:
      "Unless otherwise agreed upon in writing or required by law, all prices will be quoted and billed exclusive of federal, state and local excise sales and similar taxes. Such taxes, when applicable shall appear as additional terms on the invoice. If exemption from such taxes is claimed, buyer must provide, or have on file with Seller, a certificate of exemption at the time the Purchase Order is submitted to Seller.",
    OrdersAcceptance:
      "Buyer's Purchase Orders must be in writing and approved by an authorized representative of the Buyer. Seller shall determine order acceptance at its discretion, and may impose restrictions for quantity requirements in regard to total order value and product packaging minimums. Seller can also restrict time frames for scheduled deliveries and change order activity. Terms of acceptance will be mutually understood by both parties, and authorized in writing by Seller at the Buyer's request",
  };

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[2%]">
          <SectionHeader2 title={"TERMS AND CONDITIONS:"} />
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Acceptance:</span>
            <span className="text-[16px] font-light text-justify">{texts.Acceptance}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Price:</span>
            <span className="text-[16px] font-light text-justify">{texts.Prices}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Taxes:</span>
            <span className="text-[16px] font-light text-justify">{texts.Taxes}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Orders Acceptance:</span>
            <span className="text-[16px] font-light text-justify">{texts.OrdersAcceptance}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
