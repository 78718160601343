import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, FormControlLabel, Paper, useMediaQuery, Switch, Chip, Button, Typography } from "@mui/material";
import TextField from "app/TextField";
import { LockButton, useLock } from "common/Lock";
import { PostType } from "api/magazine";
import { DatePicker } from "@mui/x-date-pickers";
import AsyncCombo from "common/AsyncCombo";
import { get } from "api";
import FileUploader from "app/UploadButton";
import { toast } from "react-toastify";
import { host } from "host";

interface IForm {
  values: PostType;
  errors?: any;
  touched?: any;
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  handleSubmit?: () => void;
  setFieldValue?: any;
  isSubmitting?: boolean;
  device?: boolean;
  boms?: boolean;
  add?: boolean;
}

export const BlogForm = ({
  values,
  handleChange,
  handleBlur,
  add,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}: IForm) => {
  const phone = useMediaQuery("(max-width:900px)");
  const { lock } = useLock();
  const [selectedTopic, setSelectedTopic] = useState<any>();
  const [currentTag, setCurrentTag] = useState("");
  const [photoUploaded, setPhotoUploaded] = useState(false);

  console.log({ values });

  useEffect(() => {
    if (values?.TopicId) {
      get(`/topic`).then((response) => {
        const selectedTopic = response?.result.find((o: any) => o.id === values?.TopicId);
        setSelectedTopic(selectedTopic);
      });
    }
  }, [values?.TopicId]);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    console.log("hi");
    const file = event.target.files?.[0];
    console.log("FILE", file);
    if (file) {
      console.log("IN THE IF");
      setFieldValue("header", file);
      setPhotoUploaded(true);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginTop: add ? 0 : 8,
        }}
      >
        <LockButton onSubmit={handleSubmit} loading={isSubmitting} />
      </div>
      <Box display="grid" gridTemplateColumns={phone ? "1fr" : "1fr 1fr"} gap={2}>
        <Paper
          style={{
            margin: "0.5em 0",
            backgroundColor: "#eee",
            gridColumnEnd: "span 2",
          }}
          sx={{ py: "0.5rem", px: "1rem" }}
        >
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <FormControlLabel
              sx={{ "& .MuiTypography-root ": { fontSize: "0.8rem" } }}
              label="Status"
              value={values?.status}
              name="status"
              onChange={handleChange}
              control={<Switch size="small" checked={values?.status} />}
              disabled={lock}
            />
          </Box>
        </Paper>
        <TextField
          label="Title"
          name="title"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.title}
          disabled={lock}
        />
        <AsyncCombo
          label="Topic"
          placeholder="All"
          url="/topic"
          filterBy=""
          getOptionLabel={(o) => String(o?.name) || ""}
          getOptionSelected={(o, v) => o?.id === v?.id}
          defaultValue={selectedTopic}
          controlledValue={selectedTopic || null}
          onChange={(e, nv) => {
            setSelectedTopic(nv);
            setFieldValue("TopicId", nv?.id);
          }}
          disabled={lock}
        />
        <DatePicker
          value={values?.createdAt || new Date()}
          label="Created At"
          sx={{
            "& .MuiInputBase-root": {
              height: "32px",
            },
            "& .MuiFormLabel-root": {
              fontSize: ".9rem",
            },
            "& .MuiInputBase-input": {
              "-webkit-text-fill-color": "#000 !important",
              fontSize: ".8rem",
            },
          }}
          disabled
        />
        <TextField
          label="Author Name"
          name="authorName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.authorName}
          disabled
        />
        <TextField
          label="Time Duration"
          name="timeDuration"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.timeDuration}
          disabled={lock}
        />
        <TextField
          label="Visit Count"
          name="visitCount"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.visitCount}
          disabled
        />

        <TextField
          multiline
          style={{ gridColumnEnd: "span 2" }}
          rows={6}
          label="Summary"
          name="summary"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.summary}
          disabled={lock}
        />

        <TextField
          disabled={lock}
          value={currentTag}
          name="tag"
          label="Tag"
          onChange={(e) => setCurrentTag(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ width: "11rem" }}
          disabled={lock}
          onClick={() => {
            const temp = [...values?.tags, currentTag];
            setFieldValue("tags", temp);
            setCurrentTag("");
          }}
        >
          Add Tag
        </Button>

        {values?.tags && values?.tags?.length > 0 && (
          <Box sx={{ display: "flex", gridColumnEnd: "span 2", flexWrap: "wrap", gap: "0.5rem" }}>
            {values?.tags?.map((tag: string, index: number) => (
              <Chip
                key={index}
                label={tag}
                disabled={lock}
                onDelete={() => {
                  const temp = values?.tags?.filter((t: string) => t !== tag);
                  setFieldValue("tags", temp);
                }}
              />
            ))}
          </Box>
        )}

        <Typography style={{ gridColumnEnd: "span 2" }}>Header Image</Typography>
        {photoUploaded ? (
          <Box style={{ gridColumnEnd: "span 2" }}>
            {values?.header && <img src={URL.createObjectURL(values?.header as any)} alt="" />}
          </Box>
        ) : (
          <Box style={{ gridColumnEnd: "span 2" }}>{values?.header && <img src={host + values?.header} alt="" />}</Box>
        )}
        <Box sx={{ display: "flex", width: "20rem" }}>
          <FileUploader
            onChange={handleFileUpload}
            onError={(err) => toast.error(err)}
            allowedTypes={["image"]}
            maxSize={5}
            title={"choose Image"}
            disabled={lock}
          />
        </Box>
      </Box>
    </>
  );
};
