import React from "react";
import Footer from "../Quote/footer/Footer";
import styled from "@emotion/styled";
import Header from "../PO/header/Header";

const Wrapper = styled("div")`
  padding-top: 16px;

  p {
    padding: 0 16px;
    text-align: justify;
    font-size: 12px;
  }
`;

export default function SOpage3({ number, pageNumber, title }: { number: string; pageNumber: string; title: string }) {
  return (
    <div className="mt-12 mx-auto relative">
      <div className="flex w-full">
        <Header />
      </div>
      <Wrapper>
        <p>
          All accepted deliveries are deemed to be free from defects as of the time of delivery. K. Product sold but not
          manufactured by the Seller will be warranted as to defects in material and workmanship consistent with the
          warranty policy of the original manufacturer of the product. The Seller's only obligation shall be to assign
          Buyer, to the extent possible, whatever warranty the Seller receives from said manufacturer. In no event shall
          the Seller be liable for loss, damage or expense directly or indirectly arising from the use of the units or
          from any other cause, except as expressly stated in this warranty. THERE ARE NO WARRANTIES, WHICH EXTEND
          BEYOND THE DESCRIPTION ON THE FACE HEREOF. THE SELLER DISCLAIMS ANY IMPLIED WARRANTY OF MERCHANTABILITY OF THE
          GOODS OR OF THE FITNESS OF THE GOODS FOR ANY INTENDED PURPOSE. The Seller is not liable for, and the Buyer
          waives any right of action it has or may have against the Seller for any consequential or special damages
          arising out of any breach of warranty and for any damages the Buyer may claim for damage to any property or
          injury or death to any person arising out of its purchase or the use, operation, or maintenance of the
          product. The Seller will not be liable for any labor subcontracted or performed by the Buyer for preparation
          of item under warranty for return to the Seller’s factory or for preparation work for field repair or
          replacement. Invoicing of the Seller for labor either performed or subcontracted by the Buyer will not be
          considered as a liability by the Seller. The Seller's obligations under this warranty are conditioned upon
          timely receipt of all payments in strict accordance with payment terms, time being of the essence in this
          regard. During the time while the Seller has not received any amount overdue, the Seller shall have no
          obligation under this warranty. The expiration date of the warranty shall not be extended upon payment of the
          overdue amount.
        </p>
        <p className="font-bold mt-8">Product Discontinuance</p>
        <p>
          Seller will give as much notification as possible in the event of discontinuing product, however, Seller
          reserves the right to discontinue production of any product at any time without notice except for that
          quantity of product for which Seller has received and acknowledged a Purchase Order from Buyer and has
          scheduled such product for shipment within six (6) months of the date of such acknowledgement.
        </p>

        <p className="font-bold mt-8">Cancellation of Buyer</p>
        <p>
          Orders for standard product may be cancelled on sixty (60) days prior to the scheduled ship date by written
          notice to Seller. Orders for products which are not listed in Seller's current catalogue (including but not
          limited to, semi-custom application specific products, or other semi-custom product(s) which have special
          markings, or which have received special testing or which are specially programmed for Buyer) may not be
          cancelled or returned except under the provisions of a prior written agreement between Seller and Buyer which
          sets forth the cancellation charges to be paid by Buyer in the vent of such cancellation. If such an agreement
          is not in place, the charges shall be 100%. Any money paid in advance is refundable at DSPM discretion.
        </p>

        <p className="font-bold mt-8">Property Rights</p>
        <p>
          The design, development or manufacture by Seller of a product for Buyer shall not be deemed to produce a work
          made for hire and shall not give to Buyer any copyright interest in the product or any interest in all or any
          portion of the mask works relating to the product. All such rights remain with the property of the Seller
          including models, drawings, composites, patterns, dies molds, masks and any other tools made for or obtained
          for furnishing the products hereunder.
        </p>
        <p>
          Special, Incidental, Consequential, or Indirect Damages
          <br />
          INDEPENDENTLY OF ANY OTHER LIMITATION HEREOF AND REGARDLESS OF WHETHER THE PURPOSE OF SUCH LIMITATION IS
          SERVED, IT IS AGREED THAT IN NO EVENT SHALL SELLER BE LIABLE FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
          INDIRECT DAMAGES. Limitation of Actions No action against Seller for breach hereof shall be commenced more
          than one (1) year after the accrual of the cause of action.
        </p>
      </Wrapper>

      <div style={{ position: "absolute", top: "27cm" }}>
        <Footer DocNumber={number} pageNumber={pageNumber} title={title} email="sales-dspm@dspmanufacturing.com" />
      </div>
    </div>
  );
}
