import { host } from "host";
import { delete_, get, patch, post } from ".";
import Levels from "modules/Engineering/Device/Levels";

export interface IDocument {
  id?: string;
  path?: string;
  file: File | string;
  description: string;
  modelId: string;
  clientRepDocAccess?: boolean;
}

export const getAllModelDocuments = (model: string, id: string) => {
  return get(`/document/${model}/${id}`);
};

// export const createAModelDocument = (model: string, id: string, file: any, description: string, fileName?: string) => {
export const createAModelDocument = ({
  model,
  id,
  description,
  file,
  fileName,
  number,
  name,
  clientRepDocAccess,
}: {
  model: string;
  id: string;
  file: any;
  description?: string;
  fileName?: string;
  name?: string;
  number?: string;
  clientRepDocAccess?: boolean;
}) => {
  const formData = new FormData();

  formData.append("document", file, fileName);
  description && formData.append("description", description);
  fileName && formData.append("fileName", fileName);
  number && formData.append("number", number);
  name && formData.append("name", name);

  if (typeof clientRepDocAccess === "boolean") {
    formData.append("clientRepDocAccess", clientRepDocAccess.toString());
  }
  return post(`/document/${model}/${id}`, formData);
};
export const createACrossCheck = ({
  model,
  id,
  levels,
  description,
  file,
  fileName,
  number,
  name,
  clientRepDocAccess,
}: {
  model: string;
  id: string;
  file: any;

  levels: {
    "Power Rating": { uom: string; value: string }[];
    "Input Voltage": { uom: string; value: string }[];
    "Output Voltage": { uom: string; value: string }[];
    Runtime: { uom: string; value: string }[];
  };
  description?: string;
  fileName?: string;
  name?: string;
  number?: string;
  clientRepDocAccess?: boolean;
}) => {
  const formData = new FormData();

  file && formData.append("document", file, fileName);
  description && formData.append("description", description);
  fileName && formData.append("fileName", fileName);
  number && formData.append("number", number);
  name && formData.append("name", name);
  const powerRatings = levels["Power Rating"]?.map((i) => ({ uom: i.uom, value: i.value }));
  const inPutVoltage = levels["Input Voltage"]?.map((i) => ({ uom: i.uom, value: i.value }));

  const outputVoltages = levels["Output Voltage"]?.map((i) => ({ uom: i.uom, value: i.value }));
  const runtimes = levels.Runtime?.map((i) => ({ uom: i.uom, value: i.value }));
  formData.append(
    "levels",
    JSON.stringify({
      "Power Rating": powerRatings,
      "Input Voltage": inPutVoltage,
      "Output Voltage": outputVoltages,
      Runtime: runtimes,
    })
  );

  if (typeof clientRepDocAccess === "boolean") {
    formData.append("clientRepDocAccess", clientRepDocAccess.toString());
  }
  return post(`/document/${model}/${id}`, formData);
};
export const updateAModelDocument = (
  docid: string,
  name?: string,
  number?: string,
  file?: any,
  fileName?: string,
  description?: string,
  clientRepDocAccess?: boolean,
  levels?: {
    "Power Rating": { uom: string; value: string }[];
    "Input Voltage": { uom: string; value: string }[];
    "Output Voltage": { uom: string; value: string }[];
    Runtime: { uom: string; value: string }[];
  }
) => {
  const formData = new FormData();
  file && formData.append("document", file, fileName);
  description && formData.append("description", description);
  name && formData.append("name", name);
  number && formData.append("number", number);
  const powerRatings = levels?.["Power Rating"]?.map((i) => ({ uom: i.uom, value: i.value }));
  const inPutVoltage = levels?.["Input Voltage"]?.map((i) => ({ uom: i.uom, value: i.value }));
  const outputVoltages = levels?.["Output Voltage"]?.map((i) => ({ uom: i.uom, value: i.value }));
  const runtimes = levels?.Runtime?.map((i) => ({ uom: i.uom, value: i.value }));
  formData.append(
    "levels",
    JSON.stringify({
      "Power Rating": powerRatings,
      "Input Voltage": inPutVoltage,
      "Output Voltage": outputVoltages,
      Runtime: runtimes,
    })
  );
  if (typeof clientRepDocAccess === "boolean") {
    formData.append("clientRepDocAccess", clientRepDocAccess.toString());
  }

  return patch(`/document/${docid}`, formData);
};

export const updateAccseeDocument = (docid: string, data: Partial<IDocument>) => {
  return patch(`/document/${docid}`, data);
};

export const deleteAModelDocument = (docid: string) => {
  return delete_(`/document/${docid}`);
};

export const getDocumentUrl = (path: string, useHost?: boolean) => {
  return path?.includes("http") ? path : (useHost ? host : "https://erp.phocuss.com") + path;
};
