import React from "react";
import footer from "assets/pdf/footer.png";

function Footer({
  pageNumber,
  DocNumber,
  title,
  email,
}: {
  pageNumber: string;
  DocNumber: string;
  title: string;
  email?: string;
}) {
  return (
    <div className="relative w-full">
      <img
        src={footer}
        alt=""
        style={{
          width: "98%",
          height: "auto",
          objectFit: "contain",
          margin: 8,
        }}
      />

      <div className="absolute left-[2%] bottom-[25%] flex items-center">
        <span className="font-bold text-white text-[16px]">{pageNumber}</span>
      </div>
      <div className="absolute left-[12%] bottom-[20%] flex items-center">
        <span className=" text-white text-[16px]">1.877.377.6769</span>
      </div>
      <div className="absolute left-[32%] bottom-[25%] flex items-center">
        <span className=" text-white text-[16px]">{email || "Quote@dspmanufacturing.com"}</span>
      </div>
      <div className="absolute right-5 bottom-[25%] flex items-center">
        <span className=" text-slate-500 text-[16px]">{title} NO:</span>
        <span className=" text-white ml-1 text-[16px]">{DocNumber}</span>
      </div>
    </div>
  );
}

export default Footer;
