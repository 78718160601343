import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, CircularProgress, ListItem, useMediaQuery } from "@mui/material";
import { Formik, Form } from "formik";
import useSWR, { mutate } from "swr";
import { getModifiedValues } from "logic/utils";
import { patch } from "api";
import { BasePaper } from "app/Paper";
import Toast from "app/Toast";
import { LockProvider } from "common/Lock";
import { PostType } from "api/magazine";
import { BlogForm } from "./BlogForm";
import BlogDataGridTabs from "./BlogDataGridTabs";
import { WebsiteUrl } from "api/config";
import { createFormData } from "./modals/AddPost";
import useSWRImmutable from "swr/immutable";

function BlogDetails() {
  const phone = useMediaQuery("(max-width:850px)");
  const { postId } = useParams<{ postId: string }>();
  const {
    data: selectedPost,
    mutate: mutatePost,
    isLoading,
  } = useSWRImmutable<PostType>(postId ? `/post/${postId}` : null);

  const handlePostSubmit = async (data: PostType, { setSubmitting }: any) => {
    console.log({ data });
    try {
      setSubmitting(true);
      if (selectedPost && selectedPost.id) {
        const reqData = getModifiedValues(data, selectedPost);
        console.log("WHATS THE REQ DATA", reqData);
        const formData = createFormData(reqData?.tags ? { ...reqData, tags: reqData?.tags?.join(",") } : reqData);
        console.log("FORM DATA", formData);
        await patch(`/post/${postId}`, formData);
        mutate("/post");
        mutatePost();
        Toast("Record updated successfully", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            title: selectedPost ? selectedPost?.title : "",
            topic: selectedPost ? selectedPost?.topic : "",
            status: selectedPost ? selectedPost?.status : false,
            summary: selectedPost ? selectedPost?.summary : "",
            authorName: selectedPost ? selectedPost?.authorName : "",
            createdAt: selectedPost ? selectedPost?.createdAt : null,
            visitCount: selectedPost ? selectedPost?.visitCount : 0,
            timeDuration: selectedPost ? selectedPost?.timeDuration : 0,
            content: selectedPost ? selectedPost?.content : "",
            TopicId: selectedPost ? selectedPost?.TopicId : "",
            tags: selectedPost ? selectedPost?.tags : [],
            header: selectedPost ? selectedPost?.header : "",
          }}
          onSubmit={handlePostSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form style={{ width: "100%" }}>
              <Box display="grid" gridTemplateColumns={phone ? "1fr" : "1fr 3fr"} gap={2} flex={1}>
                <Box display="flex" flexDirection="column" maxWidth={phone ? "98%" : "500px"}>
                  <BasePaper sx={{ mb: "20px" }}>
                    <BlogForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      errors={errors}
                      touched={touched}
                    />
                    <input type="submit" hidden />
                  </BasePaper>
                </Box>
                <BasePaper sx={{ width: phone ? "98%" : "100%", height: "100%" }}>
                  <LockProvider>
                    {selectedPost && (
                      <BlogDataGridTabs
                        onMutate={mutatePost}
                        handleSubmit={handleSubmit}
                        values={values}
                        setFieldValue={setFieldValue}
                        selectedPost={selectedPost}
                      />
                    )}
                  </LockProvider>
                </BasePaper>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default BlogDetails;
