import React, { Suspense, useState } from "react";
import { Switch, Route } from "react-router-dom";
import MyBackdrop from "app/Backdrop";
import DashboardLayout from "layouts/Panel/Dashboard";
import useSWR from "swr";
// import ActionPlan from "pages/ActionPlan";

const Profile = React.lazy(() => import("../pages/Profile"));
const Tickets = React.lazy(() => import("../pages/message"));
const ProfileNotification = React.lazy(() => import("../pages/profileNotification"));
const ChangePassword = React.lazy(() => import("../pages/changePassword"));
const Question = React.lazy(() => import("../pages/questions"));
const EmployeeTasks = React.lazy(() => import("../pages/EmployeeTasks"));
const AttendanceQr = React.lazy(() => import("../pages/Employee/Qr"));
const ActionPlan = React.lazy(() => import("../pages/ActionPlan"));

export const chatDrawerWidth = 340;

export default function EmployeeRouter() {
  const [updated, setUpdated] = useState(false);
  return (
    <DashboardLayout title="" updated={updated}>
      <Suspense fallback={<MyBackdrop />}>
        <Switch>
          <Route exact path="/employee/tickets" component={Tickets} />
          <Route exact path="/employee/profileNotification" component={ProfileNotification} />
          <Route exact path="/employee/changePassword" component={ChangePassword} />
          <Route exact path="/employee/Profile">
            <Profile onMutate={() => setUpdated((p) => !p)} />
          </Route>
          <Route exact path="/employee/questions" component={Question} />
          <Route exact path="/employee/tasks" component={EmployeeTasks} />
          <Route exact path="/employee/ActionPlan" component={ActionPlan} />
          <Route exact path="/employee/attendance_qr" component={AttendanceQr} />
        </Switch>
      </Suspense>
    </DashboardLayout>
  );
}
