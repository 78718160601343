import Footer from "../Quote/footer/Footer";
import Header from "../PO/header/Header";
import { formatTimestampToDate } from "logic/date";

import SectionHeader from "./SectionHeader";
export default function SOPage1({ data, QrCode, pageNumber, title, footerTitle }) {
  return (
    <div style={{ position: "relative" }}>
      <Header />
      <Content title={title} data={data} QrCode={QrCode} />
      <div style={{ position: "absolute", top: "27cm" }}>
        <Footer
          pageNumber={pageNumber}
          DocNumber={data.number}
          title={footerTitle}
          email="sales-dspm@dspmanufacturing.com"
        />
      </div>
    </div>
  );
}

function Content({ data, QrCode, title }) {
  return (
    <div style={{ height: "23cm" }}>
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <SectionHeader title={title || "Order Acknowledgement"}></SectionHeader>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-row w-full h-[120px]" style={{ gap: 8 }}>
            <div className="flex flex-col justify-center">{QrCode}</div>
            <div
              style={{
                flexGrow: 1,
                backgroundColor: "#1673AA",
                padding: 16,
                color: "white",
              }}
            >
              <p style={{ fontWeight: "bold", fontSize: 14 }}>Project Name:</p>
              <p>{data?.projectName}</p>
              <p style={{ fontWeight: "bold", fontSize: 14, marginTop: 16 }}>Project Location:</p>
              <p>{data?.projectLocation || data?.location}</p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 16,
            marginTop: 16,
          }}
        >
          <div
            style={{
              backgroundColor: "#ECECEC",
              padding: 16,
              display: "grid",
              gridTemplateColumns: "80px 1fr",
              gap: 8,
            }}
          >
            <span className="text-[12px] font-bold">Bill to:</span>
            <span className="text-[10px] font-light">
              {`${data?.billingCompany || ""} ${data?.billingAddress || ""} ${data?.billingCity || ""} ${
                data?.billingState || ""
              } ${data?.billingZipcode || ""}`}
            </span>

            <span className="text-[12px] font-bold">Ship to:</span>
            <span className="text-[10px] font-light">
              {data?.shippingCompany} {data?.shippingAttn ? `Attn: ${data?.shippingAttn}` : ""}
              <br />
              {data?.shippingAddress} {data?.shippingState} {data?.shippingZipcode}
              <br />
              {data?.shippingPhone}
              <br />
              <span className="email">{data?.shippingPhone}</span>
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#ECECEC",
              padding: 16,
              display: "grid",
              gridTemplateColumns: "120px 1fr",
              gap: 8,
            }}
          >
            <span style={{ fontWeight: "bold" }}>Sales Order#:</span>
            <span>{data?.number}</span>
            <span style={{ fontWeight: "bold" }}>Customer PO#:</span>
            <span>{data?.customerPONumber}</span>
            <span style={{ fontWeight: "bold" }}>PO Received Date:</span>
            <span>{data?.receivedDate ? formatTimestampToDate(data?.receivedDate) : null}</span>
            <span style={{ fontWeight: "bold" }}>Entry Date:</span>
            <span>{data?.entryDate || data?.date ? formatTimestampToDate(data?.entryDate || data?.date) : null}</span>

            <span style={{ fontWeight: "bold" }}>Processed By:</span>
            <span>{data?.issuedByName}</span>

            <span style={{ fontWeight: "bold" }}>Estimated Ship Date:</span>
            <span>{data?.estimatedShipDate ? formatTimestampToDate(data?.estimatedShipDate) : null}</span>
          </div>
        </div>
        <div
          style={{
            marginTop: 8,
            backgroundColor: "#ECECEC",
            padding: 16,
            display: "grid",
            gridTemplateColumns: "120px 1fr",
            gap: 8,
          }}
        >
          <p style={{ fontWeight: "bold" }}>24h Contact:</p>
          <p>{`${data.twentyFourHourName || ""} ${data.twentyFourHourPhone || ""}`}</p>

          <p style={{ fontWeight: "bold" }}>Ship Via:</p>
          <p>{data?.shipVia}</p>

          <p style={{ fontWeight: "bold" }}>Freight Terms:</p>
          <p>{data?.freightTerms}</p>

          <p style={{ fontWeight: "bold" }}>Payment Terms:</p>
          <p>{data?.paymentTerms || data?.terms}</p>
        </div>
        <div
          style={{
            marginTop: 8,
            backgroundColor: "#ECECEC",
            padding: 16,
            display: "grid",
            gridTemplateColumns: "120px 1fr",
            gap: 8,
          }}
        >
          <p style={{ fontWeight: "bold" }}>Special Instruction:</p>
          <p>{data?.specialInstruction}</p>
        </div>
      </div>
    </div>
  );
}
