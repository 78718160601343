import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, Box, Popover, Typography, Button, Divider } from "@mui/material";

import NotificationButton from "./Notification/Button";
import { selectSession } from "features/Session/sessionsSlice";
import { EditProfile } from "./Profile/EditProfile";
import { AddEmployeeModal } from "features/Modals/EmployeeModal";
import UPCToggle from "components/UPC";
import menu from "assets/icons/menu.svg";
import orangeMenu from "assets/icons/orangeMenu.svg";
import user from "assets/icons/user.svg";
import msg from "assets/icons/msg.svg";
import oMsg from "assets/icons/oMsg.svg";
import { useHistory } from "react-router-dom";
import CustomizedInputBase from "./AIInput";

export const PhoneHeader = ({
  isChatOpen,
  onOpenChatClicked,
}: {
  isChatOpen: boolean;
  onOpenChatClicked: () => void;
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [activeNotif, setActiveNotif] = useState(false);
  const [activeMsg, setActiveMsg] = useState(false);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const [changePass, setChangePass] = useState(false);
  const [editProf, setEditProf] = useState(false);
  const session = useSelector(selectSession);

  return (
    <>
      <EditProfile open={editProf} onClose={() => setEditProf(false)} />
      <AddEmployeeModal
        open={changePass}
        onClose={() => setChangePass(false)}
        initTab={2}
        initialVals={session?.session}
      />
      <Box flex={1} display="flex" alignItems="center" sx={{ borderRadius: 0, padding: "0px !important" }}>
        <div style={{ flexGrow: 1 }}>
          {" "}
          <Box sx={{ display: "flex" }}>
            <CustomizedInputBase />
          </Box>
        </div>
        <UPCToggle />
        <IconButton onClick={handleOpenMenu}>
          {open ? <img alt="menu" src={orangeMenu} /> : <img alt="menu" src={menu} style={{ marginRight: "5px" }} />}
        </IconButton>
        <Popover
          id="notification-menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setOpen(false);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2} width={200} overflow="auto">
            <Box
              display={"flex"}
              flex={1}
              alignItems="center"
              justifyContent={"space-between"}
              onClick={() => history.push(`/employee/Profile`)}
            >
              <Typography>{session?.session?.username}</Typography>
              <IconButton size="small" title="User" color="inherit">
                <img src={user} alt="user" style={{ width: "15px" }} />
              </IconButton>
            </Box>
            <Divider sx={{ my: 0.5 }} />
            <Button
              sx={{ color: activeMsg ? "#E68031" : "#000", justifyContent: "space-between" }}
              fullWidth
              onClick={() => {
                onOpenChatClicked();
                setActiveMsg(true);
                setActiveNotif(false);
              }}
              endIcon={
                activeMsg ? (
                  <img alt="message" src={oMsg} style={{ width: "18px" }} />
                ) : (
                  <img alt="message" src={msg} style={{ width: "18px" }} />
                )
              }
            >
              Message
            </Button>
            <Divider sx={{ my: 0.5 }} />
            <NotificationButton
              click={activeNotif}
              onClick={() => {
                setActiveNotif(true);
                setActiveMsg(false);
              }}
            />
          </Box>
        </Popover>
      </Box>
    </>
  );
};
