import styled from "@emotion/styled";
import React, { forwardRef } from "react";

const Wrapper = styled.div`
  width: 21cm;
  height: 29.7cm;

  input {
    border: none;
    outline: none;
  }

  .form-container {
    max-width: 800px;
    margin: 0 auto;
    border: 2px solid #000;
  }
  .header {
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom: 2px solid #000;
  }
  .logo {
    font-weight: bold;
    font-size: 24px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  .contact-info {
    font-size: 14px;
    text-align: center;
  }
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px;
  }
  .full-width {
    grid-column: 1 / -1;
  }
  .form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  label {
    font-weight: bold;
  }
  input,
  textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  th,
  td {
    border: 1px solid #000;
    padding: 5px;
    text-align: left;
  }
  .signature-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .signature-field {
    width: 45%;
  }
  .highlight {
    background-color: yellow;
  }
`;

function CustomerSecondaryForm(props: any, ref: any) {
  return (
    <Wrapper ref={ref}>
      <div className="form-container">
        <div className="header">
          <div className="logo">DSPM INC.</div>
          <div className="title">CUSTOMER SECONDARY REPORT FORM</div>
          <div className="contact-info">
            Digital Signal Power Manufacturer, Inc.- PowerClone
            <br />
            1921 So. Quaker Ridge Place
            <br />
            Ontario, CA 91761
          </div>
        </div>
        <div className="form-grid">
          <div className="form-field">
            <label>Model No.:</label>
            <input type="text" value="0" />
          </div>
          <div className="form-field">
            <label>Serial No.:</label>
            <input type="text" />
          </div>
        </div>
        <div className="full-width">
          <label>Description of Work Performed (Continued):</label>
          <textarea></textarea>
        </div>
        <table>
          <tr>
            <th>Qty</th>
            <th>Part Number or Desc.</th>
            <th>Price ea.</th>
            <th>Ext Price</th>
          </tr>
          {Array(20)
            .fill(0)
            .map((i) => (
              <tr key={i}>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan={3} className="highlight">
              Total Parts This Page
            </td>
            <td>
              <input type="text" />
            </td>
          </tr>
        </table>
        <div className="signature-section">
          <div className="signature-field">
            <label>Service Person's Signature:</label>
            <input type="text" />
            <label>Date:</label>
          </div>
          <div className="signature-field">
            <label>Customer's Signature:</label>
            <input type="text" />
            <label>Date:</label>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default forwardRef(CustomerSecondaryForm);
